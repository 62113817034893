import React, {useState} from 'react';
import axios from "axios";
import {CSRF} from "../../../constants";
import {errorNotify} from "../../../utils/app";
import Button from "../Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSparkles} from "@awesome.me/kit-a13c95776b/icons/modules/sharp/solid";
import {useSelector} from "react-redux";

const AiRulesAction = ({msgRef, mainInp}) => {
    const [load, setLoad] = useState(false)
    const {details} = useSelector(state => state.pages.facebook.activePage)

    const setMessage = (message) => {
        msgRef.current.DOM.input.focus()
        document.execCommand("selectAll", false, message);
        document.execCommand("InsertText", false, message);
    }

    const ai_reply = (e) => {
        const replyText = mainInp.current.DOM.input.innerText
        e.preventDefault()
        setLoad(true)
        const formData = new FormData()
        formData.append("reply_text", replyText)

        axios({
            method: 'post',
            url: `/pages/${details.id}/rules/ai_reply`,
            data: formData,
            headers: { 'Content-Type': 'application/json',
                'X-CSRF-Token': CSRF}
        }).then(response => {
            setLoad(false)
            if(response.data.success){
                setMessage(response.data.text)
            }else{
                errorNotify("Service unavailable now.")
            }
        }).catch(error => {
                setLoad(false)
                errorNotify("Service unavailable now.")
            }
        );
    }

    const isMobile = /Mobi|Android/i.test(navigator.userAgent)

    return (

        <Button className="btn ai-btn" clickHandler={ai_reply} dataTip={isMobile ? '' : 'Generate similar reply'}>
            Rewrite
            <FontAwesomeIcon icon={faSparkles} beatFade={load} className="ms-2"/>
        </Button>
    )
}

export default React.memo(AiRulesAction)
