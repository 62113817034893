import React, {useContext, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {createUserSavedReplies, updateUserSavedReplies} from "../../../actions/requests";
import {faFaceSmile} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setShowEmojiModal} from "../../../actions/ui";
import EmojiContainer from "../EmojiContainer";


const SavedRepliesForm = ({setTabIndex, setShowSavedReplies, selectedReply, setSelectedReply, setDisabledRepliesList, pageId}) => {
    const dispatch = useDispatch()
    const savedReplyForm = useRef()

    const handleForm = (e) => {
        e.preventDefault()

        const formData = new FormData(savedReplyForm.current)
        if(selectedReply){
            dispatch(updateUserSavedReplies(selectedReply.id, formData, pageId))
            handleCancel(e)
        }else{
            dispatch(createUserSavedReplies(formData,setTabIndex, pageId))
        }
    }

    const handleCancel = (e) => {
        setDisabledRepliesList(false)
        setTabIndex(0)
        setSelectedReply()
    }

    return (
        <>
            <div className="my-4">
                Here you can {selectedReply ? 'edit your' : 'create'} saved replies.
            </div>

            <div className="line mb-4"></div>

            <form onSubmit={handleForm} ref={savedReplyForm}>
                <div className="row mb-2">
                    <div className="col-lg-8">
                        <div className="form-group mb-2 w-70">
                            <label>
                                Name
                                <small className="mx-1">(for internal use only)</small>
                            </label>
                            <input type="text" name="saved_reply[name]" required={true}
                                   defaultValue={selectedReply && selectedReply.attributes.name} className="form-control"/>
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label>Reply</label>
                        <div className="reply-block">
                            <div className="text-group w-100 mb-2">
                                <textarea className="form-control"
                                          required="required"
                                          rows="5"
                                          name="saved_reply[message]"
                                          id="saved_reply_message"
                                          defaultValue={selectedReply && selectedReply.attributes.message}/>
                            </div>


                            <div className="d-flex justify-content-end icon-buttons mx-0">
                                <FontAwesomeIcon icon={faFaceSmile}
                                                 className="emoji-icon p-2"
                                                 size="lg"
                                                 onClick={()=>{dispatch(setShowEmojiModal(true, "saved_reply_message"))}}/>
                            </div>
                        </div>


                    </div>



                </div>
                <div className="row">
                    <div>
                        <button type="submit" className="btn btn-primary float-end">Save</button>
                        <button type="reset" className="btn btn-secondary float-end mx-2"
                                onClick={handleCancel}>Cancel</button>
                    </div>
                </div>
            </form>

            <EmojiContainer className="emoji-modal-fixed"/>
            
        </>
    )
}

export default React.memo(SavedRepliesForm)