import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import SwitchItem from "../SwitchItem";
import Datetime from "react-datetime";
import timezones from "../../../constants/timezones.json";
import {updateUserNotifications, userEdit} from "../../../actions/user";

const CustomLanguage = ({title, desc}) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const {profile} = user
    const {enable_language, language, languages} = profile.attributes
    const [selectedlanguage, setSelectedLanguage] = useState()


    // const updateHandler = (e) => {
    //     const formData = new FormData(form.current);
    //     dispatch(updateUserNotifications(notification.id, formData))
    // }

    // useEffect(() => {
    //     setSelectedLanguage(language)
    // }, [language])

    const handleLanguage = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("user[language]", e.target.value)

        dispatch(userEdit(formData))
    }

    const enableLanguage = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("user[enable_language]", e.target.checked)

        dispatch(userEdit(formData))
    }

    return (
      <SwitchItem title={title}
                  desc={desc}
                  checked={enable_language ? true : false}
                  name="user[enable_language]"
                  changeHandler={enableLanguage}>

          {enable_language && <><div className="ml-4 mt-3 collapse show">
          </div>
              <div className="row languages-select mx-1">
                  <div className="col-lg-3">
                      <select className="form-select"
                              name="user[language]"
                              value={language}
                              onChange={handleLanguage}>
                          {languages && languages.map((l, index) => {
                              return <option key={index} value={l.code}>
                                  {l.name}
                              </option>
                          })}
                      </select>
                  </div>
              </div>
          </>}

      </SwitchItem>
    )
}

export default React.memo(CustomLanguage)
