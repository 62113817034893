import React, {forwardRef, useEffect, useState} from 'react'
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {setSelectCommentsCount, setSelectType} from "../../../actions/ui";
import {requestPageComments} from "../../../actions/requests";
import Button from "../Button";

const MainCheckbox = forwardRef(({commentsForm}, countRef) => {
    const dispatch = useDispatch()
    const [selectedAll, setSelectedAll] = useState(false)
    const {selectType} = useSelector(state => state.ui)
    const {unreviewed_comments_count} = useSelector(state => state.pages.facebook.activePage.details ? state.pages.facebook.activePage.details.attributes : 0)

    const {list: filters} = useSelector(state => state.filters)
    const {unreviewed} = filters

    // useEffect(()=>{
    //     if(selectedAll){
    //         selectComment()
    //     }
    // },[selectType])

    const selectComment = () => {
        const checkboxes = commentsForm.current.getElementsByClassName("form-check-input")
        for (let checkbox of checkboxes) {
            checkbox.checked = !selectedAll
        }
        dispatch(setSelectCommentsCount(checkboxes.length))
        if(selectedAll) {
            countRef.current.style.display = "none"
        }else{
            countRef.current.style.display = "inline-flex"
        }
        setSelectedAll(!selectedAll)
    }

    return (
        <>
            <input className="form-check-input main-check-inp" type="checkbox" onChange={selectComment}/>
            <label className="mx-3 lh-sm main-check">
                {unreviewed ? <div className="btn-group select-group">
                    <a href="#" className="filter-link dropdown-toggle"
                       data-bs-toggle="dropdown"
                       aria-expanded="false">
                        <span>{selectType}</span>
                    </a>
                    <ul className="dropdown-menu dr-main">
                        <li className="select-li" onClick={()=>dispatch(setSelectType("Select all"))}>
                            <span>Select all</span>
                        </li>
                        <li className="select-li" onClick={()=>dispatch(setSelectType("Select visible"))}>
                            <span>Select visible</span>
                        </li>
                    </ul>
                </div> : "Select visible"}
            </label>
            <label className="mx-3 lh-sm main-check-m">
                {unreviewed ? <div className="btn-group select-group">
                    <a href="#" className="filter-link dropdown-toggle"
                       data-bs-toggle="dropdown"
                       aria-expanded="false">
                        <FontAwesomeIcon icon={faCheckDouble} className="fa-fw"/>
                    </a>
                    <ul className="dropdown-menu dr-main">
                        <li className="select-li" onClick={()=>dispatch(setSelectType("Select all"))}>
                            <span>Select all</span>
                        </li>
                        <li className="select-li" onClick={()=>dispatch(setSelectType("Select visible"))}>
                            <span>Select visible</span>
                        </li>
                    </ul>
                </div> : "Select visible"}
            </label>
        </>
    )
})

export default React.memo(MainCheckbox)