import React, {forwardRef, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from "react-redux";

import { formatDistanceToNow } from 'date-fns'
import './CommentItem.scss'
import { SanitizeHTML} from "../../../constants";
import ReactTooltip from "react-tooltip";
import ReplyForm from "../ReplyForm";
import Checkbox from "./Checkbox";
import Actions from "./Actions";
import {useLongPress} from "use-long-press";

import PageName from "./PageName";
import {faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../Button";
import {showReplyForm, updateComment} from "../../../actions/pages";
import {faCircleExclamation} from "@awesome.me/kit-a13c95776b/icons/modules/classic/regular";
import {replyResend} from "../../../actions/requests";

const CommentItem = forwardRef(({index, commentsForm}, countRef) => {
    const dispatch = useDispatch()
    const comment = useSelector(state => state.comments.commentsList[index])
    const {author_name, is_hidden, message, fb_created_at, score_tooltip, show_reply_form, selected, attachment_url,
        replies, trigger, intent, page_name, page_type, page_id, mark_as_deleted, matched_keywords, attachment_type} = comment.attributes

    useEffect(()=>{
        ReactTooltip.rebuild()
    },[index])

    const bind = useLongPress((e) => {
        e.target.querySelector('.form-check-input').click()
    });

    const isMobile = /Mobi|Android/i.test(navigator.userAgent)

    const editComment = (reply) => {
        dispatch(updateComment(comment.id, {replyEdit: reply}))
        dispatch(showReplyForm(comment.id))
    }


    const resendReply = (reply) => {
        dispatch(replyResend(reply.id, page_id))
    }

    return (
      <div {...bind()}>
      <div className={`row main-row mx-0 pt-2 ${is_hidden ? 'c-hidden' : ''}`} >
          
        <div className="d-flex px-0 selection">
            <div className="form-check">
                {!mark_as_deleted && <Checkbox commentId={comment.id} selected={selected} commentsForm={commentsForm} ref={countRef}/>}
            </div>
        </div>

        <div className="col-md-2 col-xs-12 name-date">
            <div className="author-name text-break">
                <div>{author_name || "Author name"}</div>
                <div className="d-flex">
                    <div className="comment-time">
                        {formatDistanceToNow(new Date(fb_created_at), {addSuffix: true})}
                    </div>
                </div>
                <PageName page_type={page_type} page_name={page_name} />
            </div>
        </div>

        <div className="col-md-9 col-xs-12 d-flex justify-content-between px-0 comment-text-actions">
            <div className="comment-message accordion">

                {message && <div className="text">
                    <SanitizeHTML html={message} />
                </div>}

              { attachment_url && (<div className="comment-attachment">
                  {attachment_type === "animated_image_share" ?
                      <video controls src={attachment_url} alt="attachment"/> :
                      <img src={attachment_url} alt="attachment"/>}
                </div>)}
                {replies.map((reply) =>{
                    return <div key={reply.id}>
                            <div className='reply-message'>
                            <SanitizeHTML html={reply.attributes.message} />
                            {page_type!=="instagram" && reply.attributes.can_edit && !reply.attributes.delay && <Button className="comment-edit"
                                    clickHandler={() =>editComment(reply)}
                                    dataTip={isMobile ? '' : 'Edit reply'}>
                            <FontAwesomeIcon icon={faPenToSquare} className="fa-fw"/>
                            </Button>}
                            {reply.attributes.delay && <div className='badge badge-light-green' data-tip={`Will be sent in ${reply.attributes.delay}`}>Delayed</div>}
                            {reply.attributes.has_error && <div className='reply-error' data-tip={reply.attributes.error_msg}>
                                <FontAwesomeIcon icon={faCircleExclamation} className="fa-fw"/>
                                <Button clickHandler={() =>resendReply(reply)}>Resend</Button>
                            </div>}
                        </div>
                        <div className="replied-by">{reply.attributes.created_by_msg}</div>
                    </div>
                })}
            </div>

            <div className="actions-group btn-actions" >
                <div className="d-inline-flex me-4 my-2 badges">
                    {trigger && <><div className={`badge ${trigger==="Review" ? 'badge-dark' : 'badge-light'}`} data-tip={trigger==="Review"
                        ? "Reviews (also known as ratings or recommendations) are not like normal comments. They can’t be hidden, deleted or liked. The same applies to comments received on reviews."
                        : (trigger==="Keyword" ? matched_keywords : score_tooltip)}>
                        {trigger}
                    </div>
                    </>}
                    <div className="flex badges">
                        {intent && replies.length==0 && intent.map((i, index) => {
                            return <div key={index} className={`badge ${i==="Complaint" ? 'badge-light-red' : 'badge-light-green'}`}>
                                {i}
                            </div>
                        })}
                    </div>
                </div>

                <Actions comment={comment}/>
            </div>
        </div>
      </div>

        {show_reply_form && <ReplyForm comment={comment}/>}
        <div className="line mt-2"/>
      </div>
  )
})

export default React.memo(CommentItem)