import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {deleteUserSavedReplies} from "../../../actions/requests";
import Button from "../Button";


const SavedRepliesList = ({setTabIndex,setSelectedReply,setShowSavedReplies,setDisabledRepliesList,setMessage, pageId}) => {
    const dispatch = useDispatch()
    const saved_replies = useSelector(state => state.user.saved_replies)

    const handleEdit = (reply) =>{
        setSelectedReply(reply)
        setDisabledRepliesList(true)
        setTabIndex(1)
    }

    const handleDelete = (ReplyId) =>{
        dispatch(deleteUserSavedReplies(ReplyId, pageId))
    }

    const handleReplySelect = (message) => {
        setMessage(message)
        setShowSavedReplies(false)
    }

    return (
        <>
            <div className="my-4">
                Here you'll find an overview of your saved replies.
            </div>

            <div className="replies-list mb-4">
                <div className="line"></div>
                {saved_replies.length > 0 ? saved_replies.map((saved_reply)=>{
                    return  <div key={saved_reply.id}>
                        <div className="saved-reply">
                            <div className="saved-reply-item py-3"
                                onClick={()=>handleReplySelect(saved_reply.attributes.message)}>
                                {saved_reply.attributes.name}
                            </div>
                            <div className="saved-reply-actions">
                                <div className="px-2">
                                    <Button className="remove-page-link" clickHandler={()=>handleEdit(saved_reply)}>
                                        Edit
                                    </Button>
                                </div>
                                <div className="px-2">
                                    <Button className="remove-page-link" clickHandler={()=>handleDelete(saved_reply.id)}>
                                        Delete this reply?
                                    </Button>
                                </div>

                            </div>
                        </div>
                        <div className="line"></div>
                    </div>
                }) :
                    <div>
                        <button className="btn btn-primary my-4" onClick={()=>{setTabIndex(1)}}>Create reply</button>
                    </div>}
            </div>

            <button type="reset" className="btn btn-secondary float-end mx-2"
                    onClick={()=>{setShowSavedReplies(false)}}>Cancel</button>

        </>
    )
}

export default React.memo(SavedRepliesList)