import React from 'react'
import {filterList} from "../../../constants";
import Button from "../Button";
import DateRangeWrapper from "../Filters/DateRangeWrapper";
import NarrowImg from "images/narrow.svg"
import {useDispatch, useSelector} from "react-redux";
import {changeFilter} from "../../../actions/pages";
import {requestPageComments} from "../../../actions/requests";
import './FilterItems.scss'
import {faArrowUpLong, faArrowDownLong, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OrderByTime from "./OrderByDate";
import {setShowSearch} from "../../../actions/ui";

const FilterItems = ({matches, handleFilter}) => {
    const dispatch = useDispatch()
    const {list: filters} = useSelector(state => state.filters)
    const {status, comments_type, label, startDate, endDate, order_by_time, replied, intent} = filters

    return (
        <>
          <div className="btn-group filter-group">
            <a href="#" className="filter-link dropdown-toggle"
               data-bs-toggle="dropdown"
               aria-expanded="false">
                <span>{status ? status : "Visibility"}</span>
              <img src={NarrowImg} className="ms-2" width="16" height="12"/>
            </a>

            <ul className="dropdown-menu dr-main">
              {filterList.status.map((filter, index) => {
                return <li key={index}>
                  <Button className='dropdown-item' name="status" value={filter} clickHandler={handleFilter}>
                    <span className="filter-span">{filter}</span>
                  </Button>
                </li>
              })}
            </ul>

          </div>
          <div className="btn-group filter-group">
            <a href="#" className="filter-link dropdown-toggle"
               data-bs-toggle="dropdown"
               aria-expanded="false">
                <span>{comments_type ? comments_type : "Type"}</span>
              <img src={NarrowImg} className="ms-2" width="16" height="12"/>
            </a>

            <ul className="dropdown-menu">
              {filterList.comments_type.map((filter, index) => {
                return <li key={index}>
                  <Button className='dropdown-item' name="comments_type" value={filter} clickHandler={handleFilter}>
                    <span className="filter-span">{filter}</span>
                  </Button>
                </li>
              })}
            </ul>
          </div>
          <div className="btn-group filter-group">
            <a href="#" className="filter-link dropdown-toggle"
               data-bs-toggle="dropdown"
               aria-expanded="false">
              {label ? label : "Label"}
              <img src={NarrowImg} className="ms-2" width="16" height="12"/>
            </a>

            <ul className="dropdown-menu">
              {filterList.label.map((filter, index) => {
                return <li key={index}>
                  <Button className='dropdown-item' name="label" value={filter} clickHandler={handleFilter}>
                    <span className="filter-span">{filter}</span>
                  </Button>
                </li>
              })}
            </ul>
          </div>
          <div className="btn-group filter-group">
            <a href="#" className="filter-link dropdown-toggle"
               data-bs-toggle="dropdown"
               aria-expanded="false">
                {replied ? replied : "Replied"}
                <img src={NarrowImg} className="ms-2" width="16" height="12"/>
            </a>

            <ul className="dropdown-menu">
                {filterList.replied.map((filter, index) => {
                    return <li key={index}>
                        <Button className='dropdown-item' name="replied" value={filter} clickHandler={handleFilter}>
                            <span className="filter-span">{filter}</span>
                        </Button>
                    </li>
                })}
            </ul>
          </div>
        <div className="btn-group filter-group">
            <a href="#" className="filter-link dropdown-toggle"
               data-bs-toggle="dropdown"
               aria-expanded="false">
                {intent ? intent : "Intent"}
                <img src={NarrowImg} className="ms-2" width="16" height="12"/>
            </a>

            <ul className="dropdown-menu">
                {filterList.intent.map((filter, index) => {
                    return <li key={index}>
                        <Button className='dropdown-item' name="intent" value={filter} clickHandler={handleFilter}>
                            <span className="filter-span">{filter}</span>
                        </Button>
                    </li>
                })}
            </ul>
        </div>
          <div className="btn-group filter-group">
            <a href="#" className="filter-link dropdown-toggle"
               data-bs-toggle="dropdown"
               data-bs-auto-close="outside"
               aria-expanded="false">
              {startDate ? startDate.toLocaleString('en', { month: 'short', day: 'numeric' }) : "Date"}
              {endDate && " - " + endDate.toLocaleString('en', { month: 'short', day: 'numeric' })}
              <img src={NarrowImg} className="ms-2" width="16" height="12"/>
            </a>
            <ul className="dropdown-menu date-wrapper">
              <DateRangeWrapper startDate={startDate} endDate={endDate} />
            </ul>
          </div>

        </>
        )
    }

export default React.memo(FilterItems)