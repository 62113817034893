import axios from "axios"

import {
    loadFacebookPagesCompleted,
    loadFacebookPagesFailed,
    loadFacebookPagesStarted,
    requestPageDetailsSuccess,
    resetPageDetails,
    banUser,
    updateComment,
    changePage,
    requestPageCommentsStart,
    requestPageCommentsSuccess,
    requestPageCommentsFailed,
    deleteComments,
    flagUser,
    decUnreviewedCommentsCounter,
    incUnreviewedCommentsCounter,
    setUnreviewedCommentsCounter,
    requestRulesSuccess,
    addRule,
    removeRule,
    editRule,
    deleteComment,
    addNewComments,
    changeLinks,
    loadLinks
} from "./pages";

import {
    requestUserDetailsStart,
    requestUserDetailsSuccess,
    requestUserDetailsFailed,
    removeFacebookPageFromUser,
    decUserPagesCount,
    requestSavedRepliesSuccess,
    addSavedReply,
    editSavedReply,
    removeSavedReply, addFacebookPagesToUser, incUserPagesCount, editUserPages
} from "./user"
import {CSRF, SanitizeHTML} from "../constants";
import {errorNotify} from "../utils/app";
import React from "react";
import {batch} from "react-redux";
import {deleteReplySent, setReplySent} from "./ui";
import {addBrand, addPage, editBrand, removeBrand, requestBrandsSuccess, requestPages} from "./brands";

export const requestFacebookPages = () => {
    return dispatch => {
        dispatch(loadFacebookPagesStarted())
        try {
            axios.get(`/main/get_account_info`)
                .then(json => {
                    dispatch(loadFacebookPagesCompleted(json.data.pages))
                }).catch(error => {
                console.error(error)
                dispatch(loadFacebookPagesFailed(error))
            })
        } catch(e) {
            console.error("")
        }
    }
}

export const requestUserDetails = (pageId) => {
    return dispatch => {
        dispatch(requestUserDetailsStart())
        try {
            axios.get(`/users/get_details`)
                .then(json => {
                    const userData = json.data.data
                    const accountLevelData = userData.attributes.account_level
                    delete userData.attributes.account_level
                    const pagesData = json.data.included
                    const details = {
                        ...userData,
                        attributes: {
                            ...userData.attributes,
                        },
                        pages: pagesData,
                        account_level: accountLevelData

                    }
                    dispatch(requestUserDetailsSuccess(details))
                    if(pagesData){
                        if(!pageId && pagesData.length == 1) {
                            dispatch(requestPageDetails(pagesData[0].id))
                        }
                    }

                    // if (userData.attributes.token_error){
                    //     // console.log('userData.attributes.token_error', userData.attributes.token_error)
                    //     userData.attributes.token_error.map((error_message) => {
                    //         errorNotify(error_message, false)
                    //
                    //     })
                    // }
                }).catch(error => {
                    dispatch(requestUserDetailsFailed(error))
                })
        } catch(e) {
            console.error("Error of requesting current user data")
        }
    }
}

export const requestPageDetails = (pageId) => {
    return dispatch => {
        if(pageId){
            try {
                axios.get(`/pages/${pageId}`)
                    .then(json => {
                        const pageData = json.data.data
                        const details = {
                            ...pageData,
                            details: {
                                ...pageData,
                            }
                        }
                        dispatch(requestPageDetailsSuccess(details))
                    }).catch(error => {
                        console.log(error)
                    })
            } catch(e) {
                console.error("Error of requesting current user data")
            }
        }else{
            dispatch(resetPageDetails())
        }
    }
}

export const requestPageComments = (pageNum=1, exceptCommentIds = []) => {
    return (dispatch, getState) => {
        const filters = getState().filters.list
        const filterParams = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
        let url = `/comments?page=${pageNum}&${encodeURI(filterParams)}`

        dispatch(requestPageCommentsStart())
        try {
            axios.get(url)
                .then(json => {
                    const {data, unreviewed_comments_count} = json.data
                    const links = {
                        current_page: parseInt(json.headers["x-page"]),
                        next_page: parseInt(json.headers["x-page"]),
                        per_page: parseInt(json.headers["x-per-page"]),
                        total_count: parseInt(json.headers["x-total"])
                    }
                    links.total_pages = Math.ceil(links.total_count/links.per_page)
                    batch(() => {
                        dispatch(setUnreviewedCommentsCounter(unreviewed_comments_count))
                        dispatch(requestPageCommentsSuccess(data, exceptCommentIds))
                        dispatch(loadLinks(links))
                    })
                }).catch(error => {
                console.log(error)
                dispatch(requestPageCommentsFailed(error))
            })
        } catch(e) {
            console.error("Error of requesting current user data")
        }
    }
}

export const exportComments = () => {
    return (dispatch, getState) => {
        const filters = getState().filters.list
        const filterParams = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
        let url = `/comments/export`

        try {
            axios.post(url, filters, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    console.log(json)
                    // const {data, unreviewed_comments_count} = json.data
                    // const links = {
                    //     current_page: parseInt(json.headers["x-page"]),
                    //     next_page: parseInt(json.headers["x-page"]),
                    //     per_page: parseInt(json.headers["x-per-page"]),
                    //     total_count: parseInt(json.headers["x-total"])
                    // }
                    // links.total_pages = Math.ceil(links.total_count/links.per_page)
                    // batch(() => {
                    //     dispatch(setUnreviewedCommentsCounter(unreviewed_comments_count))
                    //     dispatch(requestPageCommentsSuccess(data, exceptCommentIds))
                    //     dispatch(loadLinks(links))
                    // })
                }).catch(error => {
                // console.log(error)
                // dispatch(requestPageCommentsFailed(error))
            })
        } catch(e) {
            console.error("Error of requesting export")
        }
    }
}



export const removePageFromUser = (pageId) => {
    return dispatch => {
        if(pageId) {
            try {
                axios.delete(`/pages/${pageId}`, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                    .then(json => {
                        batch(() => {
                            dispatch(removeFacebookPageFromUser(pageId))
                            dispatch(decUserPagesCount())
                        })
                    }).catch(error => {
                    console.error(error)
                })
            } catch (e) {
                console.error(e)
            }
        }
    }
}

export const setCommentVisibility = (commentId, pageId) => {
    return dispatch => {
        if(commentId) {
            try {
                axios.post(`/comments/${commentId}/api_hide_unhide`, {page_id: pageId}, {headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': CSRF}})
                    .then(json => {
                        dispatch(updateComment(commentId, json.data.attributes))
                    }).catch(error => {
                        // errorNotify(error.response.data.error)
                        error.response.data.remove && dispatch(deleteComments(commentId))
                })
            } catch (e) {
                console.error("")
            }
        }
    }
}


export const setCommentLikeUnlike = (commentId, pageId) => {
    return dispatch => {
        if(commentId) {
            try {
                axios.post(`/comments/${commentId}/like_unlike`, {page_id: pageId}, {headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': CSRF}})
                    .then(json => {
                        dispatch(updateComment(commentId, json.data.attributes))
                    }).catch(error => {
                    // errorNotify(error.response.data.error)
                })
            } catch (e) {
                console.error("")
            }
        }
    }
}

export const setCommentReview = (commentId, reviewed, pageId) => {
    return (dispatch, getState) => {

        // const excluded_comments_uuids = getState().tmpComments.commentsList
        const {current_page, total_pages} = getState().links.list
        const filters = getState().filters.list
        const {unreviewed} = filters
        const params = {...filters, ...{
                page_id: pageId,
                page: current_page,
                total_pages: total_pages}}
        batch(() => {
            // if (unreviewed) {
                dispatch(deleteComment(commentId))
            // }
            if (!reviewed) {
                dispatch(decUnreviewedCommentsCounter())
            } else {
                dispatch(incUnreviewedCommentsCounter())
            }
        })
        if(commentId) {
            try {
                axios.post(`/comments/${commentId}/review`, params, {headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': CSRF}})
                    .then(json => {
                        // successNotify('Comment has been successfully reviewed')
                        dispatch(updateComment(commentId, json.data.attributes))
                        // if(unreviewed){
                            // dispatch(requestPageComments(current_page))

                            dispatch(requestPageCommentsSuccess(json.data.data, [],unreviewed))
                            dispatch(loadLinks(json.data.links))

                        // }
                        // if(json.data.attributes.reviewed){
                        //     dispatch(decUnreviewedCommentsCounter())
                        // }else{
                        //     dispatch(incUnreviewedCommentsCounter())
                        // }



                        // batch(() => {
                        //     if (unreviewed) {
                        //         dispatch(addNewComments(json.data.data))
                        //     } else {
                        //         dispatch(updateComment(commentId, json.data.attributes))
                        //     }
                        //     dispatch(changeLinks(json.data.links))
                        // })
                    }).catch(error => {
                    console.error(error)
                })
            } catch (e) {
                console.error("")
            }
        }
    }
}

export const bulkBan = (commentsForm) => {
    return (dispatch, getState) => {
        const formData = new FormData(commentsForm)

        const {selectType} = getState().ui
        const filters = getState().filters.list
        Object.keys(filters).forEach(key => formData.append(key, filters[key]))
        formData.append("selectType", selectType)
        try {
            axios.post(`/comments/bulk_ban`, formData, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    commentsForm.reset()
                    successNotify("Bulk ban request has been created.")
                }).catch(error => {
                console.error(error)
            })
        } catch (e) {
            console.error("")
        }
    }
}

export const bulkLike = (commentsForm) => {
    return (dispatch, getState) => {
        const formData = new FormData(commentsForm)
        try {
            axios.post(`/comments/bulk_like`, formData, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    commentsForm.reset()
                    dispatch(updateComment(json.data.comment_ids, {liked: true}))
                }).catch(error => {
                console.error(error)
            })
        } catch (e) {
            console.error("")
        }
    }
}

export const bulkUnHide = (commentsForm, hide) => {
    return (dispatch, getState) => {
        const formData = new FormData(commentsForm)
        const {current_page} = getState().links.list
        const {selectType} = getState().ui
        const filters = getState().filters.list
        Object.keys(filters).forEach(key => formData.append(key, filters[key]))
        formData.append("selectType", selectType)
        formData.append("hide", hide)
        try {
            axios.post(`/comments/bulk_un_hide`, formData, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    if(selectType !== "Select all") {
                        commentsForm.reset()
                        dispatch(updateComment(json.data.comment_ids, {is_hidden: hide}))
                    }else{
                        dispatch(requestPageComments(current_page || 1))
                    }
                }).catch(error => {
                console.error(error)
            })
        } catch (e) {
            console.error("")
        }
    }
}

export const bulkReview = (commentsForm) => {
    return (dispatch, getState) => {
        const formData = new FormData(commentsForm)
        const {current_page, total_pages} = getState().links.list
        const {selectType} = getState().ui
        const filters = getState().filters.list
        Object.keys(filters).forEach(key => formData.append(key, filters[key]))
        formData.append("selectType", selectType)
        try {
            axios.post(`/comments/bulk_review`, formData, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    commentsForm.reset()
                    dispatch(requestPageComments(current_page || 1))
                    dispatch(decUnreviewedCommentsCounter(json.data.comments_count))
                }).catch(error => {
                console.error(error)
            })
        } catch (e) {
            console.error("")
        }
    }

}

export const bulkDelete = (commentsForm, comments_delete_notify = false) => {
    return (dispatch, getState) => {
        const formData = new FormData(commentsForm)
        if (comments_delete_notify) {
            formData.append('comments_delete_notify', comments_delete_notify)
        }
        const {selectType} = getState().ui
        const filters = getState().filters.list
        Object.keys(filters).forEach(key => formData.append(key, filters[key]))
        formData.append("selectType", selectType)
        const {current_page, total_pages} = getState().links.list

        try {
            axios.post(`/comments/bulk_delete`, formData, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    commentsForm.reset()
                    batch(() => {
                        if(selectType !== "Select all") {
                            dispatch(deleteComments(json.data.comment_ids))
                            dispatch(decUnreviewedCommentsCounter(json.data.comments_count))
                        }
                        dispatch(requestPageComments(current_page || 1))
                    })
                }).catch(error => {
                console.error(error)
            })
        } catch (e) {
            console.error("")
        }
    }
}

export const sendCommentDelete = (commentId, pageId, comments_delete_notify = false) => {
    return (dispatch, getState) => {
        const {current_page, total_pages} = getState().links.list
        const filters = getState().filters.list
        const {unreviewed} = filters
        let params = {page_id: pageId}
        if (comments_delete_notify){
            params = {...params,...{comments_delete_notify: comments_delete_notify}}
        }
        if(commentId) {
            try {
                axios.post(`/comments/${commentId}/api_delete`, params, {headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': CSRF}})
                    .then(json => {
                        dispatch(deleteComments(commentId))
                        if(unreviewed){
                            dispatch(decUnreviewedCommentsCounter())
                        }
                        dispatch(requestPageComments( current_page || 1, json.data.comment_ids))
                    }).catch(error => {
                    console.error(error)
                })
            } catch (e) {
                console.error("")
            }
        }
    }
}

export const updatePage = (pageId, data, userPages = false) => {
    return dispatch => {
        if(pageId) {
            try {
                axios.patch(`/pages/${pageId}`, data, {headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': CSRF}})
                    .then(json => {
                        console.log('json.data', json.data)
                        if(userPages){
                            dispatch(editUserPages(pageId, json.data.attributes))
                        }else{
                            dispatch(changePage(json.data.attributes))
                        }
                    }).catch(error => {
                    console.error(error)
                })
            } catch (e) {
                console.error("")
            }
        }
    }
}

export const sendBanUser = (commentId, pageId) => {
    return dispatch => {
        if(commentId) {
            try {
                axios.post(`/comments/${commentId}/ban_author`, {page_id: pageId}, {headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': CSRF}})
                    .then(json => {
                        if(json.data.author_banned){
                            dispatch(banUser(json.data.author_id, true))
                        }

                    }).catch(error => {
                    console.error(error.response.data)
                })
            } catch (e) {
                console.error("")
            }
        }
    }
}

export const sendUnbanUser = (commentId, pageId) => {
    return dispatch => {
        if(commentId) {
            try {
                axios.post(`/comments/${commentId}/unban_author`, {page_id: pageId}, {headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': CSRF}})
                    .then(json => {
                        // successNotify('User has been successfully unbanned')
                        if(!json.data.author_banned){
                            dispatch(banUser(json.data.author_id, false, json.data.ban_locked))
                        }

                    }).catch(error => {
                    console.error(error.response.data)
                })
            } catch (e) {
                console.error("")
            }
        }
    }
}

export const sendReply = (commentId, data, pageId) => {
    return dispatch => {
        if(commentId) {
            data.append("id", commentId)
            data.append("page_id", pageId)
            try {
                axios.post(`/comments`, data, {headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': CSRF}})
                    .then(json => {
                        dispatch(updateComment(commentId, json.data.attributes))
                        dispatch(deleteReplySent(commentId))
                    }).catch(error => {
                        // errorNotify(error.response.data.error)
                        error.response.data.remove && dispatch(deleteComments(commentId))
                })
            } catch (e) {
                console.error("")
            }
        }
    }
}

export const editReply = (commentId, data, pageId, replyId) => {
    return dispatch => {
        if(replyId) {
            data.append("page_id", pageId)
            try {
                axios.patch(`/comments/${replyId}`, data, {headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': CSRF}})
                    .then(json => {
                        dispatch(updateComment(commentId, json.data.attributes))
                    }).catch(error => {
                })
            } catch (e) {
                console.error("")
            }
        }
    }
}

export const replyResend = (replyId, pageId) => {
    return dispatch => {
        if(replyId) {
            const formData = new FormData()
            console.log('pageId', pageId)
            formData.append("page_id", pageId)
            try {
                axios.post(`/comments/${replyId}/resend_reply`, formData, {headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': CSRF}})
                    .then(json => {
                        // dispatch(updateComment(commentId, json.data.attributes))
                    }).catch(error => {
                })
            } catch (e) {
                console.error("")
            }
        }
    }
}

export const requestUserSavedReplies = (pageId="") => {
    return (dispatch, getState) => {
        pageId = pageId || getState().pages.facebook.activePage.details.id
        try {
            axios.get(`/pages/${pageId}/saved_replies`)
                .then(json => {
                    dispatch(requestSavedRepliesSuccess(json.data.data))
                }).catch(error => errorNotify(error.response.data.error))
        } catch(e) {
            console.error("Error of requesting saved replies")
        }
    }
}

export const createUserSavedReplies = (data,setTabIndex, pageId) => {
    return (dispatch, getState) => {
        pageId = pageId || getState().pages.facebook.activePage.details.id
        try {
            axios.post(`/pages/${pageId}/saved_replies`, data, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    dispatch(addSavedReply(json.data.data))
                    setTabIndex(0)
                }).catch(error => errorNotify(error.response.data.error))
        } catch (e) {
            console.error("")
        }
    }
}

export const updateUserSavedReplies = (savedReplyId, data, pageId) => {
    return (dispatch, getState) => {
        pageId = pageId || getState().pages.facebook.activePage.details.id
        try {
            axios.put(`/pages/${pageId}/saved_replies/${savedReplyId}`, data, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    // successNotify('Reply have been successfully updated')
                    dispatch(editSavedReply(savedReplyId, json.data.data.attributes))
                }).catch(error => errorNotify(error.response.data.error))
        } catch (e) {
            console.error("")
        }
    }
}

export const deleteUserSavedReplies = (savedReplyId, pageId) => {
    return (dispatch, getState) => {
        pageId = pageId || getState().pages.facebook.activePage.details.id
        try {
            axios.delete(`/pages/${pageId}/saved_replies/${savedReplyId}`, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    // successNotify('Reply have been successfully removed')
                    dispatch(removeSavedReply(savedReplyId))
                }).catch(error => errorNotify(error.response.data.error))
        } catch (e) {
            console.error("")
        }
    }
}


export const flagUnFlagAuthor = (commentId, pageId) => {
    return dispatch => {
        try {
            axios.post(`/comments/${commentId}/flag_unflag`, {page_id: pageId}, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    dispatch(flagUser(json.data.author_id, json.data.author_flagged))
                }).catch(error => {
                console.error(error.response.data)
            })
        } catch (e) {
            console.error("")
        }
    }
}

export const requestRules = () => {
    return (dispatch, getState) => {
        const pageId = getState().pages.facebook.activePage.details.id
        try {
            axios.get(`/pages/${pageId}/rules`)
                .then(json => {
                    dispatch(requestRulesSuccess(json.data.data))
                }).catch(error => errorNotify(error.response.data.error))
        } catch(e) {
            console.error("Error of requesting automations")
        }
    }
}

export const createRules = (data) => {
    return (dispatch, getState) => {
        const pageId = getState().pages.facebook.activePage.details.id
        try {
            axios.post(`/pages/${pageId}/rules`, data, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    // successNotify('Rule have been successfully created')
                    dispatch(addRule(json.data.data))
                }).catch(error => {
                    errorNotify(error.response.data.error)
            })
        } catch (e) {
            console.error("")
        }
    }
}

export const updateRules = (ruleId, data) => {
    return (dispatch, getState) => {
        const pageId = getState().pages.facebook.activePage.details.id
        try {
            axios.put(`/pages/${pageId}/rules/${ruleId}`, data, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    // successNotify('Rule have been successfully updated')
                    dispatch(editRule(ruleId, json.data.data.attributes))
                }).catch(error => errorNotify(error.response.data.error))
        } catch (e) {
            console.error("")
        }
    }
}

export const deleteRules = (ruleId) => {
    return (dispatch, getState) => {
        const pageId = getState().pages.facebook.activePage.details.id
        try {
            axios.delete(`/pages/${pageId}/rules/${ruleId}`, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    // successNotify('Rule have been successfully removed')
                    dispatch(removeRule(ruleId))
                }).catch(error => errorNotify(error.response.data.error))
        } catch (e) {
            console.error("")
        }
    }
}


export const requestBrands = (pagesOnly=false) => {
    return dispatch => {
        try {
            axios.get(`/brands_list`)
                .then(json => {
                    if(!pagesOnly) {
                        dispatch(requestBrandsSuccess(json.data.data))
                    }
                    dispatch(requestPages(json.data.available_pages.data))
                }).catch(error => console.error(error))
        } catch(e) {
            console.error("Error of requesting brands")
        }
    }
}

export const createBrands = (data) => {
    return (dispatch, getState) => {
        getState().brands.selectedPages.map(p => data.append("brand[page_ids][]",p.id))
        try {
            axios.post(`/brands`, data, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    dispatch(addBrand(json.data.data))
                }).catch(error => {
                console.error(error)
            })
        } catch (e) {
            console.error("")
        }
    }
}

export const updateBrands = (brandId, data) => {
    return (dispatch, getState) => {
        getState().brands.selectedPages.map(p => data.append("brand[page_ids][]",p.id))
        try {
            axios.put(`/brands/${brandId}`, data, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    dispatch(editBrand(brandId, json.data.data.attributes))
                }).catch(error => console.error(error))
        } catch (e) {
            console.error("")
        }
    }
}

export const deleteBrands = () => {
    return (dispatch, getState) => {
        const brandId = getState().brands.selectedBrand.id
        try {
            axios.delete(`/brands/${brandId}`, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    dispatch(removeBrand(brandId))
                }).catch(error => errorNotify(error.response.data.error))
        } catch (e) {
            console.error("")
        }
    }
}


export const addPageManually = (data, userPages) => {
    return dispatch => {
        try {
            axios.post(`/main/add_page_manually`, data, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(response => {
                    if (userPages.some(page => page.id === response.data.data.id)) {
                    } else {
                        dispatch(addFacebookPagesToUser(response.data.data))
                        dispatch(incUserPagesCount())
                        // successNotify('Page has been successfully added')
                    }
                }).catch(error => {
                errorNotify(error)
            })
        } catch (e) {
            console.error("")
        }
    }
}

export const getAnalytics = () => {
    return (dispatch, getState) => {
        pageId = getState().pages.facebook.activePage.details.id
        try {
            axios.post(`/analytics`, {page_id: pageId}, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    console.log("analytics data", json.data)
                    // json.data
                    // dispatch(updateComment(commentId, json.data.attributes))
                }).catch(error => {
                // errorNotify(error.response.data.error)
                // error.response.data.remove && dispatch(deleteComments(commentId))
            })
        } catch (e) {
            console.error("")
        }
    }
}