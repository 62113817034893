import {UI_ACTIONS} from "../constants";
import {changeFilter} from "./pages";
import {requestPageComments} from "./requests";

export const toggleShowFeedbackModalVisibility = () => ({ type: UI_ACTIONS.TOGGLE_SHOW_FEEDBACK_MODAL_VISIBILITY })

export const setShowSavedReplies = () => ({ type: UI_ACTIONS.SHOW_SAVED_REPLIES })

export const setShowEmojiModal = (val=null, inputId=null) => ({ type: UI_ACTIONS.SHOW_EMOJI_MODAL, payload: {inputId, val} })

export const setReplySent = (commentId) => ({ type: UI_ACTIONS.REPLY_SENT_ADD, payload: {commentId} })

export const deleteReplySent = (commentId) => {
    return {
        type: UI_ACTIONS.REPLY_SENT_DELETE,
        payload: {commentId}
    }
}

export const setPreviewData = (previewData) => {
    return {
        type: UI_ACTIONS.SET_PREVIEW_DATA,
        payload: {previewData}
    }
}

export const setShowPreviewPanel = (showPreviewPanel) => {
    return {
        type: UI_ACTIONS.SHOW_PREVIEW_PANEL,
        payload: {showPreviewPanel}
    }
}

export const setShowSearch = () => ({ type: UI_ACTIONS.SHOW_SEARCH })

export const setShowBrandsModal = () => ({ type: UI_ACTIONS.SHOW_BRANDS_MODAL })

export const setSelectType = (type) => {
    return {
        type: UI_ACTIONS.SET_SELECT_TYPE,
        payload: {type}
    }
}

export const setSelectCommentsCount = (count) => {
    return {
        type: UI_ACTIONS.SELECTED_COMMENTS_COUNT,
        payload: {count}
    }
}