import React, {useEffect} from "react"

import "./ManagePageList.scss"

import defaultAvatar from "images/fb-logo-grey.png"
import {useDispatch, useSelector} from "react-redux";

import Button from "../Button";
import {removePageFromUser, updatePage} from "../../../actions/requests";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {faEye, faEyeSlash} from "@fortawesome/free-regular-svg-icons";

const ManagePageList = ({ pageDetails }) => {
    const dispatch = useDispatch()
    const {attributes: page, id: id} = pageDetails
    const has_errors = page.app_errors.length > 0

    const hidePageFromDashboard = (e) => {
        const formData = new FormData()
        formData.append("page[hide_from_dashboard]", !page.hide_from_dashboard)
        dispatch(updatePage(id, formData, true))
    }

    useEffect(()=>{
        ReactTooltip.rebuild()
    },[pageDetails])

  return (
      <div className="nav-link ms-3 my-3">
            <div className="row">
              <div className="col-lg-7 d-flex">
                <div>
                    <img className="rounded-circle" alt="page image" src={page.picture_url ? page.picture_url : defaultAvatar}/>

                </div>

                  <div className={`d-inline page-name align-self-center mx-2`} >
                  {page.displayName}

                  {has_errors && <div className='badge badge-red mx-2' data-tip={page.app_errors}>
                      <a href='https://commentguard.io/how-to-refresh-your-access-token' target='_blank'>Refresh your access token</a>
                  </div>}
                </div>
              </div>
              <div className="col-lg-3 d-flex pl-0">
                  <div className="message-xs remove-page-wrapper align-self-center">
                      <Button className="remove-page-link" clickHandler={(e) => {
                          e.stopPropagation()
                          dispatch(hidePageFromDashboard)}
                      }>
                          {page.hide_from_dashboard ?
                                <>
                                    <FontAwesomeIcon icon={faEyeSlash} className="me-2" />
                                    Show in dashboard
                                </> :
                                <>
                                    <FontAwesomeIcon icon={faEye} className="me-2" />
                                    Hide from dashboard
                                </>}

                      </Button>
                  </div>
              </div>
              <div className="col-lg-2 d-flex pl-0">
                   <div className="message-xs remove-page-wrapper align-self-center">
                       <Button className="remove-page-link" clickHandler={(e) => {
                           e.stopPropagation()
                           dispatch(removePageFromUser(id))}
                       }>
                           Remove this page?
                       </Button>
                   </div>
              </div>
            </div>
      </div>
  )
}

export default React.memo(ManagePageList)
