import React, {useCallback, useEffect, useRef, useState} from 'react'
import ModalWindow from "../../../UI/ModalWindow";
import {useDispatch, useSelector} from "react-redux";
import {createBrands, removePageFromUser, requestBrands, updateBrands} from "../../../../actions/requests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {defaultSystemInstruction, getIcon} from "../../../../constants";
import PagesSelectionItem from "../../../UI/PagesSelectionItem";
import Button from "../../../UI/Button";
import {addPage, initCurrentPages, removePage, setSelectedBrand} from "../../../../actions/brands";
import BrandsPage from "./BrandsPage";
import {setShowBrandsModal} from "../../../../actions/ui";

const BrandsModal = (props) => {
    const showBrandsModal = useSelector(state => state.ui.showBrandsModal)
    const brand = useSelector(state => state.brands.selectedBrand)
    const {name, info, enabled, system_instruction} = brand.attributes || {system_instruction: defaultSystemInstruction}
    const dispatch = useDispatch()
    const brandForm = useRef()
    const btnRef = useRef()

    const handleClose = () => {
        dispatch(setSelectedBrand())
        dispatch(setShowBrandsModal())
    }

    useEffect(()=>{
        if (showBrandsModal) {
            dispatch(requestBrands(true))
        }
    },[showBrandsModal])

    const handleBrandForm = (e) => {
        e.preventDefault()
        const formData = new FormData(brandForm.current)
        if(brand.attributes){
            dispatch(updateBrands(brand.id, formData))
        }else{
            dispatch(createBrands(formData))
        }
        handleClose()
    }

    const isValidForm = useCallback(e => {
        // let valid = false
        // const formData = new FormData(brandForm.current)
        //
        // for(let pair of formData.entries()) {
        //     if(!pair[1]) {
        //         valid = true
        //     }
        // }
        //
        // btnRef.current.disabled = false
    }, [])

    return (
        <>
            {showBrandsModal && <ModalWindow hideClose={true}
                         header='Brand information' className="brandsModal"
                         closeHandler={() => handleClose()}
            >
                <form onSubmit={handleBrandForm} ref={brandForm}>
                    <div className="main row">
                        <div className="form-group mb-2">
                            <label>Name</label>
                            <input type="text" name="brand[name]"
                                   defaultValue={name}
                                   className="form-control"
                                   // required="required"
                                   onChange={isValidForm}/>
                        </div>

                        <BrandsPage />

                        <div className="form-group mb-2">
                            <label>System instructions</label>
                            <textarea type="text" name="brand[system_instruction]"
                                      defaultValue={system_instruction}
                                      className="form-control"
                                      rows="8"
                                      maxLength="50000"
                                // required="required"
                                      onChange={isValidForm}/>
                        </div>

                        <div className="form-group mb-2">
                            <label>Knowledge</label>
                            <textarea type="text" name="brand[info][knowledge]"
                                   defaultValue={info && info.knowledge}
                                   className="form-control"
                                      rows="8"
                                      maxLength="50000"
                                   // required="required"
                                   onChange={isValidForm}/>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div>
                            <button type="submit" className="btn btn-primary float-end" ref={btnRef}>Save</button>
                            <button type="reset" className="btn btn-secondary float-end mx-2"
                                    onClick={() => handleClose()}>Cancel</button>
                        </div>
                    </div>
                </form>

            </ModalWindow>}
        </>
    )
}

export default React.memo(BrandsModal)