import React from 'react';

const PurchaseConfirm  = () => {

    return (
        <div className="col-lg-7">

            <h4 className="mb-4">
                Thanks for your purchase 🎉
            </h4>

            <div className="connect-page-msg message my-4">
                Your account is now setup and ready to use.
            </div>

            <a href="https://app.commentguard.io/account-settings#account" className="btn btn-primary">Get started</a>
        </div>
    )
}

export default PurchaseConfirm;