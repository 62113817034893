import React from 'react'
import Button from "../Button";
import {useSelector} from "react-redux";
import './FilterItems.scss'
import {faArrowUpLong, faArrowDownLong} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const OrderByTime = ({handleFilter}) => {
    const {list: filters} = useSelector(state => state.filters)
    const {order_by_time} = filters

    return (
        <>
            <Button className="sort-btn" name="order_by_time" value={order_by_time == "desc" ? "asc" : "desc"}
                    clickHandler={handleFilter}>
                <FontAwesomeIcon icon={faArrowUpLong} className={order_by_time == "desc" ? "grey-arrow" : ""}/>
                <FontAwesomeIcon icon={faArrowDownLong} className={order_by_time == "asc" ? "grey-arrow" : ""}/>
            </Button>
        </>
    )
}

export default React.memo(OrderByTime)