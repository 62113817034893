// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "channels"

import ahoy from "ahoy.js"

Rails.start()
Turbolinks.start()

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import '../stylesheets/application.scss'
// Support component names relative to this directory:

const images = require.context('../../assets/images', true)


var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);


if (window.location.hash && window.location.hash == '#_=_') {
    window.location.hash = '';
}

window.successNotify = require("../utils/app").successNotify;
window.errorNotify = require("../utils/app").errorNotify;