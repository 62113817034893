import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setShowEmojiModal, setShowSavedReplies} from "../../../actions/ui";
import EmojiContainer from "../EmojiContainer";
import SavedRepliesModal from "../SavedRepliesModal";

const AdditionalModals = ({curVar, msgRef, isValidForm, showSavedReplies, setShowSavedReplies}) => {
    const dispatch = useDispatch()

    const setMessage = (message) => {
        msgRef[curVar].current.DOM.input.focus()
        const range = window.getSelection().getRangeAt(0)
        const old_range = msgRef[curVar].current.state.selection.range || range
        range.setStart(old_range.startContainer, old_range.startOffset)
        range.setEnd(old_range.endContainer, old_range.endOffset)
        range.collapse(old_range.collapsed)
        document.execCommand("InsertText", false, message);
        isValidForm()
    }

    const cursor_position = (e) => {
        const sel = document.getSelection();
        sel.modify("extend", "backward", "paragraphboundary");
        const pos = sel.toString().length;
        if(sel.anchorNode != undefined) sel.collapseToEnd();
        return pos;
    }

    const handleEmojiSelect = (emoji) => {
        setMessage(emoji.native)
        cursor_position()
    }

    const clickOutsideEmoji = (e) => {
        if (!e.target.classList.contains("saved-reply-item") && !e.target.classList.contains("emoji-icon") &&
            e.target.localName !== "path" && e.target.localName !== "em-emoji-picker") {
            dispatch(setShowEmojiModal(false))
        }
    }

    useEffect(()=>{
        msgRef[curVar]?.current?.DOM?.input?.focus()
    },[])

    return (
      <>
        <EmojiContainer isValidForm={isValidForm} handleEmojiSelect={handleEmojiSelect} clickOutside={clickOutsideEmoji} className="col-lg-9 mx-auto emoji-modal-fixed"/>
        {showSavedReplies && <>
            <SavedRepliesModal fade={false} setShowSavedReplies={setShowSavedReplies} setMessage={setMessage}/>
            <div className = "modal-backdrop fade show noclose"/>
        </>}
    </>
    )
}

export default React.memo(AdditionalModals)