import React, {forwardRef, useRef, useState} from 'react'

import {useDispatch, useSelector} from "react-redux";
import {bulkBan, bulkDelete, bulkLike, bulkReview, bulkUnHide, sendCommentDelete} from "../../../actions/requests";
import Button from "../Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxArchive, faTrashAlt, faEye, faEyeSlash, faUserSlash, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import ModalWindow from "../ModalWindow";
import {changeProfile} from "../../../actions/user";

const BulkActions = forwardRef(({commentsForm, unreviewed}, countRef) => {
    const dispatch = useDispatch()
    const [showRemoveCommentModal, setShowRemoveCommentModal] = useState(false)
    const {attributes} = useSelector(state => state.user.profile)
    const {selectType, selectedCommentsCount} = useSelector(state => state.ui)
    const {unreviewed_comments_count} = useSelector(state => state.pages.facebook.activePage.details ? state.pages.facebook.activePage.details.attributes : 0)
    const notifyCheck = useRef()

    const handleRemoveComment = (e) => {
        dispatch(bulkDelete(commentsForm.current, notifyCheck.current.checked))
        countRef.current.style.display = "none"
    }

    return (
        <>

            <div className="actions-group bulk-actions float-end" ref={countRef} role="group">
                {unreviewed && <Button className={`btn btn-outline-secondary review-btn mx-2`} clickHandler={(e) => {
                    e.stopPropagation()
                    dispatch(bulkReview(commentsForm.current))
                    countRef.current.style.display = "none"
                }}>
                    <span className="bulk-review-text">Bulk archive</span>
                    <span className="counter ps-0">{selectType === "Select all" ? unreviewed_comments_count : selectedCommentsCount}</span>
                    <FontAwesomeIcon icon={faBoxArchive}/>
                </Button>}
                <div className="btn-group">
                    <button type="button" className="btn btn-primary dropdown-toggle actions-btn d-inline-flex align-items-center" data-bs-toggle="dropdown"
                            aria-expanded="false">
                        <div className="d-none actions-text">

                            <span>Bulk actions</span>
                            <span className="counter">{selectType === "Select all" ? unreviewed_comments_count : selectedCommentsCount}</span>
                        </div>
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            <Button className={`dropdown-item`}  clickHandler={(e) => {
                                e.stopPropagation()
                                dispatch(bulkBan(commentsForm.current))
                                countRef.current.style.display = "none"
                            }}>
                                <>
                                    <FontAwesomeIcon icon={faUserSlash} className="fa-fw"/>
                                    <span className="px-1">Block authors</span>
                                </>
                            </Button>
                        </li>
                        {/*<li>*/}
                        {/*    <Button className={`dropdown-item`}  clickHandler={(e) => {*/}
                        {/*        e.stopPropagation()*/}
                        {/*        dispatch(bulkLike(commentsForm.current))*/}
                        {/*        countRef.current.style.display = "none"*/}
                        {/*    }}>*/}
                        {/*        <>*/}
                        {/*            <FontAwesomeIcon icon={faThumbsUp} className="fa-fw"/>*/}
                        {/*            <span className="px-1">Like comments</span>*/}
                        {/*        </>*/}
                        {/*    </Button>*/}
                        {/*</li>*/}
                        <li>
                            <Button className={`dropdown-item`}  clickHandler={(e) => {
                                e.stopPropagation()
                                dispatch(bulkUnHide(commentsForm.current, true))
                                countRef.current.style.display = "none"
                            }}>
                                <>
                                    <FontAwesomeIcon icon={faEyeSlash} className="fa-fw"/>
                                    <span className="px-1">Hide comments</span>
                                </>
                            </Button>
                        </li>
                        <li>
                            <Button className={`dropdown-item`}  clickHandler={(e) => {
                                e.stopPropagation()
                                dispatch(bulkUnHide(commentsForm.current,false))
                                countRef.current.style.display = "none"
                            }}>
                                <>
                                    <FontAwesomeIcon icon={faEye} className="fa-fw"/>
                                    <span className="px-1">Unhide comments</span>
                                </>
                            </Button>
                        </li>
                        <li>
                            <Button className={`dropdown-item`} clickHandler={(e) => {
                                e.stopPropagation()
                                if(attributes.comments_delete_notify){
                                    dispatch(bulkDelete(commentsForm.current))
                                    countRef.current.style.display = "none"
                                }else{
                                    setShowRemoveCommentModal(true)
                                }
                            }}>
                                <FontAwesomeIcon icon={faTrashAlt} className="fa-fw"/>
                                <span className="px-1">Delete comments</span>
                            </Button>
                        </li>
                    </ul>
                </div>
            </div>

            {showRemoveCommentModal && !attributes.comments_delete_notify &&
            <ModalWindow hideClose={true}
                         header="Are you sure?"
                         closeHandler={() => setShowRemoveCommentModal(false)}>
                <div className="mb-4">
                    Deleting is an action that can't be reversed.
                </div>
                <div className="row">
                    <form onSubmit={handleRemoveComment}>
                        <input className="form-check-input mt-3" type="checkbox" ref={notifyCheck} name="comments_delete_notify"/>
                            <label className="mx-2 mt-3 fs-08">Don’t ask me again</label>
                        <button type="submit" className="btn btn-danger float-end">Delete</button>
                        <button type="reset" className="btn btn-secondary float-end mx-2 delete-btn"
                                onClick={() => setShowRemoveCommentModal(false)}>Cancel</button>
                    </form>
                </div>
            </ModalWindow>
            }
        </>
    )
})

export default React.memo(BulkActions)