import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import ModalWindow from "../../../UI/ModalWindow";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import AddUsersForm from "./AddUsersForm";
import {updateUserSubUsers} from "../../../../actions/user";
import AddUsersRoles from "./AddUsersRoles";
import "./AccountSettingsUsers.scss"
import Button from "../../../UI/Button";
import axios from "axios";
import {CSRF} from "../../../../constants";
import {errorNotify, successNotify} from "../../../../utils/app";

const AccountSettingsUsers = (props) => {
    const dispatch = useDispatch()
    const {pages: userPages, account_level, profile} = useSelector(state => state.user)

    const [showAddUsersModal, setShowAddUsersModal] = useState(false)
    const [showRemoveUsersModal, setShowRemoveUsersModal] = useState(false)
    const [userProfile, setUserProfile] = useState({attributes: {}})
    const [selectedUserId, setSelectedUserId] = useState("")
    const userForm = useRef()
    const {sub_users_limit} = account_level.attributes
    const {sub_users, sub_users_count} = profile.attributes

    const handleUserForm = (e) => {
        e.preventDefault();
        const formData = new FormData(userForm.current);

        try {
            axios({
                method: selectedUserId ? 'put' : 'post',
                url: `/users/${selectedUserId}`,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}
            }).then(json => {
                // successNotify(json.data.message)
                setShowAddUsersModal(false)
                dispatch(updateUserSubUsers(json.data.sub_users))
            }).catch(error => {
                errorNotify(error.response.data.error)
            })
        } catch (e) {
            console.error(e)
        }
    }

    const handleRemoveUser = (e) => {
        e.preventDefault();

        try {
            axios.delete(`/users/${selectedUserId}`, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                // successNotify(json.data.message)
                setShowRemoveUsersModal(false)
                dispatch(updateUserSubUsers(json.data.sub_users))
            }).catch(error => {
                errorNotify(error.response.data.error)
            })
        } catch (e) {
            console.error(e)
        }
    }

    const removeUserModal = (user) => {
        setUserProfile(user)
        setShowRemoveUsersModal(true)
        setSelectedUserId(user.id)
    }

    const editUserModal = (user) => {
        setUserProfile(user)
        setShowAddUsersModal(true)
        setSelectedUserId(user.id)
    }

    const addUserModal = () => {
        setUserProfile({attributes: {}})
        setShowAddUsersModal(true)
        setSelectedUserId("")
    }

    return (
        <>
            <div className="my-4">
                Here you can add sub-users to your CommentGuard account.
            </div>

            <div className="mb-4">
                {sub_users && sub_users.map((user) => {

                    return <div className="d-flex py-3" key={user.id}>
                         <div className="d-flex sub-user-item sub-user-email">
                            {user.attributes.email}
                            <div className="badge badge-light mx-2">
                                {user.attributes.users_role}
                            </div>
                        </div>
                        <div className="d-flex px-0 sub-user-item">
                            <div className="px-2">
                                <Button className="remove-page-link" clickHandler={()=>editUserModal(user)}>
                                    Edit
                                </Button>
                            </div>
                            <div className="px-2">
                                <Button className="remove-page-link" clickHandler={()=>removeUserModal(user)}>
                                    Remove this user?
                                </Button>
                            </div>
                        </div>
                    </div>
                })}

            </div>

            {sub_users_count >= sub_users_limit
                ? (sub_users_limit==0 || !sub_users_limit)
                    ? <div className="mb-4 fs-08">Your current plan does not include this feature.</div>
                    : <div className="mb-4 fs-08">You’ve reached your maximum number of users.</div>
                : <button className="btn btn-primary mb-4" onClick={()=>addUserModal()}>Add user</button>
            }

            {showAddUsersModal && <ModalWindow hideClose={true}
                header={`${selectedUserId ? 'Edit' : 'Add new'} user`} className="giveFeedbackModal"
                closeHandler={() => setShowAddUsersModal(false)}
            >
                <Tabs className="rect-tabs pt-2">
                    <TabList>
                        <Tab>Profile</Tab>
                        <Tab>Privileges</Tab>
                    </TabList>

                    <form onSubmit={handleUserForm} ref={userForm}>
                        <TabPanel forceRender={true}>
                            <AddUsersForm profile={userProfile} selectedUserId={selectedUserId} />
                        </TabPanel>

                        <TabPanel forceRender={true}>
                            <AddUsersRoles profile={userProfile} userPages={userPages}/>
                        </TabPanel>

                        <div className="row">
                            <div>
                                <button type="submit" className="btn btn-primary float-end">Save</button>
                                <button type="reset" className="btn btn-secondary float-end mx-2"
                                        onClick={() => setShowAddUsersModal(false)}>Cancel</button>
                            </div>
                        </div>
                        {/*<input type="hidden" name="user{}" />*/}
                    </form>
                </Tabs>
            </ModalWindow>}

            {showRemoveUsersModal &&
                <ModalWindow hideClose={true}
                             header="Are you sure you want to delete this user?"
                             closeHandler={() => setShowRemoveUsersModal(false)}>
                    <div className="mb-4">
                        If you delete {userProfile.attributes.email} their access will be removed entirely.
                    </div>
                    <div className="row">
                        <form onSubmit={handleRemoveUser}>
                            <button type="submit" className="btn btn-danger float-end">Delete</button>
                            <button type="reset" className="btn btn-secondary float-end mx-2"
                                    onClick={() => setShowRemoveUsersModal(false)}>Cancel</button>
                        </form>
                    </div>
                </ModalWindow>
            }
        </>
    )
}

export default React.memo(AccountSettingsUsers)