import {PAGES_ACTIONS} from "../constants";

const initState = {
    facebook: {
        selectionList: {
            error: null,
            isFetching: false,
            list: []
        },
        activePage: {
            error: null,
            isFetching: false,
            details: {
                attributes: {}
            },
            rules: []
        }
    }
}


export function pagesReducer(state=initState, action) {
    const {payload} = action

    switch (action.type) {
        case PAGES_ACTIONS.LOAD_FACEBOOK_PAGES_FAILED:
            return {
                ...state,
                facebook: {
                    ...state.facebook,
                    selectionList: {
                        error: action.payload,
                        isFetching: false,
                        list: null
                    }
                }
            }
        case PAGES_ACTIONS.LOAD_FACEBOOK_PAGES_STARTED:
            return {
                ...state,
                facebook: {
                    ...state.facebook,
                    selectionList: {
                        error: null,
                        isFetching: true,
                        list: []
                    }
                }
            }
        case PAGES_ACTIONS.LOAD_FACEBOOK_PAGES_COMPLETED:
            return {
                ...state,
                facebook: {
                    ...state.facebook,
                    selectionList: {
                        error: null,
                        isFetching: false,
                        list: payload.selectionList
                    }
                }
            }
        case PAGES_ACTIONS.REQUEST_PAGE_DETAILS_SUCCESS:
            return {
                ...state,
                facebook: {
                    ...state.facebook,
                    activePage: {
                        ...state.facebook.activePage,
                        error: null,
                        isFetching: false,
                        details: payload.data.details
                    }
                }
            }
        case PAGES_ACTIONS.RESET_PAGE_DETAILS:
            return {
                ...state,
                facebook: {
                    ...state.facebook,
                    activePage: {
                        ...state.facebook.activePage,
                        error: null,
                        isFetching: false,
                        details: {
                            attributes: {}
                        }
                    }
                }
            }
        case PAGES_ACTIONS.CHANGE_PAGE:
            return {
                ...state,
                facebook: {
                    ...state.facebook,
                    activePage: {
                        ...state.facebook.activePage,
                        details: {
                            ...state.facebook.activePage.details,
                            attributes: Object.assign(state.facebook.activePage.details.attributes, payload.attributes)
                        }
                    }
                }
            }
        case PAGES_ACTIONS.INC_UNREVIEWED_COMMENTS_COUNTER:
            return{
                ...state,
                facebook: {
                    ...state.facebook,
                    activePage: {
                        ...state.facebook.activePage,
                        details: {
                            ...state.facebook.activePage.details,
                            attributes: {
                                ...state.facebook.activePage.details.attributes,
                                unreviewed_comments_count: state.facebook.activePage.details.attributes.unreviewed_comments_count + 1
                            }
                        }
                    }
                }
            }
        case PAGES_ACTIONS.DEC_UNREVIEWED_COMMENTS_COUNTER:
            return{
                ...state,
                facebook: {
                    ...state.facebook,
                    activePage: {
                        ...state.facebook.activePage,
                        details: {
                            ...state.facebook.activePage.details,
                            attributes: {
                                ...state.facebook.activePage.details.attributes,
                                unreviewed_comments_count: state.facebook.activePage.details.attributes.unreviewed_comments_count - payload.num
                            }
                        }
                    }
                }
            }
        case PAGES_ACTIONS.SET_UNREVIEWED_COMMENTS_COUNTER:
            return{
                ...state,
                facebook: {
                    ...state.facebook,
                    activePage: {
                        ...state.facebook.activePage,
                        details: {
                            ...state.facebook.activePage.details,
                            attributes: {
                                ...state.facebook.activePage.details.attributes,
                                unreviewed_comments_count: payload.num
                            }
                        }
                    }
                }
            }
        case PAGES_ACTIONS.REQUEST_RULES:
            return {
                ...state,
                facebook: {
                    ...state.facebook,
                    activePage: {
                        ...state.facebook.activePage,
                        rules: payload.data
                    }
                }
            }
        case PAGES_ACTIONS.ADD_RULES:
            return {
                ...state,
                facebook: {
                    ...state.facebook,
                    activePage: {
                        ...state.facebook.activePage,
                        rules: [payload.data, ...state.facebook.activePage.rules]
                    }
                }
            }
        case PAGES_ACTIONS.UPDATE_RULES:
            return {
                ...state,
                facebook: {
                    ...state.facebook,
                    activePage: {
                        ...state.facebook.activePage,
                        rules: state.facebook.activePage.rules.map(rule => {
                            if (rule.id === payload.ruleId) {
                                return {
                                    ...rule,
                                    attributes: Object.assign(rule.attributes, payload.attributes)
                                }
                            } else {
                                return {
                                    ...rule
                                }
                            }
                        })
                    }
                }
            }
        case PAGES_ACTIONS.REMOVE_RULES:
            return {
                ...state,
                facebook: {
                    ...state.facebook,
                    activePage: {
                        ...state.facebook.activePage,
                        rules: state.facebook.activePage.rules.filter(rule => rule.id !== payload.ruleId)
                    }
                }
            }
        default:
            return state
    }
}