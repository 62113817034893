import React, {useState} from 'react'
import axios from "axios";
import {CSRF, SanitizeHTML} from "../../../../constants";
import {capitalize, errorNotify} from "../../../../utils/app";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import Button from "../../Button";
import {deleteComments} from "../../../../actions/pages";
import {useDispatch} from "react-redux";
import {setPreviewData, setShowPreviewPanel} from "../../../../actions/ui";
import MGlass from "./Mglass";

const PreviewBtn = ({pageId, commentId, type, url, isMobile}) => {
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false)

    const preview = (e) => {
        e.preventDefault()
        setLoad(true)

        const formData = new FormData()
        formData.append("page_id", pageId)

        axios({
            method: 'post',
            url: `/comments/${commentId}/post_preview`,
            data: formData,
            headers: { 'Content-Type': 'application/json',
                'X-CSRF-Token': CSRF}
        }).then(response => {
            dispatch(setPreviewData({...response.data,...{commentId: commentId, commentType: type, commentUrl: url}}))
            dispatch(setShowPreviewPanel(true))
            setLoad(false)
        }).catch(error => {
                errorNotify(error.response.data.error)
                error.response.data.remove && dispatch(deleteComments(commentId))
                setLoad(false)
            }
        );
    }

    return (
            <button type="button" className="square-btn preview-btn" onClick={preview} data-tip={isMobile ? '' : "Preview"}>
                {/*<FontAwesomeIcon icon={faMagnifyingGlass} className="fa-fw"/>*/}
                <MGlass className={`svg-inline--fa fa-lg fa-fw ai-reply-icon me-3`} load={load}/>
            </button>
    )
}

export default React.memo(PreviewBtn)