import React, {useEffect, useRef, useState} from 'react'
import RulesModal from "./RulesModal";
import {deleteRules, requestRules, updateRules} from "../../../actions/requests";
import {useDispatch, useSelector} from "react-redux";
import Button from "../Button";
import SwitchItem from "../SwitchItem";
import './Rules.scss'
import ModalWindow from "../ModalWindow";
import {setShowEmojiModal} from "../../../actions/ui";
import CopySettings from "../Moderation/CopySettings";

const Rules = (props) => {
    const dispatch = useDispatch()
    const {rules, details} = useSelector(state => state.pages.facebook.activePage)
    const [showRulesModal, setShowRulesModal] = useState(false)
    const [selectedRule, setSelectedRule] = useState()
    const [showRemoveRuleModal, setShowRemoveRuleModal] = useState(false)


    useEffect(()=>{
        dispatch(requestRules())
    },[details])

    const rulesModal = () => {
        setShowRulesModal(true)
    }

    const removeRuleModal = (rule) => {
        setSelectedRule(rule)
        setShowRemoveRuleModal(true)
    }

    const editRuleModal = (rule) => {
        setSelectedRule(rule)
        setShowRulesModal(true)
    }

    const handleClose = () => {
        setShowRulesModal(false)
        setSelectedRule()
        dispatch(setShowEmojiModal(false))
    }

    const handleRemoveRule = (e) => {
        e.preventDefault()
        dispatch(deleteRules(selectedRule.id))
        closeRemoveModal()
    }

    const closeRemoveModal = (e) => {
        setShowRemoveRuleModal(false)
        setSelectedRule()
    }

    const disableRule = (ruleId, val) => {
        const formData = new FormData()
        formData.append("rule[enabled]", !val)
        dispatch(updateRules(ruleId, formData))
    }

    return (
        <>
            <div className="mt-4 mb-3">
                Here you can setup triggers and automated actions.
            </div>

            <CopySettings objectType="automations"/>

            <div className="mb-4 rules">
                {rules && rules.map((rule) => {

                    return <div className="d-flex py-2" key={rule.id}>
                        <SwitchItem title={rule.attributes.name}
                                    wrapperClass="d-flex sub-user-item"
                                    value="true"
                                    checked={rule.attributes.enabled}
                                    name="rule[enabled]"
                                    changeHandler={()=>disableRule(rule.id, rule.attributes.enabled)}/>

                        <div className="d-flex px-0 sub-user-item">
                            <div className="px-2">
                                <Button className="remove-page-link" clickHandler={()=>editRuleModal(rule)}>
                                    Edit
                                </Button>
                            </div>
                            <div className="px-2">
                                <Button className="remove-page-link" clickHandler={()=>removeRuleModal(rule)}>
                                    Remove this automation?
                                </Button>
                            </div>
                        </div>
                    </div>
                })}

            </div>

            <button className="btn btn-primary mb-4" onClick={()=>{rulesModal()}}>Create automation</button>

            {showRulesModal && <RulesModal rule={selectedRule}
                                           setShowRulesModal={setShowRulesModal}
                                           handleClose={handleClose}/>}

            {showRemoveRuleModal &&
                <ModalWindow hideClose={true}
                             header="Are you sure you want to delete this automation?"
                             closeHandler={() => closeRemoveModal()}>
                    <div className="mb-4">
                        If you delete this automation it will no longer trigger your automated action.
                    </div>
                    <div className="row">
                        <form onSubmit={handleRemoveRule}>
                            <button type="submit" className="btn btn-danger float-end">Delete</button>
                            <button type="reset" className="btn btn-secondary float-end mx-2"
                                    onClick={() => closeRemoveModal()}>Cancel</button>
                        </form>
                    </div>
                </ModalWindow>
            }

        </>
    )
}

export default React.memo(Rules)