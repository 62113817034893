import React, {useState} from 'react';

const MGlass = ({className, load=false}) => {

    return (
        <svg className={className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.0013 12.9981C26.0013 15.8664 25.07 18.516 23.5011 20.6657L31.414 28.5833C32.1953 29.3644 32.1953 30.633 31.414 31.4141C30.6327 32.1953 29.3639 32.1953 28.5826 31.4141L20.6698 23.4966C18.5197 25.0713 15.8695 25.9962 13.0006 25.9962C5.81903 25.9962 0 20.1783 0 12.9981C0 5.8179 5.81903 0 13.0006 0C20.1822 0 26.0013 5.8179 26.0013 12.9981ZM13.0006 21.9968C14.1826 21.9968 15.353 21.764 16.445 21.3118C17.5369 20.8596 18.5291 20.1967 19.3649 19.3611C20.2007 18.5255 20.8636 17.5335 21.316 16.4417C21.7683 15.35 22.0011 14.1798 22.0011 12.9981C22.0011 11.8164 21.7683 10.6462 21.316 9.55445C20.8636 8.46268 20.2007 7.47067 19.3649 6.63507C18.5291 5.79946 17.5369 5.13662 16.445 4.6844C15.353 4.23217 14.1826 3.99941 13.0006 3.99941C11.8187 3.99941 10.6483 4.23217 9.55632 4.6844C8.46433 5.13662 7.47213 5.79946 6.63636 6.63507C5.80059 7.47067 5.13763 8.46268 4.68531 9.55445C4.233 10.6462 4.0002 11.8164 4.0002 12.9981C4.0002 14.1798 4.233 15.35 4.68531 16.4417C5.13763 17.5335 5.80059 18.5255 6.63636 19.3611C7.47213 20.1967 8.46433 20.8596 9.55632 21.3118C10.6483 21.764 11.8187 21.9968 13.0006 21.9968Z" fill="black"/>
            {load && <path d="M23.91 13.41V12.802C23.91 12.208 23.861 11.615 23.763 11.029C23.5299 9.62963 23.0229 8.29003 22.271 7.087L22.188 6.954C21.347 5.60841 20.2106 4.47201 18.865 3.631C17.5774 2.8261 16.1298 2.31149 14.623 2.123L14.357 2.09C13.396 1.97 12.424 1.97 11.463 2.09L11.197 2.123C9.68988 2.31135 8.24194 2.82596 6.954 3.631C5.60841 4.47201 4.47201 5.60841 3.631 6.954C2.82596 8.24194 2.31135 9.68988 2.123 11.197L2.09 11.463C1.97 12.424 1.97 13.396 2.09 14.357L2.123 14.623C2.31149 16.1298 2.8261 17.5774 3.631 18.865C4.47201 20.2106 5.60841 21.347 6.954 22.188L7.087 22.271C8.29003 23.0229 9.62963 23.5299 11.029 23.763C11.615 23.861 12.208 23.91 12.802 23.91H13.41" stroke="#2BAE76" strokeWidth="4" strokeLinecap="round">
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 13 13"
                    to="360 13 13"
                    dur="1s"
                    repeatCount="indefinite"
                />
            </path>}
        </svg>
    )
}

export default MGlass;