import React, {useEffect, useRef, useState} from 'react'
import {useDispatch} from "react-redux";
import {userAdd, userEdit} from "../../../../actions/user";
import {getIcon, rolesInfo, SanitizeHTML} from "../../../../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AddUsersRoles = ({profile, userPages}) => {
    const dispatch = useDispatch()
    const {role, users_role, all_pages, page_uuids} = profile.attributes
    const [roleInfo, setRoleInfo] = useState(rolesInfo[users_role || "moderator"])
    const [selRole, setSelRole] = useState(users_role || "moderator")
    const [allPages, setAllPages] = useState(all_pages===false ? false : true)

    const selectRole = (e) => {
        setRoleInfo(rolesInfo[e.target.value])
        setSelRole(e.target.value)
    }

    return (
        <>
            <div className="my-2">
                <div className="col-lg-8">
                    <div className="form-group mb-2">
                        <label>Role</label>
                        <select className="form-select" name="role[name]" defaultValue={users_role}
                                onChange={selectRole}>
                            <option value="moderator">Moderator</option>
                            <option value="manager">Manager</option>
                            <option value="administrator">Administrator</option>
                        </select>
                    </div>
                    <SanitizeHTML html={roleInfo} className="role-info"/>
                </div>

                {selRole !== "administrator" &&
                <div className="col-lg-8 my-4">
                    <div className="form-group mb-2">
                        <label>Pages</label>
                        <select className="form-select" name="role[all_pages]" defaultValue={allPages}
                                onChange={(e) => {
                                    setAllPages(e.target.value === 'true')
                                }}>
                            <option value="true">User has access to all pages in my account</option>
                            <option value="false">User has access to specific pages in my account</option>
                        </select>

                        {allPages ? <>
                                <input type="hidden" name="role[page_uuids][]"/>
                            </>
                            : <div className="my-4 page-lists-subusers">
                                {userPages.sort((a, b) => a.attributes.displayName.localeCompare(b.attributes.displayName)).map((page) => {
                                    return <div key={page.id} className="d-flex mb-2">
                                        <input className="form-check-input"
                                               type="checkbox"
                                               defaultChecked={page_uuids && page_uuids.includes(page.id)}
                                               name="role[page_uuids][]"
                                               value={page.id}/>

                                        <div className="form-check-label ms-3">
                                            <FontAwesomeIcon icon={getIcon(page.type)} className="fa-fw"/>
                                            <span className="ms-2">
                                            {page.attributes.displayName}
                                        </span>
                                        </div>
                                    </div>
                                })}
                            </div>}
                    </div>
                </div>
                }
            </div>
        </>
    )
}

export default React.memo(AddUsersRoles)