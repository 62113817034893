import React, { useEffect } from "react"
import { createPortal } from "react-dom"

import "./ModalWindow.scss"

const ModalWindow = ({ fade=true, toTop=false, closeHandler, header, children, className="", hideClose=false, modalSize=""}) => {
  const mountRoot = document.getElementById("modal-root")
  const wrapperNode = document.createElement("div")

  useEffect(() => {
    mountRoot.appendChild(wrapperNode)
    return () => mountRoot.removeChild(wrapperNode)
  }, [wrapperNode, mountRoot])

  const clickOutside = (e) => {
    e.target.classList.contains("fade") && !e.target.classList.contains("noclose") && closeHandler(e)
  }

  const modal = (
    <>
      <div className={`modal fade show open ${className}`} style={toTop ? {zIndex: "999999999"} : {} } onClick={clickOutside}>
        <div className={`modal-dialog ${modalSize}`} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title" id="addPageModalLabel">{ header }</div>
              {!hideClose && <button className="btn-close" onClick={closeHandler}/>}
            </div>
            <div className="modal-body">
              { children }
            </div>
          </div>
        </div>
      </div>
      {fade && <div className = "modal-backdrop fade show" onClick={clickOutside}/>}
    </>
  )

  return createPortal(modal, wrapperNode)
}

export default React.memo(ModalWindow)