import {COMMENTS_ACTIONS} from "../constants";

const initState = {
    commentsList: []
}


export function tmpCommentsReducer(state=initState, action) {
    const {payload} = action

    switch (action.type) {
        case COMMENTS_ACTIONS.RESET_TMP_COMMENTS:
            return {
                ...state,
                commentsList: []
            }
        case COMMENTS_ACTIONS.ADD_NEW_TMP_COMMENT:
            return {
                ...state,
                commentsList: [payload.comment, ...state.commentsList]
            }
        default:
            return state
    }
}