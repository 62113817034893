import React, {useEffect, useRef} from 'react'
import {EmojiPicker} from "../../../utils/app";
import {useDispatch, useSelector} from "react-redux";
import {setShowEmojiModal} from "../../../actions/ui";

const EmojiContainer = (props) => {
    const dispatch = useDispatch()
    const emojiRef = useRef()
    const {emojiInputId: inputId, showEmojiModal} = useSelector(state => state.ui)

    const handleEmojiSelect = (emoji) => {
        if(inputId) {
            const textarea = document.getElementById(inputId)
            const startPos = textarea.selectionStart
            const output = textarea.value.substring(0, startPos) + emoji.native + textarea.value.substring(startPos);
            textarea.value = output
            textarea.selectionEnd = startPos + 2
            props.isValidForm && props.isValidForm()
        }
    }

    const clickOutsideEmojiMart = (e) => {
        const parent = emojiRef.current
        if (emojiRef.current && !parent.contains(e.target) && !e.target.closest(".emoji-icon")) {
            dispatch(setShowEmojiModal(false))
        }
    }

    useEffect(()=>{
        document.addEventListener("click", props.clickOutside || clickOutsideEmojiMart);
        return () => {
            document.removeEventListener('click', props.clickOutside || clickOutsideEmojiMart)
        }
    },[])

    return (
        <>
            {showEmojiModal && <div className={props.className ? props.className : "col-lg-4 mx-auto emoji-modal"} ref={emojiRef}>
                <EmojiPicker onEmojiSelect={props.handleEmojiSelect || handleEmojiSelect} previewPosition='none'/>
            </div>}
        </>

    )
}

export default React.memo(EmojiContainer)