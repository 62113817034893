import React from 'react';

const RebillFailed  = () => {

    return (
        <div className="col-lg-7">

            <h4 className="mb-4">
                Your account has been paused
            </h4>

            <div className="connect-page-msg message my-4">
                The rebill for your subscription failed.
            </div>

            <div className="connect-page-msg message my-4">
                Please purchase a plan or update your payment details to resume your subscription.
            </div>

            <a href="https://app.commentguard.io/account-settings#account" className="btn btn-primary">Account settings</a>
        </div>
    )
}

export default RebillFailed;