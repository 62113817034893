import React, {useEffect, useRef, useState} from 'react'
import Button from "../Button";
import "./TestTab.scss"
import axios from "axios";
import {CSRF} from "../../../constants";
import {errorNotify} from "../../../utils/app";
import {useSelector} from "react-redux";
import ReactTooltip from 'react-tooltip';

const TestTab = (props) => {
    const formRef = useRef()
    const [result, setResult] = useState()
    const [adminText, setAdminText] = useState()
    const [trigger, setTrigger] = useState()
    const [message, setMessage] = useState()
    const [tooltip, setTooltip] = useState()
    const {id: pageId} = useSelector(state => state.pages.facebook.activePage.details)

    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData(formRef.current)

        axios({
            method: 'post',
            url: `/pages/${pageId}/test_comment`,
            data: formData,
            headers: { 'Content-Type': 'application/json',
                'X-CSRF-Token': CSRF}
        }).then(json => {
            console.log(json.data)
            if(json.data.changed){
                setResult("this comment would be hidden")
                setTrigger(json.data.trigger)
                let text = ""
                if(json.data.score) {
                    text = `Score: ${json.data.score}`
                }
                if(json.data.magnitude) {
                    text = text + `, Magnitude: ${json.data.magnitude}`
                }
                text ? setTooltip(text) : setTooltip()

            }else{
                setTrigger()
                setTooltip()
                setResult("this comment would not be hidden")
            }
            if(json.data.admin_text){
                setAdminText(json.data.admin_text)
            }

        }).catch(errors => {
                errorNotify(errors.response.data.error)
            }
        );
    }


    const handleText = (e) => {
        setMessage(e.currentTarget.value)
        if(!e.currentTarget.value){
            setResult()
            setTrigger()
            setTooltip()
        }
    }

    useEffect(()=>{
        if(tooltip) {
            ReactTooltip.rebuild()
        }
    },[tooltip])

    return (
        <>
            <div className="test-block col-8 mb-4">
                <form ref={formRef} onSubmit={handleSubmit}>
                  <div className="text-group w-100 mb-2">
                    <textarea className="form-control"
                              placeholder="Type your comment here..."
                              required="required"
                              rows="5"
                              name="message"
                              onKeyUp={handleText}></textarea>
                  </div>
                  <div className="row test-btn mx-0 reply-btn">
                    <div className="col-10">
                        {/*<span className="note-text">NOTE: test comments are also counted towards your total number of comments per month.</span>*/}
                    </div>
                    <div className="col-2 p-0">
                        {message && <Button type="submit" className="btn btn-primary" >Process</Button>}
                    </div>
                  </div>
                </form>
            </div>
            <div className="my-4">
                &nbsp;
                {result && message && (<>
                    <strong>Result: </strong>{result}
                    <div className="badge badge-light mx-2" data-tip={tooltip}>
                        {trigger}
                    </div>
                    </>)}
                {adminText && <div>{adminText}</div>}
            </div>
        </>

    )
}

export default React.memo(TestTab)