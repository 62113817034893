import axios from "axios";
import {errorNotify} from "../utils/app";
import {CSRF, DIRECT_MESSAGES_ACTIONS} from "../constants";


export const requestConversationsSuccess = (data) => {
    return {
        type: DIRECT_MESSAGES_ACTIONS.REQUEST_CONVOS,
        payload: { data }
    }
}

export const requestMessagesSuccess = (data=[]) => {
    return {
        type: DIRECT_MESSAGES_ACTIONS.REQUEST_MESSAGES,
        payload: { data }
    }
}

export const addMessage = (data) => {
    return {
        type: DIRECT_MESSAGES_ACTIONS.ADD_DIRECT_MESSAGES,
        payload: { data }
    }
}

export const addLastActive = (data=null) => {
    return {
        type: DIRECT_MESSAGES_ACTIONS.ADD_LAST_ACTIVE,
        payload: { data }
    }
}

export const addConvo = (data) => {
    return {
        type: DIRECT_MESSAGES_ACTIONS.ADD_CONVO,
        payload: { data }
    }
}

export const editConvo  = (convoId, attributes) => {
    return {
        type: DIRECT_MESSAGES_ACTIONS.UPDATE_CONVO,
        payload: { convoId, attributes }
    }
}

export const deleteConvo  = (convoId) => {
    return {
        type: DIRECT_MESSAGES_ACTIONS.DELETE_CONVO,
        payload: { convoId }
    }
}

export const editMessage  = (messageId, attributes) => {
    return {
        type: DIRECT_MESSAGES_ACTIONS.UPDATE_DIRECT_MESSAGE,
        payload: { messageId, attributes }
    }
}

export const deleteMessage  = (messageId) => {
    return {
        type: DIRECT_MESSAGES_ACTIONS.DELETE_DIRECT_MESSAGE,
        payload: { messageId }
    }
}


export const requestConversations = () => {
    return (dispatch, getState) => {
        const filters = getState().direct_messages.filters
        const { bottom} = filters
        const filterBottomParams = Object.keys(bottom).map(key => key + '=' + bottom[key]).join('&');
        const pageId = getState().pages.facebook.activePage.details.id
        let url = `/pages/${pageId}/direct_messages/conversations?${encodeURI(filterBottomParams)}`
        try {
            axios.get(url)
                .then(json => {
                    const {data, unarchived_convos_count} = json.data
                    dispatch(requestConversationsSuccess(data))
                    dispatch(requestMessagesSuccess())
                    dispatch(setUnarchivedConvosCount(unarchived_convos_count))
                }).catch(error => errorNotify(error.response.data.error))
        } catch(e) {
            console.error("Error of requesting automations")
        }
    }
}

export const requestMessages = (senderId) => {
    return (dispatch, getState) => {
        const filters = getState().direct_messages.filters
        const {bottom} = filters
        const filterBottomParams = Object.keys(bottom).map(key => key + '=' + bottom[key]).join('&');
        const pageId = getState().pages.facebook.activePage.details.id
        let url = `/pages/${pageId}/direct_messages/?id=${senderId}&${encodeURI(filterBottomParams)}`
        try {
            axios.get(url)
                .then(json => {
                    dispatch(requestMessagesSuccess(json.data.data))
                }).catch(error => errorNotify(error.response.data.error))
        } catch(e) {
            console.error("Error of requesting automations")
        }
    }
}


export const sendMessage = (messageId, data) => {
    return (dispatch, getState) => {
        const pageId = getState().pages.facebook.activePage.details.id
        if(messageId) {
            data.append("id", messageId)
            try {
                axios.post(`/pages/${pageId}/direct_messages`, data, {headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': CSRF}})
                    .then(json => {
                        dispatch(addMessage(json.data.data))
                    }).catch(error => {
                })
            } catch (e) {
                console.error("")
            }
        }
    }
}

export const changeMessageFilter = (top=false, name=null, value=null) => {
    return (dispatch) => {
        dispatch({
            type: DIRECT_MESSAGES_ACTIONS.CHANGE_DM_FILTER,
            payload: { top, name, value }
        })
    }
}

export const updateConversation = (messageId, paramsName) => {
    return (dispatch, getState) => {
        const pageId = getState().pages.facebook.activePage.details.id
        try {
            axios.put(`/pages/${pageId}/direct_messages/${messageId}`, {dm_conversation: {[paramsName]: true}}, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    dispatch(requestConversations())
                    // dispatch(editConvo(json.data.data.attributes.convo_id, json.data.data.attributes))
                }).catch(error => console.error(error))
        } catch (e) {
            console.error("")
        }
    }
}

export const setUnarchivedConvosCount = (num=1) => {
    return {
        type: DIRECT_MESSAGES_ACTIONS.SET_UNARCHIVED_CONVOS_COUNT,
        payload: { num }
    }
}