import React, {forwardRef} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getIcon} from "../../../constants";
import {useSelector} from "react-redux";

const PageName = ({page_name, page_type}) => {
    const {list: filters} = useSelector(state => state.filters)
    const {by_page_id: page_id} = filters

    return (
        <>
        {!page_id && <div className='comment-p-name'>
            <FontAwesomeIcon icon={getIcon(`${page_type}_page`)} className="pe-1"/>
                {page_name}
            </div>}
        </>
    )
}

export default React.memo(PageName)