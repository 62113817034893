import React from 'react';
import FacebookLoginButton from "../../UI/FacebookLoginButton";
import './NotSigned.scss'
import {useAbility} from "@casl/react";
import {AbilityContext} from "../../../utils/app";

const NotSigned  = () => {
    const ability = useAbility(AbilityContext);

    return (
        <div className="col-lg-7">

            <h4 className="mb-4">
                Welcome to CommentGuard 👋
            </h4>

            <div className="connect-page-msg message my-4">
                Login with Facebook to connect your account.
            </div>

            <div className="connect-page-msg message my-4">
                Once your account is connected, you will be redirected back to CommentGuard to add the Facebook Pages or
                Instagram Business Accounts you'd like to be moderated.
            </div>
            {ability.can("create", "PagesController") && <FacebookLoginButton href="/auth/facebook" text="Login with Facebook"/>}
        </div>
    )
}

export default NotSigned;