import React from 'react'
import './SwitchItem.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";

const SwitchItem = (props) => {

  return (
      <div className={props.wrapperClass ? props.wrapperClass : "settings-item"} >
        <div className="form-check form-switch">
          <input onChange={props.changeHandler}
                 id={props.id || props.name}
                 className="form-check-input settings-form-inp"
                 value={props.value}
                 type="checkbox"
                 checked={props.checked}
                 defaultChecked={props.defaultChecked}
                 disabled={props.disabled}
                 name={props.name} />
            <label className="custom-control-label" htmlFor={props.name}>
              <div className="switch-desc">
                <div className={props.desc ? "switch-desc-heading switch-desc-p" : "switch-desc-heading"}>
                  {props.title}
                </div>
                {props.desc}
                {props.info && <FontAwesomeIcon icon={faCircleInfo} className="mx-2" data-tip={props.info} data-place={props.tooltip_place || "bottom"}/>}
              </div>
            </label>
            {props.children}
        </div>
      </div>

  )
}

export default React.memo(SwitchItem)
