import {COMMENTS_ACTIONS} from "../constants";

const initState = {
    commentsList: []
}


export function commentsReducer(state=initState, action) {
    const {payload} = action

    switch (action.type) {
        case COMMENTS_ACTIONS.REQUEST_PAGE_COMMENTS_SUCCESS:
            return {
                ...state,
                commentsList: payload.data.filter(comment => !payload.exceptCommentIds.includes(comment.id))
            }
        case COMMENTS_ACTIONS.ADD_NEW_COMMENT:
            return {
                ...state,
                commentsList: [payload.comment, ...state.commentsList]
            }
        case COMMENTS_ACTIONS.ADD_NEW_COMMENTS:
            return {
                ...state,
                commentsList: [...state.commentsList, ...payload.comments]
            }
        case COMMENTS_ACTIONS.DELETE_COMMENTS:
            return {
                ...state,
                commentsList: state.commentsList.filter(comment => !payload.commentIds.includes(comment.id))
            }
        case COMMENTS_ACTIONS.DELETE_COMMENT:
            return {
                ...state,
                commentsList: state.commentsList.filter(comment => comment.id !== payload.commentId)
            }
        case COMMENTS_ACTIONS.UPDATE_COMMENT:
            return {
                ...state,
                commentsList: state.commentsList.map(comment => {
                    if (Array.isArray(payload.commentId) ? payload.commentId.includes(comment.id) : comment.id === payload.commentId) {
                        if(payload.channel){payload.attributes.show_reply_form = comment.attributes.show_reply_form}
                        return {
                            ...comment,
                            attributes: Object.assign(comment.attributes, payload.attributes)
                        }
                    }else{
                        return comment
                    }
                })
            }
        case COMMENTS_ACTIONS.BAN_USER:
            return {
                ...state,
                commentsList: state.commentsList.map(comment => {
                    if (comment.attributes.author_id === payload.authorId) {
                        return {
                            ...comment,
                            attributes: {
                                ...comment.attributes,
                                author_banned: payload.banned,
                                ban_locked: payload.ban_locked
                            }
                        }
                    }else{
                        return comment
                    }
                })
            }
        case COMMENTS_ACTIONS.FLAG_USER:
            return {
                ...state,
                commentsList: state.commentsList.map(comment => {
                    if (comment.attributes.author_id === payload.authorId) {
                        return {
                            ...comment,
                            attributes: {
                                ...comment.attributes,
                                author_flagged: payload.flagged
                            }
                        }
                    }else{
                        return comment
                    }
                })
            }
        case COMMENTS_ACTIONS.SHOW_REPLY_FORM:
            return {
                ...state,
                commentsList: state.commentsList.map(comment => {
                    if (comment.id === payload.commentId) {
                        return {
                            ...comment,
                            attributes: {
                                ...comment.attributes,
                                show_reply_form: !comment.attributes.show_reply_form
                            }
                        }
                    }else{
                        if(comment.attributes.show_reply_form) {
                            return {
                                ...comment,
                                attributes: {
                                    ...comment.attributes,
                                    show_reply_form: false
                                }
                            }
                        }else{
                            return comment
                        }
                    }
                })
            }
        default:
            return state
    }
}