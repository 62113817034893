import React, {useEffect, useRef, useState} from 'react'
import {addFacebookPagesToUser, incUserPagesCount, userEdit} from "../../../../actions/user";
import {useDispatch} from "react-redux";
import FacebookLoginButton from "../../../UI/FacebookLoginButton";
import ManagePageList from "../../../UI/ManagePageList";
import PagesSelection from "../../../UI/PagesSelection";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {CSRF} from "../../../../constants";
import {errorNotify, successNotify} from "../../../../utils/app";
import {requestFacebookPages} from "../../../../actions/requests";
import ReactTooltip from "react-tooltip";
import ManualPageModal from "./ManualPageModal";


const AccountSettingsPages = ({account_level, profile, userPages}) => {
    const dispatch = useDispatch()
    const {fb_pages_limit} = account_level.attributes
    const {pages_count} = profile.attributes
    const fbPages = userPages.filter(page => page.type === "facebook_page")
    const instaPages = userPages.filter(page => page.type === "instagram_page")
    const [showManualPageModal, setShowManualPageModal] = useState(false)

    useEffect(()=>{
        dispatch(requestFacebookPages())
    })

    const handleInputChange = (e) => {
        e.preventDefault()
        if(e.currentTarget.value !== "New") {
            const formData = new FormData()
            formData.append("page[uuid]", e.currentTarget.value)

            axios({
                method: 'post',
                url: `/main/save_page_selection`,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF
                }
            }).then(response => {
                if (userPages.some(page => page.id === response.data.data.id)) {
                } else {
                    dispatch(addFacebookPagesToUser(response.data.data))
                    dispatch(incUserPagesCount())
                    // successNotify('Page has been successfully added')
                }
            }).catch(errors => {
                    errorNotify(errors.response.data.error, true, true)
                }
            );
        }
    }

    const handleClose = () => {
        setShowManualPageModal(false)
    }

    const handleOpenManually = (e) => {
        e.preventDefault()
        setShowManualPageModal(true)
    }

    return (
        <>
            <div className="my-4">
                Here you can connect your Facebook account and add pages to CommentGuard.
            </div>
            <div className="mb-4">
                <FacebookLoginButton href="/main/log_out" id="facebook_logout" text="Sign out"/>
            </div>
            <div className="col-lg-8 mb-4">

                {fbPages.length > 0 && <div className="mb-4">
                    <h4>
                        Facebook pages
                    </h4>
                    {fbPages.map((page) => {
                        return <ManagePageList key={page.id} pageDetails={page}></ManagePageList>
                    })}
                </div>}

                {instaPages.length > 0 && <div>
                    <h4>
                        Instagram pages
                    </h4>
                    {instaPages.map((page) => {
                        return <ManagePageList key={page.id} pageDetails={page}></ManagePageList>
                    })}

                </div>}


                {pages_count < fb_pages_limit
                    ?
                        // <div className="row d-flex justify-content-between mt-4 mb-3 mx-0">
                        <div className="col-lg-6 mt-4 mb-3 px-0">
                            <PagesSelection handleInputChange={handleInputChange}/>
                        </div>

                        // <div className="col-lg-4 d-flex align-items-center">
                        //     <a href="#"
                        //        className="g-link py-2"
                        //        target="_blank"
                        //        onClick={handleOpenManually}>
                        //         Add a page using the page ID
                        //     </a>
                        // </div>
                        //
                        //     {showManualPageModal && <ManualPageModal userPages={userPages} handleClose={handleClose}/>}
                        // </div>
                    : pages_count && <div className="mt-4 mb-3 px-0 fs-08">You’ve reached your maximum number of pages.</div>
                }

                {pages_count < fb_pages_limit &&
                    <a href="https://commentguard.io/missing-pages-in-the-dropdown/"
                        className="g-link"
                        target="_blank">
                        Are you missing pages in this dropdown?
                    </a>
                }
                <ReactTooltip effect="solid" html={true} className="switch-tooltip"/>
            </div>
        </>
    )
}

export default React.memo(AccountSettingsPages)