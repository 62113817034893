import React from 'react'

const AddUsersForm = ({profile, selectedUserId}) => {
    const {firstname, lastname, email, editable} = profile.attributes

    return (
        <>
            <div className="row">
                <div className="col-lg-8">
                    <div className="form-group mb-2">
                        <label>First name</label>
                        <input type="text" name="user[firstname]" disabled={selectedUserId ? !editable : false} defaultValue={firstname} className="form-control"/>
                    </div>
                    <div className="form-group mb-2">
                        <label>Last name</label>
                        <input type="text" name="user[lastname]" disabled={selectedUserId ? !editable : false} defaultValue={lastname} className="form-control"/>
                    </div>
                    <div className="form-group mb-2">
                        <label>
                            Email
                            <small className="mx-1">(login username)</small>
                        </label>
                        <input type="email" name="user[email]" disabled={email} defaultValue={email} className="form-control" required/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(AddUsersForm)