import React from 'react';
import './Footer.scss'
import ReactTooltip from "react-tooltip";

const Footer = () => {
    return (
        <nav className="footer navbar navbar-light navbar-expand bg-faded flex-shrink-0 px-0">
            <div className="d-flex justify-content-between align-items-center px-0 py-2 mx-0">
                <div className="footer-header">
                    Copyright © 2024 CommentGuard,
                    <span className="d-inline-block ms-1">all rights reserved.</span>
                </div>
                <div className="justify-content-center text-center">
                    <ul className="nav navbar-nav ms-auto">
                        <li className="nav-item">
                            <a className="nav-link" href='https://commentguard.io/support/' target="_blank">
                                Support
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href='https://commentguard.io/privacy-policy/' target="_blank">
                                Privacy Policy
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href='https://commentguard.io/terms-of-service/' target="_blank">
                                Terms of Service
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <ReactTooltip effect="solid" html={true} className="switch-tooltip"/>

        </nav>

    )
}

export default React.memo(Footer)