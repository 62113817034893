import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import NotificationItem from "../NotificationItem";
import "react-datetime/css/react-datetime.css";
import {
    createUserNotifications,
    deleteUserNotifications,
    requestUserNotifications,
    userEdit
} from "../../../actions/user";

import CustomLanguage from "../CustomLanguage";

const Notifications = (props) => {
    const dispatch = useDispatch()
    const notificationsForm = useRef()
    const userForm = useRef()


    const changeHandler = (e) => {
        const formData = new FormData(notificationsForm.current);

        if(e.currentTarget.checked){
            dispatch(createUserNotifications(formData))
        }else{
            dispatch(deleteUserNotifications(e.currentTarget.id))
        }
    }

    useEffect(()=>{
        dispatch(requestUserNotifications())
    })

    // const handleUserEdit = (e) => {
    //     e.preventDefault();
    //     console.log('e', e)
    //     const userFormData = new FormData(userForm.current);
    //
    //     dispatch(userEdit(userFormData))
    // }


    return (
        <>
            <div className="my-4">
                Here you can manage your email notifications.
            </div>

            <form ref={notificationsForm}>
                <NotificationItem title="Daily digest"
                            desc="When enabled, you’ll receive a daily email if there are comments that require your review."
                            value="daily_digest"
                            changeHandler={changeHandler}
                            form={notificationsForm}/>

            </form>
            <form ref={userForm}>
                <CustomLanguage title="Custom translations"
                                  desc="Choose the language you'd like to translate foreign comments to (our default is English)."/>

            </form>
        </>
    )
}

export default React.memo(Notifications)