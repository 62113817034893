import React, {useEffect, useState} from 'react'
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import SavedRepliesList from "./SavedRepliesList";
import SavedRepliesForm from "./SavedRepliesForm";
import ModalWindow from "../ModalWindow";
import {requestUserSavedReplies} from "../../../actions/requests";
import {useDispatch} from "react-redux";


const SavedRepliesModal = ({fade, setShowSavedReplies, setMessage, pageId}) => {
    const dispatch = useDispatch()
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedReply, setSelectedReply] = useState();
    const [disabledRepliesList, setDisabledRepliesList] = useState(false)

    useEffect(()=>{
        dispatch(requestUserSavedReplies(pageId))
    },[])

    return (
        <ModalWindow hideClose={true}
                     fade={fade}
                     toTop={true}
                     header='Saved replies'
                     closeHandler={() => setShowSavedReplies(false)}>

            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)} className="rect-tabs pt-2">
                <TabList>
                    <Tab disabled={disabledRepliesList} disabledClassName="d-none">Replies</Tab>
                    <Tab>{selectedReply ? 'Edit' :'Create'} reply</Tab>
                </TabList>

                <TabPanel forceRender={true}>
                    <SavedRepliesList setTabIndex={setTabIndex}
                                      pageId={pageId}
                                      setSelectedReply={setSelectedReply}
                                      setShowSavedReplies={setShowSavedReplies}
                                      setDisabledRepliesList={setDisabledRepliesList}
                                      setMessage={setMessage}/>
                </TabPanel>

                <TabPanel>
                    <SavedRepliesForm setTabIndex={setTabIndex}
                                      pageId={pageId}
                                      setShowSavedReplies={setShowSavedReplies}
                                      selectedReply={selectedReply}
                                      setSelectedReply={setSelectedReply}
                                      setDisabledRepliesList={setDisabledRepliesList}/>
                </TabPanel>
            </Tabs>
        </ModalWindow>
    )
}


export default React.memo(SavedRepliesModal)