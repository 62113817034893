import React, {useState} from 'react'
import axios from "axios";
import {CSRF} from "../../../../constants";
import {capitalize, errorNotify} from "../../../../utils/app";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLanguage} from "@fortawesome/free-solid-svg-icons";
import Button from "../../Button";
import {deleteComments, updateComment} from "../../../../actions/pages";
import {useDispatch} from "react-redux";

const Translate = ({pageId, objectId, isMobile, inputRef, className = "square-btn", dm=false}) => {
    const dispatch = useDispatch()
    const [translated, setTranslated] = useState(false)
    const [origMessage, setOrigMessage] = useState("")

    const translate = (e) => {
        e.preventDefault()

        if(!translated) {
            const formData = new FormData()
            !dm && formData.append("page_id", pageId)
            if(inputRef){
                formData.append("message", inputRef.current.state.lastOriginalValueReported)
            }

            axios({
                method: 'post',
                url: dm ? `/pages/${pageId}/direct_messages/${objectId}/translate` : `/comments/${objectId}/translate`,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF
                }
            }).then(response => {
                if (response.data.success) {
                    setTranslated(true)
                    setOrigMessage(response.data.original_message)
                    if(inputRef){
                        inputRef.current.removeAllTags()
                        inputRef.current.injectAtCaret(response.data.text)
                    }else {
                        if(dm){

                        }else{
                            dispatch(updateComment(objectId, {message: response.data.text}))

                        }
                    }
                } else {
                    errorNotify(response.data.text)
                }

            }).catch(error => {
                    errorNotify(error.response.data.error)
                }
            );
        }else{
            setTranslated(false)
            if(inputRef){
                inputRef.current.removeAllTags()
                inputRef.current.injectAtCaret(origMessage)
            }else {
                !dm && dispatch(updateComment(objectId, {message: origMessage}))
            }
        }
    }

    return (
        <button type="button" className={`${className} ${translated ? 'active' : ''}`} onClick={translate} data-tip={isMobile ? '' : "Translate"}
                aria-expanded="false">
            <FontAwesomeIcon icon={faLanguage} className="fa-fw"/>
        </button>
    )
}

export default React.memo(Translate)