import React, {useEffect, useRef, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getIcon} from "../../../../constants";
import Button from "../../../UI/Button";
import {addCurrentPage, addPage, initCurrentPages, removeCurrentPage, removePage} from "../../../../actions/brands";
import {useDispatch, useSelector} from "react-redux";
import PagesSelectionItem from "../../../UI/PagesSelectionItem";
import {requestBrands} from "../../../../actions/requests";

const BrandsPage = (props) => {
    const dispatch = useDispatch()
    const {selectedBrand: brand, selectedPages, availablePages} = useSelector(state => state.brands)
    const {pages: brandsPages} = brand.attributes || {}

    const addPages = (page) => {
        dispatch(addCurrentPage(page))
        dispatch(removePage(page.id))
    }

    const removePages = (page) => {
        dispatch(removeCurrentPage(page.id))
        dispatch(addPage(page))
    }

    useEffect(()=>{
        dispatch(initCurrentPages(brandsPages || []))
    },[brand.id])

    return (
        <div className="my-2 page-lists-brands">
            <div className="mb-2">
                <div className="accounts-list position-relative">
                    <label>Pages</label>
                    <button type="button" className="form-select dropdown-toggle dropdown-toggle-n actions-btn" data-bs-toggle="dropdown"
                            aria-expanded="false">
                        <span className="ms-2">Select pages</span>
                    </button>
                    <ul className="dropdown-menu w-100 pages-selector">
                        {availablePages.sort((a, b) => a.attributes.displayName.localeCompare(b.attributes.displayName)).map((page, index) => {

                            return <div key={index}>
                                <PagesSelectionItem
                                    pageId={page.id}
                                    handleInputChange={(e) => {
                                        e.preventDefault()
                                        addPages(page)
                                    }}
                                    pageType={page.type}
                                    pageName={page.attributes.displayName}/>
                            </div>
                        })}

                    </ul>
                </div>
            </div>
            {selectedPages.length > 0 && <div className="row">
                {selectedPages.map((page) => {
                    return <div className="d-flex b-selected-pages justify-content-between" key={page.id}>
                        <div className="d-flex">
                            <div className='d-inline page-name align-self-center me-2' >
                                <FontAwesomeIcon icon={getIcon(page.type)} className="fa-fw"/>
                                {page.attributes.displayName}
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="message-xs remove-page-wrapper align-self-center">
                                <Button className="remove-page-link" clickHandler={(e) => {
                                    e.stopPropagation()
                                    removePages(page)}
                                }>
                                    Remove this page?
                                </Button>
                            </div>
                        </div>
                    </div>})}
            </div>}
        </div>
    )
}

export default React.memo(BrandsPage)