import React, {useEffect, useState} from 'react'
import { Flex, Title, Icon, TabGroup, TabList, Tab, AreaChart, Text, Color } from "@tremor/react";

import './Analytic.scss'

// import "tailwindcss/utilities.css";
import {getAnalytics, requestRules} from "../../../actions/requests";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {CSRF} from "../../../constants";



const Analytics = (props) => {

    const dispatch = useDispatch()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const {id: pageId} = useSelector(state => state.pages.facebook.activePage.details)
    const [data, setData] = useState([])
    const kpiList = ["Total", "Hidden"]
    const selectedKpi = kpiList[selectedIndex]


    useEffect(()=>{
        axios.post(`/analytics`, {page_id: pageId}, {headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': CSRF}})
            .then(json => {
                console.log("analytics data", json.data)
                setData(json.data)
            }).catch(error => {
            // errorNotify(error.response.data.error)
            // error.response.data.remove && dispatch(deleteComments(commentId))
        })
    },[pageId])

    const areaChartArgs = {
        className: "mt-5 h-72",
        index: "date",
        categories: [selectedKpi],
        colors: ["blue"],
        showLegend: false,
        yAxisWidth: 56,
        allowDecimals: false
    }

    return (
        <div className="analytics">
            <div className="md:flex justify-between">
                <div>
                    {data.length <= 0 &&
                        <div className="my-4">
                            Nothing to see here…
                        </div>
                    }
                </div>
                <div>
                    {data.length > 0 &&
                        <TabGroup index={selectedIndex} onIndexChange={setSelectedIndex}>
                            <TabList variant="solid">
                                <Tab className="py-2">Total comments</Tab>
                                <Tab className="py-2">Hidden comments</Tab>
                            </TabList>
                        </TabGroup>
                    }
                </div>
            </div>


            {data.length > 0 &&
                <>
                    <div className="mt-8 hidden sm:block">
                        <AreaChart {...areaChartArgs} data={data}/>
                    </div>

                    <div className="mt-8 sm:hidden">
                        <AreaChart {...areaChartArgs} data={data} startEndOnly={true} showGradient={false} showYAxis={false} />
                    </div>
                </>
            }
        </div>
    )
}

export default React.memo(Analytics)