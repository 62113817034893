import React, {useRef, useState} from 'react'
import Button from "../Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBoxArchive,
    faEye,
    faEyeSlash,
    faThumbsUp,
    faReply,
    faTrashAlt, faUserSlash, faMagnifyingGlass
} from "@fortawesome/free-solid-svg-icons";
import {
    sendBanUser,
    sendCommentDelete,
    sendUnbanUser,
    setCommentLikeUnlike, setCommentReview,
    setCommentVisibility
} from "../../../actions/requests";
import {addNewTmpComment, showReplyForm} from "../../../actions/pages";
import {useDispatch, useSelector} from "react-redux";
import PreviewBtn from "./Actions/PreviewBtn";
import Translate from "./Actions/Translate";
import ModalWindow from "../ModalWindow";
import axios from "axios";
import {CSRF} from "../../../constants";
import {changeProfile, updateUserSubUsers} from "../../../actions/user";
import {errorNotify} from "../../../utils/app";

const Actions = ({comment}) => {
    const dispatch = useDispatch()
    const {is_hidden, liked, message, can_remove, can_hide, can_like, can_comment, can_ban, url, comment_type, author_banned,
        reviewed, page_type, page_id, ban_locked, mark_as_deleted} = comment.attributes
    const [showRemoveCommentModal, setShowRemoveCommentModal] = useState(false)
    const {attributes} = useSelector(state => state.user.profile)
    const notifyCheck = useRef()

    const handleShowReplyForm = (e) => {
        dispatch(showReplyForm(comment.id))
    }

    const handleRemoveComment = (e) => {
        e.preventDefault();
        dispatch(sendCommentDelete(comment.id, page_id, notifyCheck.current.checked))

        if(notifyCheck.current.checked){
            dispatch(changeProfile({comments_delete_notify: true}))
        }
    }

    const review = (e) => {
        e.stopPropagation()
        if(!reviewed){
            dispatch(addNewTmpComment(comment.id))
        }
        dispatch(setCommentReview(comment.id, reviewed, page_id))
    }

    const isMobile = /Mobi|Android/i.test(navigator.userAgent)

    return (
        <div className="actions-list">
            {!mark_as_deleted &&
                <>
                {url && <PreviewBtn pageId={page_id} commentId={comment.id} type={page_type} url={url} isMobile={isMobile}/>}
                {message && <Translate pageId={page_id} objectId={comment.id} isMobile={isMobile}/>}

                {(page_type!=="instagram" && can_like) && <Button className={`square-btn ${liked ? 'active' : ''}`}
                    clickHandler={()=>{dispatch(setCommentLikeUnlike(comment.id, page_id))}}
                    dataTip={isMobile ? '' : (liked ? 'Unlike' : 'Like')}>
                    <FontAwesomeIcon icon={faThumbsUp} className="fa-fw "/>
                    </Button>}
                <Button className={`square-btn `} dataTip={isMobile ? '' : 'Reply'} clickHandler={handleShowReplyForm}>
                    <FontAwesomeIcon icon={faReply} className="fa-fw"/>
                </Button>
                {can_hide && <Button className={`square-btn`}
                    disabled={!can_hide}
                    clickHandler={(e) => dispatch(setCommentVisibility(comment.id, page_id))}
                    dataTip={isMobile ? '' : (is_hidden ? 'Unhide' : 'Hide')}>
                    <FontAwesomeIcon icon={is_hidden ? faEye : faEyeSlash} className="fa-fw"/>
                    </Button>}
                {comment_type != "review" && comment_type != "review_comment" && <Button className={`square-btn`}
                    clickHandler={(e) => attributes.comments_delete_notify ? dispatch(sendCommentDelete(comment.id, page_id)) : setShowRemoveCommentModal(true) }
                    dataTip={isMobile ? '' : "Delete"}>
                    <FontAwesomeIcon icon={faTrashAlt} className="fa-fw"/>
                    </Button>}
                    

                {can_ban && page_type === "facebook" &&
                    <Button className={`square-btn ${author_banned ? 'active-alt' : ''} ${ban_locked ? 'disabled' : ''}`}
                            disabled={ban_locked}
                        clickHandler={(e) => {author_banned ? dispatch(sendUnbanUser(comment.id, page_id)) : dispatch(sendBanUser(comment.id, page_id))}}
                        dataTip={isMobile ? '' : (ban_locked ? "Action will be available 48 hours after blocking" : author_banned ? 'Unblock' : 'Block')}>
                        <FontAwesomeIcon icon={faUserSlash} className="fa-fw"/>
                    </Button>}
                    <Button className={`square-btn ${reviewed ? 'active' : ''}`}
                    clickHandler={review}
                    dataTip={isMobile ? '' : (reviewed ? 'Unarchive' : 'Archive')}>
                    <FontAwesomeIcon icon={faBoxArchive} className="fa-fw"/>
                    </Button>

                    {showRemoveCommentModal && !attributes.comments_delete_notify &&
                    <ModalWindow hideClose={true}
                                 header="Are you sure?"
                                 closeHandler={() => setShowRemoveCommentModal(false)}>
                        <div className="mb-4">
                            Deleting is an action that can't be reversed.
                        </div>
                        <div className="row">
                            <form onSubmit={handleRemoveComment} >
                                <input className="form-check-input mt-3" type="checkbox" name="comments_delete_notify" ref={notifyCheck}/>
                                <label className="mx-2 mt-3 fs-08">Don’t ask me again</label>
                                <button type="submit" className="btn btn-danger float-end">Delete</button>
                                <button type="reset" className="btn btn-secondary float-end mx-2 delete-btn"
                                        onClick={() => setShowRemoveCommentModal(false)}>Cancel</button>
                            </form>
                        </div>
                    </ModalWindow>
                    }
                </>
            }
        </div>
    )
}

export default React.memo(Actions)