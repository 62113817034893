import {toast} from "react-toastify";
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import {AbilityBuilder, Ability, detectSubjectType} from "@casl/ability";
import data from '@emoji-mart/data/sets/14/facebook.json'
import { Picker } from 'emoji-mart'
import React,{createContext, useEffect, useRef} from 'react';
import { createContextualCan } from '@casl/react';
import {SanitizeHTML} from "../constants";

export function errorNotify(text="Error", auto_close = true, force=false) {
  if(force){
    text = (<SanitizeHTML html={text}/>)
    toast.warning(text, {
      position: "top-right",
      autoClose: auto_close ? 5000 : false,
      hideProgressBar: false,
      newestOnTop: true,
      closeOnClick: true
    })
  }
}


export function successNotify(text="") {
  // toast.success(text, {
  //   position: "top-right",
  //   autoClose: 3000,
  //   hideProgressBar: false,
  //   closeOnClick: true
  // })
}


export function defineAbilities(rules) {
  return new Ability(rules)
}

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""

export const filtersIsChanged = (filters) => {
  const checking = (({ order_by_time, unreviewed, mark_as_deleted, by_page_id, ...o }) => o)(filters)
  return Object.values(checking).filter(function(e){return e}).length > 0
}

export const dmFiltersIsChanged = (filters) => {
  const checking = (({ ...o }) => o)(filters)
  return Object.values(checking).filter(function(e){return e}).length > 0
}

export function EmojiPicker(props) {
  const ref = useRef()
  const style = document.createElement( 'style' )
  style.innerHTML = `
    #nav button[aria-selected] {
      color: #2bae76 !important;
    }
    #nav button:hover{
      color: #2bae76 !important;
    }
    #nav .bar{
      background-color: #2bae76 !important;
    }`

  useEffect(() => {
    const picker = new Picker({ ...props, data, ref })
    picker.shadowRoot.appendChild( style )
  }, [])

  return <div ref={ref}/>
}
