import React, {useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CSRF, getIcon} from "../../../constants";
import PagesSelectionItem from "../PagesSelectionItem";
import {useDispatch, useSelector} from "react-redux";
import Button from "../Button";
import axios from "axios";
import {errorNotify} from "../../../utils/app";
import {changePage, requestRulesSuccess} from "../../../actions/pages";

const CopySettings = ({setEmojiList, objectType="settings"}) => {
    const dispatch = useDispatch()
    const pages = useSelector(state => state.user.pages)
    const active_page = useSelector(state => state.pages.facebook.activePage)
    const [selectedPage, setSelectedPage] = useState();

    const copyPageSettings = (e) => {
        e.preventDefault()
        axios.patch(`/pages/${active_page.details.id}/copy_settings`, {from_page_id: selectedPage.id}, {headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': CSRF}})
            .then(json => {
                dispatch(changePage(json.data.attributes))
                setEmojiList(json.data.attributes.emoji_list)
            }).catch(error => {
            console.error(error)
        })
    }

    const copyPageRules = (e) => {
        e.preventDefault()
        axios.patch(`/pages/${active_page.details.id}/copy_rules`, {from_page_id: selectedPage.id}, {headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': CSRF}})
            .then(json => {
                dispatch(requestRulesSuccess(json.data.data))
            }).catch(error => {
            console.error(error)
        })
    }

    return (
        <>
            {pages.length > 1 &&
                <>
                    <div className="line mt-4"></div>
                    <div className="mt-4 mb-2 fw-bold">
                        Copy {objectType} from
                    </div>

                    <div className="row">
                        <div className="col-sm-5  mb-2">
                            <div className="accounts-list position-relative">
                                <button type="button" className="form-select dropdown-toggle dropdown-toggle-n actions-btn" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                    {selectedPage ?
                                        <>
                                            <FontAwesomeIcon icon={getIcon(selectedPage.type)} className="fa-fw"/>
                                            <span className="ms-2">{selectedPage.name}</span>
                                        </>
                                        : <span className="ms-2">Select page</span>}

                                </button>
                                <ul className="dropdown-menu w-100 pages-selector">
                                    {pages.map((page, index) => {

                                        return <div key={page.id}>
                                            {page.id !== active_page.details.id &&
                                                <PagesSelectionItem
                                                    pageId={page.id}
                                                    handleInputChange={()=> setSelectedPage({id: page.id, type: page.type, name: page.attributes.displayName})}
                                                    pageType={page.type}
                                                    pageName={page.attributes.displayName}/>
                                            }
                                        </div>
                                    })}

                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            {selectedPage && <Button name="button" className="btn btn-primary" clickHandler={objectType === "settings" ? copyPageSettings : copyPageRules}>Apply</Button>}
                        </div>
                    </div>
                    {objectType == "automations" &&
                        <div className="mb-4 copy-rules-info">
                            Copying automations from another page will overwrite your existing ones.
                        </div>
                    }
                </>
            }
        </>
    )
}

export default React.memo(CopySettings)