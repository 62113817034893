import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import CommentsList from "../CommentsList";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "./Comments.scss"
import {changeFilter} from "../../../actions/pages";
import {requestPageComments} from "../../../actions/requests";

const Comments = (props) => {
    const dispatch = useDispatch()
    const {unreviewed_comments_count} = useSelector(state => state.pages.facebook.activePage.details ? state.pages.facebook.activePage.details.attributes : 0)
    const {attributes} = useSelector(state => state.user.profile)
    const {expire_in} = attributes

    const setUnreview = (index) => {
        dispatch(changeFilter("unreviewed", index == 0))
        dispatch(changeFilter("mark_as_deleted", index == 2))
        dispatch(requestPageComments())
    }

    return (
        <>
          <Tabs onSelect={setUnreview}>
            <TabList className="react-tabs__tab-list filters">
              <Tab>
                  Inbox
                  {unreviewed_comments_count > 0 && <span className="comments-counter px-1">{unreviewed_comments_count}</span>}
              </Tab>
              <Tab >Archive</Tab>
              <Tab >Bin</Tab>
            </TabList>

            <TabPanel>
            </TabPanel>
            <TabPanel >
            </TabPanel>
            <TabPanel >
            </TabPanel>
          </Tabs>
            <CommentsList inbox_zero={props.inbox_zero}/>
            {expire_in === 0 && <div className="expired-backdrop"></div>}
        </>
    )
}

export default React.memo(Comments)