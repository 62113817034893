import React, {useCallback, useRef, useEffect, useState} from 'react'
import Tags from "../../../utils/@yaireo/tagify/dist/react.tagify"
import "../../../utils/@yaireo/tagify/dist/tagify.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-regular-svg-icons";
import {setShowEmojiModal} from "../../../actions/ui";

const TagInput = ({placeholder, hideInput, onChange, tagifyRef, settings, tags, copy, inputId, name}) => {
  const tagList = useRef(null)

  const baseTagifySettings = {
    placeholder: placeholder || "Type here (press enter after each word)",
    originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(','),
    userInput: hideInput ? false : true
  }
  const onChanges = useCallback(e => {
    tagList.current.value = e.detail.value
    onChange && onChange()
  }, [])


    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(tagList.current.value)
            .then(() => {

            })
            .catch((err) => {
                console.log(err);
            });
    }

  const copyTags = (e) => {
      console.log(tagList.current.value)

      navigator.permissions.query({ name: "write-on-clipboard" }).then((result) => {
          if (result.state == "granted" || result.state == "prompt") {
              alert("Write access granted!");
          }
      });
  }


  return (
      <>
        <Tags
            settings={Object.assign(baseTagifySettings, settings)}
            value={tags}
            onChange={onChanges}
            tagifyRef={tagifyRef}
        />
          {copy &&
              <div className="p-0 copy-tags icon-buttons" onClick={handleCopyClick}>
                  <FontAwesomeIcon icon={faCopy}
                                   className="copy-tags-icon mx-2"
                                   size="lg"/>
              </div>
          }

        <input id={inputId || "tag-list"} type="hidden" name={name} value={tags} ref={tagList}/>
      </>

  )
}

export default React.memo(TagInput)