import {COMMENTS_ACTIONS, PAGES_ACTIONS, USER_ACTIONS} from "../constants";

export const loadFacebookPagesStarted = () => {
    return { type: PAGES_ACTIONS.LOAD_FACEBOOK_PAGES_STARTED }
}

export const loadFacebookPagesFailed = (selectionList) => {
    return {
        type: PAGES_ACTIONS.LOAD_FACEBOOK_PAGES_FAILED,
        payload: { selectionList }
    }
}

export const loadFacebookPagesCompleted = (selectionList) => {
    return {
        type: PAGES_ACTIONS.LOAD_FACEBOOK_PAGES_COMPLETED,
        payload: { selectionList }
    }
}

export const requestPageDetailsSuccess = (data) => {
    return {
        type: PAGES_ACTIONS.REQUEST_PAGE_DETAILS_SUCCESS,
        payload: { data }
    }
}

export const resetPageDetails = () => ({ type: PAGES_ACTIONS.RESET_PAGE_DETAILS })

export const requestPageCommentsStart = () => ({ type: COMMENTS_ACTIONS.REQUEST_PAGE_COMMENTS_STARTED })

export const requestPageCommentsSuccess = (data, exceptCommentIds = [], setExcluded = false) => {
    return (dispatch, getState) => {
        if(setExcluded) {
            exceptCommentIds = getState().tmpComments.commentsList
        }
        dispatch({
            type: COMMENTS_ACTIONS.REQUEST_PAGE_COMMENTS_SUCCESS,
            payload: { data, exceptCommentIds}
        })
    }
}

export const requestPageCommentsFailed = (error) => {
    return {
        type: COMMENTS_ACTIONS.REQUEST_PAGE_COMMENTS_FAILED,
        payload: error
    }
}

export const removeFacebookPageFromActive = (pageId) => {
    return {
        type: PAGES_ACTIONS.REMOVE_FACEBOOK_PAGES_FROM_ACTIVE,
        payload: { pageId }
    }
}
export const addNewComment = (comment) => {
    return {
        type: COMMENTS_ACTIONS.ADD_NEW_COMMENT,
        payload: { comment }
    }
}

export const addNewTmpComment = (comment) => {
    return {
        type: COMMENTS_ACTIONS.ADD_NEW_TMP_COMMENT,
        payload: { comment }
    }
}

export const resetTmpComments = () => ({ type: COMMENTS_ACTIONS.RESET_TMP_COMMENTS })

export const addNewComments = (comments) => {
    return {
        type: COMMENTS_ACTIONS.ADD_NEW_COMMENTS,
        payload: { comments }
    }
}


export const deleteComment = (commentId) => {
    return {
        type: COMMENTS_ACTIONS.DELETE_COMMENT,
        payload: { commentId }
    }
}

export const deleteComments = (commentIds) => {
    return {
        type: COMMENTS_ACTIONS.DELETE_COMMENTS,
        payload: { commentIds }
    }
}

export const banUser = (authorId, banned, ban_locked=false) => {
    return {
        type: COMMENTS_ACTIONS.BAN_USER,
        payload: { authorId, banned, ban_locked }
    }
}

export const flagUser = (authorId, flagged) => {
    return {
        type: COMMENTS_ACTIONS.FLAG_USER,
        payload: { authorId, flagged }
    }
}

export const updateComment = (commentId, attributes, old_comment_id=null, channel=false) => {
    return {
        type: COMMENTS_ACTIONS.UPDATE_COMMENT,
        payload: { commentId, attributes, old_comment_id, channel }
    }
}

export const incUnreviewedCommentsCounter = () => ({ type: PAGES_ACTIONS.INC_UNREVIEWED_COMMENTS_COUNTER })

export const decUnreviewedCommentsCounter = (num=1) => {
    return {
        type: PAGES_ACTIONS.DEC_UNREVIEWED_COMMENTS_COUNTER,
        payload: { num }
    }
}

export const setUnreviewedCommentsCounter = (num=1) => {
    return {
        type: PAGES_ACTIONS.SET_UNREVIEWED_COMMENTS_COUNTER,
        payload: { num }
    }
}

export const changeFilter = (name=null, value=null) => {
    return (dispatch) => {
        dispatch(resetTmpComments())
        dispatch({
            type: COMMENTS_ACTIONS.CHANGE_FILTER,
            payload: { name, value }
        })
    }
}

export const loadLinks = (links) => {
    return {
        type: COMMENTS_ACTIONS.LOAD_LINKS,
        payload: { links }
    }
}

export const changeLinks = (links) => {
    return {
        type: COMMENTS_ACTIONS.CHANGE_LINKS,
        payload: { links }
    }
}

export const changePage = (attributes) => {
    return {
        type: PAGES_ACTIONS.CHANGE_PAGE,
        payload: { attributes }
    }
}

export const requestRulesSuccess = (data) => {
    return {
        type: PAGES_ACTIONS.REQUEST_RULES,
        payload: { data }
    }
}

export const addRule = (data) => {
    return {
        type: PAGES_ACTIONS.ADD_RULES,
        payload: { data }
    }
}

export const editRule  = (ruleId, attributes) => {
    return {
        type: PAGES_ACTIONS.UPDATE_RULES,
        payload: { ruleId, attributes }
    }
}

export const removeRule  = (ruleId) => {
    return {
        type: PAGES_ACTIONS.REMOVE_RULES,
        payload: { ruleId }
    }
}

export const showReplyForm = (commentId) => {
    return {
        type: COMMENTS_ACTIONS.SHOW_REPLY_FORM,
        payload: { commentId }
    }
}