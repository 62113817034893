import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import "./Pagination.scss"
import ReactPaginate from "react-paginate";

const Pagination = ({ details, handlePageChange }) => {
  const { total_pages } = details

  const handlePageClick = (event) => {
    handlePageChange(event.selected + 1)
  }

  return (
      <nav className="mt-4">
        <ReactPaginate
            breakLabel="..."
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={total_pages}
            previousLabel={<FontAwesomeIcon icon={faArrowLeft}/>}
            nextLabel={<FontAwesomeIcon icon={faArrowRight}/>}
            className='pagination justify-content-center'
            pageClassName='page-item'
            previousClassName='page-item'
            nextClassName='page-item'
            breakClassName='page-item'
            pageLinkClassName='page-link'
            previousLinkClassName='page-link'
            nextLinkClassName='page-link'
            breakLinkClassName='page-link'
            activeClassName='active'
            renderOnZeroPageCount={null}
        />
      </nav>
  )
}

export default React.memo(Pagination)
