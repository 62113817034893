import React from 'react';
import BrandLogo from "images/logo.svg"

const Brand  = () => {
    return (
        <a className="navbar-brand d-flex" href="/" data-turbolinks={false}>
            <img src={BrandLogo} alt="logo" className="logo" width="210" height="25"/>
        </a>
    )
}

export default React.memo(Brand)