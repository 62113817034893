import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import "./Brands.scss"
import {deleteBrands, requestBrands, requestUserDetails, updateBrands} from "../../../../actions/requests";
import Button from "../../../UI/Button";
import BrandsModal from "./BrandsModal";
import {removeBrand, setSelectedBrand} from "../../../../actions/brands";
import ModalWindow from "../../../UI/ModalWindow";
import SwitchItem from "../../../UI/SwitchItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getIcon} from "../../../../constants";
import {setShowBrandsModal} from "../../../../actions/ui";
import BrandsItem from "./BrandsItem";
import {initNotificationChannel} from "../../../../channels/notifications_channel";

const Brands = (props) => {
    const dispatch = useDispatch()
    const {brandsList, availablePages} = useSelector(state => state.brands)
    const [showRemoveBrandsModal, setShowRemoveBrandsModal] = useState(false)

    useEffect(()=>{
        dispatch(requestBrands())
    },[])

    useEffect(()=>{
        if(!showRemoveBrandsModal){
            dispatch(setSelectedBrand())
        }
    },[showRemoveBrandsModal])

    const editBrandModal = (brand) => {
        dispatch(setSelectedBrand(brand))
        dispatch(setShowBrandsModal())
    }

    const handleRemoveBrand = (e) => {
        e.preventDefault()
        dispatch(deleteBrands())
        setShowRemoveBrandsModal(false)
    }

    const closeRemoveModal = (e) => {
        setShowRemoveBrandsModal(false)
    }

    const enableBrand = (e, id) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("brand[enabled]", e.target.checked)
        dispatch(updateBrands(id, formData))
    }

    return (
        <>
            <div className="my-4">
                Here you can add custom knowledge about your brands, so AI can generate more accurate replies.
            </div>

            <div className="mb-4">
                {brandsList.map((brand,index) => {
                    return <BrandsItem key={brand.id}
                                       brandId={index}
                                       setShowRemoveBrandsModal={setShowRemoveBrandsModal}/>
                })}
            </div>

            <button className="btn btn-primary mb-4" onClick={()=>{dispatch(setShowBrandsModal())}}>Create brand</button>

            <BrandsModal />

            {showRemoveBrandsModal &&
                <ModalWindow hideClose={true}
                             header="Are you sure you want to delete this brand?"
                             closeHandler={() => closeRemoveModal()}>
                    <div className="mb-4">
                        If you delete this brand it will no longer generate responses with accurate information for selected pages.
                    </div>
                    <div className="row">
                        <form onSubmit={handleRemoveBrand}>
                            <button type="submit" className="btn btn-danger float-end">Delete</button>
                            <button type="reset" className="btn btn-secondary float-end mx-2"
                                    onClick={() => closeRemoveModal()}>Cancel</button>
                        </form>
                    </div>
                </ModalWindow>
            }
        </>
    )
}

export default React.memo(Brands)