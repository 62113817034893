import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import SwitchItem from "../SwitchItem";
import {updatePage} from "../../../actions/requests";
import Button from "../Button";
import TagInput from "../TagInput";
import './Moderation.scss'
import axios from "axios";
import {CSRF} from "../../../constants";
import {AbilityContext, errorNotify, successNotify} from "../../../utils/app";
import TestTab from "../TestTab";
import {useAbility} from "@casl/react";
import EmojiContainer from "../EmojiContainer";
import {faFaceSmile} from "@fortawesome/free-regular-svg-icons";
import {setShowEmojiModal} from "../../../actions/ui";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import CopySettings from "./CopySettings";

const Moderation = (props) =>{
  const dispatch = useDispatch()
  const ability = useAbility(AbilityContext)
  const hostname = window.location.hostname
  const page = useSelector(state => state.pages.facebook.activePage)
  const {name, private_mode, hide_urls, hide_urls_advanced, hide_urls_list, hide_mentions, hide_mentions_advanced, hide_mentions_list,
    hide_images, hide_emojis, auto_ban, google_nlp, google_score, google_magnitude, google_advanced, blacklist, whitelist, private_mode_advanced,
    tags, hide_offensive, emoji_list, hide_all_emojis, disable_organic, detect_email_phone} = page.details.attributes
  const [emojiList, setEmojiList] = useState(emoji_list)
  const [emojiSel, setEmojiSel] = useState()

  const user = useSelector(state => state.user)
  const { account_level } = user

  useEffect(()=>{
    ReactTooltip.rebuild()
  },[])

  const {
    hide_offensive: usr_hide_offensive,
    blacklist: usr_blacklist,
    hide_images: usr_hide_images,
    hide_emojis: usr_hide_emojis,
    private_mode: usr_private_mode,
    google_nlp: usr_google_nlp,
    disable_organic: usr_disable_organic,
    detect_email_phone: usr_detect_email_phone} = account_level.attributes

  const changeHandler = (e) =>{
    const hideUrlEl = document.getElementById("page[hide_urls]")
    const hideUrlAdv = document.getElementById("hide_urls_advanced")
    const hideUrlList = document.getElementById("hide_urls_list")
    const hideMentionsEl = document.getElementById("page[hide_mentions]")
    const hideMentionsAdv = document.getElementById("hide_mentions_advanced")
    const hideMentionsList = document.getElementById("hide_mentions_list")
    const hideImgEl = document.getElementById("page[hide_images]")
    const hideEmojisEl = document.getElementById("page[hide_emojis]")
    const hideAllEmojisEl = document.getElementById("page[hide_all_emojis]")
    const emojiListEl = document.getElementById("emoji_list")
    const privateModeEl = document.getElementById("page[private_mode]")
    const tagList = document.getElementById("tag-list")
    const hideOffensiveEl = document.getElementById("page[hide_offensive]")
    const disableOrganicEl = document.getElementById("page[disable_organic]")
    const detectEmailPhoneEl = document.getElementById("page[detect_email_phone]")
    const googleNlpEl = document.getElementById("page[google_nlp]")
    const googleScoreEl = document.getElementById("page[google_score]")
    const googleMagEl = document.getElementById("page[google_magnitude]")
    const blacklist_d = document.getElementById("page[blacklist]")
    const privateModeAdv = document.getElementById("private_mode_advanced")
    const whitelistEl = document.getElementById("page[whitelist]")

    const formData = new FormData()
    const checkboxes = document.querySelectorAll("input[type=checkbox]")
    Array.from(checkboxes).map(checkbox => {
      formData.append(checkbox.name, checkbox.checked)
    })

    if(blacklist){
      formData.append("page[tags]", tagList.value)
    }

    if(google_nlp){
      googleScoreEl && formData.append("page[google_score]", googleScoreEl.value)
      googleMagEl && formData.append("page[google_magnitude]", googleMagEl.value)
    }

    if(hide_emojis){
      formData.append("page[hide_all_emojis]", hideAllEmojisEl.value)
      formData.append("page[emoji_list]", emojiListEl ? emojiListEl.value : "")
    }else{
      formData.append("page[hide_all_emojis]", true)
      formData.append("page[emoji_list]", "")
    }

    if(e.target == googleNlpEl && !e.target.checked){
      googleScoreEl && (googleScoreEl.checked = false)
      googleMagEl && (googleMagEl.checked = false)
      formData.append("page[google_score]", "0")
      formData.append("page[google_magnitude]", "0.5")
      formData.append("page[google_advanced]", false)
    }

    if(e.target == hideUrlEl && !e.target.checked){
      hideUrlAdv && (hideUrlAdv.checked = false)
      formData.append("page[hide_urls_list]", "")
      formData.append("page[hide_urls_advanced]", false)
      whitelistEl && !whitelistEl.value && formData.append("page[whitelist]", "")
    }

    if(e.target == privateModeEl && !e.target.checked){
      privateModeAdv && (privateModeAdv.checked = false)
      formData.append("page[private_mode_advanced]", false)
    }

    if(private_mode && whitelistEl){
      whitelistEl && formData.append("page[whitelist]", whitelistEl.value)
    }

    if(hide_urls){
      hideUrlList && formData.append("page[hide_urls_list]", hideUrlList.value)
    }

    if(e.target == hideMentionsEl && !e.target.checked){
      hideMentionsAdv && (hideMentionsAdv.checked = false)
      formData.append("page[hide_mentions_list]", "")
      formData.append("page[hide_mentions_advanced]", false)
    }

    if(hide_mentions){
      hideMentionsList && formData.append("page[hide_mentions_list]", hideMentionsList.value)
    }

    dispatch(updatePage(page.details.id, formData))
  }

  const startImport = (e) => {
    e.preventDefault()

    axios({
      method: 'post',
      url: `/main/start_import`,
      data: {page_id: page.details.id},
      headers: { 'Content-Type': 'application/json',
        'X-CSRF-Token': CSRF}
    }).then(response => {
      // successNotify('Import has been successfully started')

    }).catch(errors => {
          errorNotify(errors.response.data.error)
        }
    );
  }


  useEffect(()=>{
    emojiSel && setEmojiList(emojiList.concat(emojiSel))
  },[emojiSel])

  useEffect(()=>{
    hide_all_emojis && setEmojiList([])
  },[hide_all_emojis])

  const handleEmojiSelect = (emoji) => {
    setEmojiSel(emoji.native)
  }

  const clickOutsideEmoji = (e) => {
    if (!e.target.classList.contains("emoji-icon") && e.target.localName !== "path" && e.target.localName !== "em-emoji-picker") {
      dispatch(setShowEmojiModal(false))
    }
  }

  const enableAdvancedOption = (e, option_name) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append(`page[${option_name}_advanced]`, true)
    dispatch(updatePage(page.details.id, formData))
  }

  return (
      <>
        <CopySettings setEmojiList={setEmojiList}/>

        <div className="settings-form my-4">
          <form onSubmit={(e) => e.preventDefault()}>

            {usr_hide_offensive && <SwitchItem title="Hide offensive language"
                            tooltip_place="bottom"
                            value="true"
                            checked={hide_offensive}
                            name="page[hide_offensive]"
                            disabled={private_mode}
                            changeHandler={changeHandler}>
            </SwitchItem>}
            <SwitchItem title="Hide URL's"
                            value="true"
                            checked={hide_urls}
                            name="page[hide_urls]"
                            disabled={private_mode}
                            changeHandler={changeHandler}>
              {hide_urls && (hide_urls_advanced ? <>
                        <div className="ml-4 mt-3 collapse show">
                        </div>
                        <div className="row languages-select">
                          <div className="col-12">
                            <TagInput name="page[hide_urls_list]" tags={hide_urls_list} inputId="hide_urls_list" placeholder="Type here (press enter after each url)"/>
                            <div className="sent-text-extra">
                              By default all URL’s will be hidden, you can add domains or URL’s you’d like to exclude from this filter.
                            </div>
                            <div className="d-flex justify-content-end mb-4">
                              <Button name="button" className="btn btn-primary save-blacklist" clickHandler={changeHandler}>Save</Button>
                            </div>
                          </div>

                        </div>
                      </> :
                      <div className="row languages-select my-2">
                        <a href="#" className="fs-08" onClick={e => enableAdvancedOption(e, "hide_urls")}>Advanced settings (by default we hide all URL's)</a>
                      </div>
              )}
            </SwitchItem>
            {usr_detect_email_phone && <SwitchItem title="Hide emails and phone numbers"
                                                   value="true"
                                                   checked={detect_email_phone}
                                                   name="page[detect_email_phone]"
                                                   disabled={private_mode}
                                                   changeHandler={changeHandler}/>}

            <SwitchItem title="Hide mentions"
                         value="true"
                         checked={hide_mentions}
                         name="page[hide_mentions]"
                         disabled={private_mode}
                         changeHandler={changeHandler}>
              {hide_mentions && (hide_mentions_advanced ? <>
                        <div className="ml-4 mt-3 collapse show">
                        </div>
                        <div className="row languages-select">
                          <div className="col-12">
                            <TagInput name="page[hide_mentions_list]" tags={hide_mentions_list} inputId="hide_mentions_list" placeholder="Type here (press enter after each name of account handle)"/>
                            <div className="sent-text-extra">
                              By default all mentions will be hidden, you can add names or account handles you’d like to exclude from this filter.
                            </div>
                            <div className="d-flex justify-content-end mb-4">
                              <Button name="button" className="btn btn-primary save-blacklist" clickHandler={changeHandler}>Save</Button>
                            </div>
                          </div>

                        </div>
                      </> :
                      <div className="row languages-select my-2">
                        <a href="#" className="fs-08" onClick={e => enableAdvancedOption(e, "hide_mentions")}>Advanced settings (by default we hide all mentions)</a>
                      </div>
              )}
            </SwitchItem>

            {usr_hide_images && <SwitchItem title="Hide images"
                            value="true"
                            checked={hide_images}
                            name="page[hide_images]"
                            disabled={private_mode}
                            changeHandler={changeHandler}/>}
            {usr_hide_emojis && <SwitchItem title="Hide emojis"
                            value="true"
                            checked={hide_emojis}
                            name="page[hide_emojis]"
                            disabled={private_mode}
                            changeHandler={changeHandler}>
              {hide_emojis && (<><div className="ml-4 mt-3 collapse show">

              </div>
                <div className="row languages-select">
                  <div className="col-lg-3">
                    <select className="form-select" value={hide_all_emojis.toString()} id="page[hide_all_emojis]" onChange={changeHandler}>
                      <option value="true">Hide all emojis</option>
                      <option value="false">Hide specific emojis</option>
                    </select>
                  </div>
                </div>
              {!hide_all_emojis && <div className="row languages-select mt-3">
                  <div className="col-12">
                    <div className="emoji-icon-wrap">
                      <FontAwesomeIcon icon={faFaceSmile}
                                       className="mx-2 emoji-icon"
                                       size="lg"
                                       onClick={()=>{dispatch(setShowEmojiModal(true, "emoji_list"))}}/>
                    </div>

                    <TagInput name="page[emoji_list]" tags={emojiList} inputId="emoji_list" placeholder="Enter your emojis here"/>
                    <div className="d-flex justify-content-end mt-3 mb-4">
                      <Button name="button" className="btn btn-primary save-blacklist" clickHandler={changeHandler}>Save</Button>
                    </div>
                  </div>
                </div>}
              </>)}
            </SwitchItem>}
            {usr_blacklist && <SwitchItem title="Hide keywords"
                            value="true"
                            checked={blacklist}
                            name="page[blacklist]"
                            disabled={private_mode}
                            changeHandler={changeHandler}>
              { blacklist &&
                (<>
                    <div className="ml-4 mt-3 collapse show">
                    </div>
                    <div className="row languages-select">
                      <div className="col-12">
                        <TagInput name="page[tags]" tags={tags} copy={true}/>
                        <div className="d-flex justify-content-end mt-3 mb-4">
                          <Button name="button" className="btn btn-primary save-blacklist" clickHandler={changeHandler}>Save</Button>
                        </div>
                      </div>
                    </div>
                </>)

              }
            </SwitchItem>}

            {usr_private_mode && <>
                <div className="line mt-4"></div>

                <SwitchItem title="Hide all comments"
                                value="true"
                                checked={private_mode}
                                name="page[private_mode]"
                                changeHandler={changeHandler}>
                  {private_mode && (private_mode_advanced ? <>
                            <div className="ml-4 mt-3 collapse show">
                            </div>
                            <div className="row languages-select">
                              <div className="col-12">
                                <TagInput name="page[whitelist]" tags={whitelist} inputId="page[whitelist]" placeholder="Type here (press enter after each word)" copy={true}/>
                                <div className="sent-text-extra">
                                  By default all comments will be hidden, you can add words you’d like to exclude from this filter.
                                </div>
                                <div className="d-flex justify-content-end mb-4">
                                  <Button name="button" className="btn btn-primary save-blacklist" clickHandler={changeHandler}>Save</Button>
                                </div>
                              </div>

                            </div>
                          </> :
                          <div className="row languages-select my-2">
                            <a href="#" className="fs-08" onClick={e => enableAdvancedOption(e, "private_mode")}>Advanced settings (by default we hide all comments)</a>
                          </div>
                  )}
                </SwitchItem>
              </>}

            {usr_disable_organic && <>
              <div className="line mt-4"></div>

              <SwitchItem title="Disable organic moderation"
                          desc="By default comments on both ads and organic content are moderated."
                          value="true"
                          checked={disable_organic}
                          name="page[disable_organic]"
                          changeHandler={changeHandler}/>
            </>}

            <div className="line mt-4"></div>
          </form>

          {ability.can("test_comment","PagesController") && <>
            <div className="my-4">
              Here you can test comments to see if they would trigger your moderation settings.
            </div>
            <TestTab />
          </>}

          {props.admin && (hostname == "dev.commentguard.io" || hostname == "localhost") && (<>
            <div className="mt-4"></div>

            <div className="settings-item" >
              <div className="switch-desc">
                <div className="switch-desc-heading">
                  Import
                </div>
                <div className="d-flex justify-content-between">
                  Import the last 7 days of comments and process these according to your CommentGuard moderation settings.
                  <Button name="button" className="btn btn-primary import-btn" clickHandler={startImport}>Import and process comments</Button>
                </div>
              </div>
            </div>
          </>)}

          <EmojiContainer handleEmojiSelect={handleEmojiSelect} clickOutside={clickOutsideEmoji}/>

        </div>
      </>
  )
}

export default React.memo(Moderation)