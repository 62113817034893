import {COMMENTS_ACTIONS} from "../constants";

const initState = {
    list: {
        current_page: 0,
        next_page: 0,
        per_page: 0,
        total_count: 0,
        total_pages: 0
    }
}


export function linksReducer(state=initState, action) {
    const {payload} = action

    switch (action.type) {
        case COMMENTS_ACTIONS.LOAD_LINKS:
            return {
                ...state,
                list: payload.links
            }
        case COMMENTS_ACTIONS.CHANGE_LINKS:
            return {
                ...state,
                list: {...state.list, ...payload.links}
            }
        default:
            return state
    }
}