import React, {useEffect, useRef, useState} from 'react'
import {userEdit} from "../../../../actions/user";
import {useDispatch} from "react-redux";
import "./Profile.scss"

const AccountSettingsProfile = ({profile}) => {
    const dispatch = useDispatch()
    const {firstname, lastname, email} = profile.attributes
    const userForm = useRef()

    const handleUserEdit = (e) => {
        e.preventDefault();
        const formData = new FormData(userForm.current);
        dispatch(userEdit(formData))
    }

    return (
        <>
            <div className="my-4">
                Manage your user information and login credentials.
            </div>

            <form onSubmit={handleUserEdit} ref={userForm}>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="form-group mb-2">
                            <label>First name</label>
                            <input type="text" name="user[firstname]" defaultValue={firstname} className="form-control"/>
                        </div>
                        <div className="form-group mb-2">
                            <label>Last name</label>
                            <input type="text" name="user[lastname]" defaultValue={lastname} className="form-control"/>
                        </div>
                        <div className="form-group mb-2">
                            <label>
                                Email
                                <small className="mx-1">(login username)</small>
                            </label>
                            <input type="email" name="user[email]" defaultValue={email} className="form-control" required/>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label>Create new password</label>
                            <input type="password" name="user[password]" autoComplete="new-password" className="form-control"/>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label>Repeat new password</label>
                            <input type="password" name="user[password_confirmation]" autoComplete="false" className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <button type="submit" className="btn btn-primary float-end">Save</button>
                        <button type="reset" className="btn btn-secondary float-end mx-2">Cancel</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default React.memo(AccountSettingsProfile)