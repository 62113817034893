import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setShowEmojiModal, setShowSavedReplies} from "../../../actions/ui";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFaceSmile, faPaste} from "@fortawesome/free-regular-svg-icons";
import TagInput from "../TagInput";
import ActionBlock from "./ActionBlock";
import SwitchItem from "../SwitchItem";

const TriggersBlock = ({rule, isValidForm, msgRef, privateRef}) => {
    const dispatch = useDispatch()
    const {details} = useSelector(state => state.pages.facebook.activePage)
    const [trigger, setTrigger] = useState((rule && rule.attributes.trigger) || "keywords")
    const [aiTrigger, setAiTrigger] = useState((rule && rule.attributes.ai_trigger) || "needs_reply")
    const [ai, setAi] = useState(rule?.attributes?.ai)

    const handleTrigger = (e) => {
        setTrigger(e.currentTarget.value)
        isValidForm(e)
    }

    const handleAiTrigger = (e) => {
        setAiTrigger(e.currentTarget.value)
    }

    const handleAi = (e) => {
        setAi(e.currentTarget.checked || false)
        if(!e.currentTarget.checked){
            setTrigger("keywords")
        }
    }

    return (
        <>
            <div className="form-group mb-2">
                <SwitchItem title="Generate AI replies"
                            wrapperClass="settings-item my-2"
                            value="true"
                            checked={ai}
                            changeHandler={handleAi}
                            name="rule[ai]">
                </SwitchItem>
                {ai && <div className="form-group mb-2">
                    <select className="form-select" name="rule[ai_trigger]" defaultValue={aiTrigger}
                            onChange={handleAiTrigger} required="required">
                        <option value="needs_reply">Automated AI replies to comments that need a reply</option>
                        <option value="visible">Automated AI replies to all visible comments</option>
                    </select>
                </div> }
            </div>

            <div className="form-group mb-2">
                <label>Trigger</label>
                <select className="form-select" name="rule[trigger]" onChange={handleTrigger}
                        defaultValue={trigger} required="required">
                    <option value="keywords">Comment contains certain keywords</option>
                    {ai ? <option value="no_trigger">No specific keyword trigger</option>
                        :<><option value="only_emoji">Comment contains just an emoji</option>
                        <option value="only_mention">Comment contains just a mention</option>
                    </>}
                </select>
            </div>

            {!trigger.includes("only_") && !trigger.includes("no_trigger") &&
                <div className="form-group mb-2">
                    <label>Keywords</label>
                    <TagInput name="rule[keywords]" copy={true} tags={(rule && rule.attributes.keywords) || []}
                              onChange={isValidForm}/>
                </div>
            }

            <ActionBlock rule={rule} msgRef={[msgRef[0], msgRef[1], msgRef[2]]} ai={ai} privateRef={privateRef} isValidForm={isValidForm}/>
        </>
    )
}

export default React.memo(TriggersBlock)