import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook} from "@fortawesome/free-brands-svg-icons";

import cl from './FacebookLoginButton.module.scss';

const FacebookLoginButton = (props) => {
    const btn_class_name = props.logout ? cl.fbBtnLogout : cl.fbBtnLogin

    return (

            <a href={props.href} data-method="post" className={`nav-link ${[cl.fbBtn, btn_class_name].join(" ")}`}>
                <i className="fab fa-facebook"></i>
                <FontAwesomeIcon icon={faFacebook} color="white"/>
                <span className={cl.fbText}>{props.text}</span>
            </a>
    )
}

export default React.memo(FacebookLoginButton)