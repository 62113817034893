import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    requestPageComments
} from "../../../actions/requests";

import {faMagnifyingGlass, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setShowSearch} from "../../../actions/ui";
import {changeFilter} from "../../../actions/pages";

const Search = (props) => {
    const dispatch = useDispatch()
    const {showSearch} = useSelector(state => state.ui)
    const {list: filters} = useSelector(state => state.filters)
    const {search} = filters

    const comments_search = (e) => {
        const value = e.target.value
        dispatch(changeFilter("search", value))
        dispatch(requestPageComments())
    }

    const closeSearch = (e) => {
        dispatch(changeFilter("search", ""))
        dispatch(requestPageComments())
        dispatch(setShowSearch())
    }

    return (
        <>
            {showSearch &&
            <div className="input-group c-search mb-3">
                  <span className="input-group-text c-search-icon" id="basic-addon1"><FontAwesomeIcon
                      icon={faMagnifyingGlass} className="fa-fw"/></span>
                <input type="text" className="form-control c-search-inp" id="search-inp" placeholder="Search" aria-label="Search"
                       aria-describedby="basic-addon1" onKeyUp={e=>comments_search(e)} defaultValue={search}/>
                <span className="input-group-text c-search-icon c-search-icon-close" onClick={closeSearch}><FontAwesomeIcon
                    icon={faXmark} className="fa-fw"/></span>
            </div>
            }


        </>
    )
}

export default React.memo(Search)