import {COMMENTS_ACTIONS} from "../constants";

const initState = {
    list: {
        status: "",
        comments_type: "",
        label: "",
        time: "",
        startDate: "",
        endDate: "",
        unreviewed: true,
        mark_as_deleted: false,
        by_page_id: "",
        order_by_time: "desc",
        replied: "",
        intent: "",
        search: ""
    }
}


export function filtersReducer(state=initState, action) {
    const {payload} = action

    switch (action.type) {
        case COMMENTS_ACTIONS.CHANGE_FILTER:
            return {
                ...state,
                list: payload.name ? {
                    ...state.list,
                    [payload.name]: payload.value
                } : {...initState.list, unreviewed: state.list.unreviewed, mark_as_deleted: state.list.mark_as_deleted}
            }
        default:
            return state
    }
}