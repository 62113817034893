
import {USER_ACTIONS} from "../constants";

const initState = {
    profile:{
        error: null,
        isFetching: false,
        attributes: {}
    },
    pages: [],
    account_level: {
        attributes: {
            name: "Free Trial",
            days_limit: 14,
            expire_date: "Mar 22, 2022",
            comments_limit: "250",
            fb_pages_limit: "1"
        }
    },
    saved_replies: [],
    notifications: [],
    loaded: false
}


export function userReducer(state=initState, action) {
    const {payload} = action

    switch (action.type) {
        case USER_ACTIONS.REQUEST_USER_DETAILS_STARTED:
            return {
                ...state
            }
        case USER_ACTIONS.REQUEST_USER_DETAILS_SUCCESS:
            return {
                ...state,
                profile: {
                    error: null,
                    isFetching: false,
                    attributes: payload.data.attributes
                },
                pages: payload.data.pages || [],
                account_level: payload.data.account_level || {},
                loaded: true
            }
        case USER_ACTIONS.ADD_FACEBOOK_PAGES_TO_USER:
            return{
                ...state,
                pages: [...state.pages, payload.pages]

            }
        case USER_ACTIONS.REMOVE_FACEBOOK_PAGES_FROM_USER:
            return{
                ...state,
                pages: state.pages.filter(page => page.id !== payload.pageId)
            }
        case USER_ACTIONS.INC_PAGES_COUNT:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    attributes: {
                        ...state.profile.attributes,
                        pages_count: state.profile.attributes.pages_count + 1
                    }
                }
            }
        case USER_ACTIONS.DEC_PAGES_COUNT:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    attributes: {
                        ...state.profile.attributes,
                        pages_count: state.profile.attributes.pages_count - 1
                    }
                }
            }
        case USER_ACTIONS.CHANGE_PAGE:
            return {
                ...state,
                pages: state.pages.map(page => {
                    if (page.id === payload.pageId) {
                        return {
                            ...page,
                            attributes: Object.assign(page.attributes, payload.attributes)
                        }
                    } else {
                        return {
                            ...page
                        }
                    }
                })
            }
        case USER_ACTIONS.UPDATE_USER_SUB_USERS:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    attributes: {
                     ...state.profile.attributes,

                     sub_users_count: payload.subUsers.length,
                     sub_users: payload.subUsers
                    }
                }
            }
        case USER_ACTIONS.REQUEST_SAVED_REPLIES:
            return {
                ...state,
                saved_replies: payload.data
            }
        case USER_ACTIONS.ADD_SAVED_REPLIES:
            return {
                ...state,
                saved_replies: [payload.data, ...state.saved_replies]
            }
        case USER_ACTIONS.UPDATE_SAVED_REPLIES:
            return {
                ...state,
                saved_replies: state.saved_replies.map(saved_reply => {
                    if (saved_reply.id === payload.savedReplyId) {
                        return {
                            ...saved_reply,
                            attributes: Object.assign(saved_reply.attributes, payload.attributes)
                        }
                    } else {
                        return {
                            ...saved_reply
                        }
                    }
                })
            }
        case USER_ACTIONS.REMOVE_SAVED_REPLIES:
            return {
                ...state,
                saved_replies: state.saved_replies.filter(saved_reply => saved_reply.id !== payload.savedReplyId)
            }
        case USER_ACTIONS.REQUEST_USER_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload.data
            }
        case USER_ACTIONS.ADD_USER_NOTIFICATIONS:
            return {
                ...state,
                notifications: [...state.notifications, payload.data]
            }
        case USER_ACTIONS.UPDATE_USER_NOTIFICATIONS:
            return {
                ...state,
                notifications: state.notifications.map(notification => {
                    if (notification.id === payload.notificationId) {
                        return {
                            ...notification,
                            attributes: Object.assign(notification.attributes, payload.attributes)
                        }
                    } else {
                        return {
                            ...notification
                        }
                    }
                })
            }
        case USER_ACTIONS.REMOVE_USER_NOTIFICATIONS:
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.id !== payload.notificationId)
            }

        case USER_ACTIONS.UPDATE_PROFILE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                            attributes: Object.assign(state.profile.attributes, payload.attributes)
                }
            }
        default:
            return state
    }
}