import React from 'react'
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getIcon} from "../../../constants";

const PagesSelectionItem = (props) => {

    return (
        <li>
            <button className="dropdown-item"
                    onClick={props.handleInputChange}
                    disabled={props.disabled}
                    value={props.pageId}
                    data-type={props.pageType}>

                <FontAwesomeIcon icon={getIcon(props.pageType)} className="fa-fw"/>
                <span className="ms-2">{props.pageName}</span>
            </button>
        </li>
    )
}


export default React.memo(PagesSelectionItem)