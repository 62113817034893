import {CSRF, USER_ACTIONS} from "../constants";
import axios from "axios";
import {errorNotify, successNotify} from "../utils/app";
import {banUser, changePage} from "./pages";
import {requestUserDetails} from "./requests";


export const requestUserDetailsStart = () => ({ type: USER_ACTIONS.REQUEST_USER_DETAILS_STARTED })

export const requestUserDetailsSuccess = (data) => {
    return {
        type: USER_ACTIONS.REQUEST_USER_DETAILS_SUCCESS,
        payload: { data }
    }
}

export const requestUserDetailsFailed = (error) => {
    return {
        type: USER_ACTIONS.REQUEST_USER_DETAILS_FAILED,
        payload: error
    }
}

export const addFacebookPagesToUser = (pages) => {
    return {
        type: USER_ACTIONS.ADD_FACEBOOK_PAGES_TO_USER,
        payload: { pages }
    }
}

export const removeFacebookPageFromUser = (pageId) => {
    return {
        type: USER_ACTIONS.REMOVE_FACEBOOK_PAGES_FROM_USER,
        payload: { pageId }
    }
}

export const updateUserSubUsers = (subUsers) => {
    return {
        type: USER_ACTIONS.UPDATE_USER_SUB_USERS,
        payload: {subUsers}
    }
}

export const userEdit = (data) => {
    return dispatch => {
        try {
            axios.patch(`/user_update`, data, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    successNotify(json.data.message)
                    dispatch(requestUserDetails())
                }).catch(error => {
                    errorNotify(error.response.data.error)
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export const changeProfile = (attributes) => {
    return {
        type: USER_ACTIONS.UPDATE_PROFILE,
        payload: { attributes }
    }
}

export const requestSavedRepliesSuccess = (data) => {
    return {
        type: USER_ACTIONS.REQUEST_SAVED_REPLIES,
        payload: { data }
    }
}

export const addSavedReply = (data) => {
    return {
        type: USER_ACTIONS.ADD_SAVED_REPLIES,
        payload: { data }
    }
}

export const editSavedReply  = (savedReplyId, attributes) => {
    return {
        type: USER_ACTIONS.UPDATE_SAVED_REPLIES,
        payload: { savedReplyId, attributes }
    }
}

export const removeSavedReply  = (savedReplyId) => {
    return {
        type: USER_ACTIONS.REMOVE_SAVED_REPLIES,
        payload: { savedReplyId }
    }
}

export const editUserPages  = (pageId, attributes) => {
    return {
        type: USER_ACTIONS.CHANGE_PAGE,
        payload: { pageId, attributes }
    }
}

export const incUserPagesCount = () => ({ type: USER_ACTIONS.INC_PAGES_COUNT })

export const decUserPagesCount = () => ({ type: USER_ACTIONS.DEC_PAGES_COUNT })

export const requestUserNotifications = () => {
    return dispatch => {
        try {
            axios.get(`/users/notifications`)
                .then(json => {
                    dispatch(requestUserNotificationsSuccess(json.data.data))
                }).catch(error => errorNotify(error.response.data.error))
        } catch(e) {
            console.error("Error of requesting users notifications")
        }
    }
}

export const createUserNotifications = (data) => {
    return dispatch => {
        try {
            axios.post(`/users/notifications`, data, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    // successNotify('Notification setting have been successfully enabled')
                    dispatch(addUserNotification(json.data.data))
                }).catch(error => errorNotify(error.response.data.error))
        } catch (e) {
            console.error("")
        }
    }
}

export const updateUserNotifications = (notificationId, data) => {
    return dispatch => {
        try {
            axios.put(`/users/notifications/${notificationId}`, data, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    // successNotify('Notification setting have been successfully updated')
                    dispatch(editUserNotification(notificationId, json.data.data.attributes))
                }).catch(error => errorNotify(error.response.data.error))
        } catch (e) {
            console.error("")
        }
    }
}

export const deleteUserNotifications = (notificationId) => {
    return dispatch => {
        try {
            axios.delete(`/users/notifications/${notificationId}`, {headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': CSRF}})
                .then(json => {
                    // successNotify('Notification setting have been successfully disabled')
                    dispatch(removeUserNotification(notificationId))
                }).catch(error => errorNotify(error.response.data.error))
        } catch (e) {
            console.error("")
        }
    }
}

export const requestUserNotificationsSuccess = (data) => {
    return {
        type: USER_ACTIONS.REQUEST_USER_NOTIFICATIONS,
        payload: { data }
    }
}

export const addUserNotification = (data) => {
    return {
        type: USER_ACTIONS.ADD_USER_NOTIFICATIONS,
        payload: { data }
    }
}

export const editUserNotification = (notificationId, attributes) => {
    return {
        type: USER_ACTIONS.UPDATE_USER_NOTIFICATIONS,
        payload: { notificationId, attributes }
    }
}

export const removeUserNotification = (notificationId) => {
    return {
        type: USER_ACTIONS.REMOVE_USER_NOTIFICATIONS,
        payload: { notificationId }
    }
}