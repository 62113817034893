import React from 'react'

const Button = (props) => {
  return (
    <button type={props.type}
            className={props.className}
            onClick={props.clickHandler}
            disabled={props.disabled}
            name={props.name}
            value={props.value}
            data-tip={props.dataTip}>
      {props.children}
    </button>
  )
}

export default React.memo(Button)