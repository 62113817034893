import React, {useCallback, useEffect} from "react"
import {useLocation} from "react-router-dom"
import { useDispatch, useSelector, batch } from "react-redux"
import {requestPageDetails, requestUserDetails} from "../../../actions/requests";
import "./PageMenu.scss"
import {initNotificationChannel} from "../../../channels/notifications_channel";
import PagesSelection from "../PagesSelection";
import {changeFilter} from "../../../actions/pages";
import PageFilter from "../PageFilter";

const PageMenu = () => {
    const dispatch = useDispatch()
    const {hash: locationHash} = useLocation()

    const page = useSelector(state => state.pages.facebook.activePage)
    const {displayName} = page.details.attributes

    const {list: filters} = useSelector(state => state.filters)
    const {by_page_id: page_id} = filters

    useEffect(() => {
        batch(() => {
            const [pageId, path] = locationHash.split("/")
            dispatch(requestUserDetails(pageId.replace('#','')))
            dispatch(initNotificationChannel())
        })
    }, [])

    useEffect(() => {
        dispatch(requestPageDetails(page_id))
    }, [page_id])

    const handleInputChange = useCallback((e) => {
        const val = e.currentTarget.value
        if(val === "New"){
            window.location.href = "/account-settings#pages"
        }else{
            dispatch(changeFilter("by_page_id", val==="Dashboard" ? "" : e.currentTarget.value))
        }
    },[])

    return (
        <div className="d-flex justify-content-between page-menu">
            <div className="selected-page-name">
                <h4>{displayName || "Dashboard"}</h4>
            </div>
            <div className="col-md-4 page-selector">
                <PageFilter handleInputChange={handleInputChange} pageName={displayName} pageType={page.details.type}/>
            </div>
        </div>
    )
}

export default React.memo(PageMenu)
