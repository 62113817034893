import React, {forwardRef} from 'react'
import {useSelector} from "react-redux";

const Checkbox = forwardRef(({commentId, selected, commentsForm}, countRef) => {
    const {selectType} = useSelector(state => state.ui)

    const selectComment = () => {
        if(selectType === "Select visible") {
            const checkboxes = commentsForm.current.querySelectorAll('.form-check-input:checked')
            for (let el of countRef.current.getElementsByClassName("counter")) {
                el.innerText = checkboxes.length
            }
            if (checkboxes.length > 1) {
                countRef.current.style.display = "inline-flex"
            } else {
                countRef.current.style.display = "none"
            }
        }
    }
    return (
        <input className="form-check-input" type="checkbox" name="comment_ids[]" value={commentId} onChange={selectComment}/>
    )
})

export default React.memo(Checkbox)