import React, {useCallback, useEffect, useRef, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFaceSmile, faPaste} from "@fortawesome/free-regular-svg-icons";
import {setShowEmojiModal} from "../../../actions/ui";
import {useDispatch, useSelector} from "react-redux";
import Button from "../Button";
import "./../ReplyForm/ReplyForm.scss"
import EmojiContainer from "../EmojiContainer";
import {addNewTmpComment, updateComment} from "../../../actions/pages";
import {errorNotify} from "../../../utils/app";
import TagInput from "../TagInput";
import {CSRF} from "../../../constants";
import AiAction from "./../ReplyForm/AiAction";
import Translate from "../CommentItem/Actions/Translate";
import {sendMessage} from "../../../actions/direct_messages";
import SavedRepliesModal from "../SavedRepliesModal";

const MessagesReply = ({selectedMessage, pageId}) => {
    const dispatch = useDispatch()
    const privateRef = useRef()
    const msgRef = useRef()
    const {sender_id, sender_name, message, uuid} = selectedMessage.attributes
    const [showSavedReplies, setShowSavedReplies] = useState(false)

    useEffect(()=>{
        msgRef.current.DOM.input.focus()
    },[])

    const beforePasteHook = (e, t) => {
        const {tagify, pastedText, clipboardData} = t
        document.execCommand("InsertText", false, pastedText);
        return Promise.reject()
    }

    const tagSettings = {
        mode: "mix",
        pattern: /^@| @/,
        tagTextProp: 'text',
        enforceWhitelist: true,
        skipInvalid: true,
        hooks: { beforePaste: beforePasteHook},
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const value = msgRef.current.state.lastOriginalValueReported
        if(value) {
            const formData = new FormData()
            formData.append("direct_message[message]", value)
            dispatch(sendMessage(selectedMessage.id, formData))
            console.log(msgRef.current)
            msgRef.current.DOM.originalInput.value = ""
        }else{
            errorNotify("Please fill in the reply field.")
        }
    }

    const setMessage = (message) => {
        msgRef.current.DOM.input.focus()
        const range = window.getSelection().getRangeAt(0)
        const old_range = msgRef.current.state.selection.range || range
        range.setStart(old_range.startContainer, old_range.startOffset)
        range.setEnd(old_range.endContainer, old_range.endOffset)
        range.collapse(old_range.collapsed)
        document.execCommand("InsertText", false, message);
    }

    const cursor_position = (e) => {
        const sel = document.getSelection();
        sel.modify("extend", "backward", "paragraphboundary");
        const pos = sel.toString().length;
        if(sel.anchorNode != undefined) sel.collapseToEnd();
        return pos;
    }

    const handleEmojiSelect = (emoji) => {
        setMessage(emoji.native)
        cursor_position()
    }

    return (
        <>
            <div className="reply-collapse">
                <div className="reply-block mb-3">
                    <div className="w-100" >
                        <div className="text-group w-100 mb-2">
                            <TagInput placeholder="Type your comment here…" name="comment[message]"
                                      settings={tagSettings}
                                      tagifyRef={msgRef}/>
                        </div>

                        <div className="d-flex justify-content-between mx-0 reply-bottom">
                            <div className="d-flex reply-p p-0">

                            </div>

                            <div className="d-flex reply-b p-0 icon-buttons position-relative">

                                <FontAwesomeIcon icon={faPaste}
                                                 className="saved-replies-icon mx-2"
                                                 size="lg"
                                                 onClick={()=>{setShowSavedReplies(true)}}/>

                                <Translate pageId={pageId} objectId={selectedMessage.id} isMobile={true} dm={true}
                                           className="btn btn-tr saved-replies-icon p-0 mx-2"
                                           inputRef={msgRef}/>

                                <FontAwesomeIcon icon={faFaceSmile}
                                                 className="emoji-icon mx-2"
                                                 size="lg"
                                                 onClick={()=>{dispatch(setShowEmojiModal(null, "comment_message"))}}/>

                                {!showSavedReplies && <EmojiContainer className="emoji-wrapper" handleEmojiSelect={handleEmojiSelect}/>}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex">
                        <AiAction objectId={selectedMessage.id} pageId={pageId} msgRef={msgRef} setMessage={setMessage} dm={true}/>
                    </div>
                    <div className="d-flex reply-p p-0">
                        <Button type="submit" className="btn btn-primary" clickHandler={handleSubmit}>Reply</Button>
                    </div>
                </div>
            </div>

            {showSavedReplies && <SavedRepliesModal setShowSavedReplies={setShowSavedReplies} pageId={pageId}
                                                    setMessage={setMessage}/>}
        </>
    )
}

export default React.memo(MessagesReply)