import {UI_ACTIONS} from "../constants"

const initState = {
  showAddNewPageModal: false,
  showFeedbackModal: false,
  showModeration: false,
  showReplyForm: false,
  selectedComment: null,
  showEmojiModal: false,
  showPreviewPanel: false,
  emojiInputId: "",
  showSavedRepliesModal: false,
  showSearch: false,
  showBrandsModal: false,
  replySent: [],
  selectType: "Select visible",
  selectedCommentsCount: 0,
  previewData: {}
}

export function uiReducer(state=initState, action) {
  const { payload } = action

  switch(action.type) {
    case UI_ACTIONS.TOGGLE_SHOW_ADD_NEW_PAGE_MODAL_VISIBILITY:
      return { ...state, showAddNewPageModal: !state.showAddNewPageModal }
    case UI_ACTIONS.TOGGLE_SHOW_FEEDBACK_MODAL_VISIBILITY:
      return { ...state, showFeedbackModal: !state.showFeedbackModal }
    case UI_ACTIONS.SHOW_MODERATION:
      return { ...state, showModeration: !state.showModeration }
    case UI_ACTIONS.SHOW_PREVIEW_PANEL:
      return { ...state, showPreviewPanel: payload.showPreviewPanel }
    case UI_ACTIONS.SET_PREVIEW_DATA:
      return { ...state, previewData: payload.previewData}
    case UI_ACTIONS.SHOW_EMOJI_MODAL:
      return { ...state,
        showEmojiModal: payload.val===null ? !state.showEmojiModal : payload.val,
        emojiInputId: payload.inputId}
    case UI_ACTIONS.SHOW_SAVED_REPLIES:
      return { ...state, showSavedRepliesModal: !state.showSavedRepliesModal }
    case UI_ACTIONS.SHOW_BRANDS_MODAL:
      return { ...state,
        showBrandsModal: !state.showBrandsModal}
    case UI_ACTIONS.REPLY_SENT_ADD:
      return { ...state, replySent: [payload.commentId, ...state.replySent]}
    case UI_ACTIONS.REPLY_SENT_DELETE:
      return {
        ...state,
        replySent: state.replySent.filter(id => id !== payload.commentId)
      }
    case UI_ACTIONS.SHOW_SEARCH:
      return { ...state, showSearch: !state.showSearch }
    case UI_ACTIONS.SET_SELECT_TYPE:
      return { ...state, selectType: payload.type }
    case UI_ACTIONS.SELECTED_COMMENTS_COUNT:
      return { ...state, selectedCommentsCount: payload.count }
    default:
      return state
  }
}
