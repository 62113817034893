import React, {useCallback, useEffect, useRef, useState} from 'react'
import CommentItem from "../CommentItem";
import {useDispatch, useSelector} from "react-redux";
import {
    requestPageComments
} from "../../../actions/requests";
import Pagination from "../Pagination/Pagination";
import './CommentsList.scss'

import MainCheckbox from "./MainCheckbox";
import BulkActions from "./BulkActions";
import Filters from "../Filters";
import {filtersIsChanged} from "../../../utils/app";
import Search from "./Search";
import Preview from "../Preview";

const CommentsList = (props) => {
    const commentsForm = useRef()
    const countRef = useRef()
    const dispatch = useDispatch()
    const {commentsList: comments} = useSelector(state => state.comments)
    const {list: links} = useSelector(state => state.links)
    const {list: filters} = useSelector(state => state.filters)
    const {by_page_id: page_id, unreviewed, mark_as_deleted} = filters

    useEffect(()=>{
        dispatch(requestPageComments())
    },[page_id])

  return (
      <div className="fixed-width">
          <div className={comments.length > 0 ? '' : 'my-4'}>
              {(comments.length > 0 || filtersIsChanged(filters)) &&
              <>
                  <div className="d-flex align-items-end">
                      <div className="d-flex main-check-wr">
                          {comments.length > 0 &&
                            <div className="form-check d-flex align-items-end mb-0 mt-4">
                                {!mark_as_deleted && <MainCheckbox commentsForm={commentsForm} ref={countRef}/>}
                            </div>}
                      </div>

                  <div className="d-flex justify-content-between align-items-end w-100">
                      <Filters />

                      {comments.length > 0 ?
                          <div className="d-flex">
                              <BulkActions commentsForm={commentsForm} ref={countRef} unreviewed={unreviewed}/>
                          </div> : <div className="col-4"></div>}

                  </div>
              </div>

                  <div className="line mt-2"/>
                  <Search />

                  {comments.length > 0 &&
                      <div className="mb-4 fixed-table">
                          <form ref={commentsForm} onSubmit={(e) => e.preventDefault()}>
                              {comments.map((comment, index)=>{
                                  return <CommentItem key={comment.id} index={index} commentsForm={commentsForm} ref={countRef}/>
                              })}
                          </form>
                          { links && links.total_pages > 1 && (
                              <Pagination
                                  details={links}
                                  handlePageChange={(page) => dispatch(requestPageComments(page))}
                              />
                          )}

                          <Preview/>
                      </div>
                  }
              </>}
              {comments.length <= 0 && unreviewed && !mark_as_deleted &&
                  <div className="my-4 pt-2">
                      {props.inbox_zero}
                  </div>}
          </div>
      </div>
  )
}

export default React.memo(CommentsList)