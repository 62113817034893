import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import SwitchItem from "../SwitchItem";
import Datetime from "react-datetime";
import timezones from "../../../constants/timezones.json";
import {updateUserNotifications} from "../../../actions/user";

const NotificationItem = ({title, desc, value, changeHandler, form}) => {
    const dispatch = useDispatch()
    const timeRef = useRef()
    const notification = useSelector(state => state.user.notifications.find(notification => notification && notification.attributes.name === value))
    const [time, setTime] = useState()

    const updateHandler = (e) => {
        const formData = new FormData(form.current);
        dispatch(updateUserNotifications(notification.id, formData))
    }

    const handleChange = (date) => {
        setTime(date)
    }

    useEffect(()=>{
        notification && setTime(notification.attributes.time)
    },[notification])

    return (
      <SwitchItem id={notification && notification.id}
                  title={title}
                  desc={desc}
                  value={value}
                  checked={notification ? true : false}
                  name="notification[name]"
                  changeHandler={changeHandler}>

          {notification && <><div className="ml-4 mt-3 collapse show">
          </div>
              <div className="row languages-select mx-1">
                  <div className="col-lg-2 mb-2">
                      <Datetime dateFormat={false}
                                updateOnView="time"
                                onClose={updateHandler}
                                ref={timeRef}
                                onChange={handleChange}
                                value={time}
                                inputProps={{className: "form-select",
                                    id: notification.id,
                                    name: "notification[time]",}}/>
                  </div>

                  <div className="col-lg-5">
                      <select className="form-select"
                              name="notification[timezone]"
                              value={notification.attributes.timezone}
                              onChange={updateHandler}>
                          {timezones.map((timezone,index)=>{
                              return <option key={index} value={`${timezone.offset} ${timezone.abbrv}`}>
                                 (UTC {timezone.offset}:00)  {timezone.name} - {timezone.abbrv}
                              </option>
                          })}
                      </select>
                  </div>
              </div>
          </>}

      </SwitchItem>
    )
}

export default React.memo(NotificationItem)
