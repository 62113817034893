import React from 'react'
import {useSelector} from "react-redux";
import PagesSelectionItem from "../PagesSelectionItem";
import "../PagesSelection/PagesSelection.scss"
import {getIcon} from "../../../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {useAbility} from "@casl/react";
import {AbilityContext} from "../../../utils/app";

const PageFilter = (props) => {
    const pages = useSelector(state => state.user.pages)
    const ability = useAbility(AbilityContext)
    const addDisallowed = ability.can("create", "PagesController")

    return (
        <div className="accounts-list position-relative">
            <button type="button" className="form-select dropdown-toggle dropdown-toggle-n actions-btn" data-bs-toggle="dropdown" aria-expanded="false">
                <FontAwesomeIcon icon={props.pageName ? getIcon(props.pageType) : faBars} className="fa-fw"/>
                <span className="ms-2">{props.pageName || "Dashboard"}</span>
            </button>
            {pages && pages.length <= 1 && !addDisallowed ? <></> :
                <ul className="dropdown-menu w-100 pages-selector">
                    {pages && pages.length > 1 && props.pageName && <PagesSelectionItem pageId="Dashboard" pageType="dashboard" handleInputChange={props.handleInputChange} pageName="Dashboard"/>}
                    {pages && (pages.map((page, index) => {
                        return <div key={page.id}><PagesSelectionItem
                            pageId={page.id}
                            handleInputChange={props.handleInputChange}
                            pageType={page.type}
                            pageName={page.attributes.displayName}/></div>
                    }))}
                    {addDisallowed && <PagesSelectionItem pageId="New" handleInputChange={props.handleInputChange} pageName="Add new page"/>}
                </ul>}

        </div>
    )
}

export default React.memo(PageFilter)