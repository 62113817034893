import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setShowEmojiModal, setShowSavedReplies} from "../../../actions/ui";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFaceSmile, faPaste} from "@fortawesome/free-regular-svg-icons";
import {faPlus, faMinus, faXmark} from "@fortawesome/free-solid-svg-icons";
import TagInput from "../TagInput";
import Button from "../Button";
import AdditionalModals from "./AdditionalModals";
import AiRulesAction from "./AiRulesAction";
import SwitchItem from "../SwitchItem";

const ActionBlock = ({rule, msgRef, isValidForm, privateRef, ai}) => {
    const dispatch = useDispatch()
    const [action, setAction] = useState((rule && rule.attributes.action) || "reply")
    const type = useSelector(state => state.pages.facebook.activePage.details.type)
    const whitelist = [{ value: 'mention', text: 'mention'}]
    const [replyVariants, setReplyVariants] = useState((rule && rule.attributes.reply_variants) || [""])
    const [showSavedReplies, setShowSavedReplies] = useState(false)
    const [curVar, setCurVar] = useState(0)
    const [enableTone, setEnableTone] = useState(rule?.attributes?.tone)

    const changeAction = (e) => {
        setAction(e.target.value)
    }

    const changeTone = (e) => {
        console.log('e.target.value', e.target.checked)
        setEnableTone(e.target.checked)
    }

    const dropdownSettings = {
        enabled: 0,
        position: 'text',
        mapValueTo: 'text',
        highlightFirst: true,
        caseSensitive: true,
        maxItems: 1
    }

    const beforePasteHook = (e, t) => {
        const {tagify, pastedText, clipboardData} = t
        document.execCommand("InsertText", false, pastedText);
        return Promise.reject()
    }

    const tagSettings = {
        mode: "mix",
        pattern: (rule && rule.attributes.private_reply) ? /efgbhe3qwedvbth564y6u654475gbgdnty64y656u/ : /^@| @/,
        tagTextProp: 'text',
        dropdown: dropdownSettings,
        enforceWhitelist: true,
        skipInvalid: true,
        whitelist: whitelist,
        hooks: { beforePaste: beforePasteHook},
    }

    const handlePrivate = (e) => {
        if(e.target.checked){
            msgRef[0].current.removeTags('mention', true)
            msgRef[0].current.settings.pattern = /efgbhe3qwedvbth564y6u654475gbgdnty64y656u/
        }else{
            msgRef[0].current.settings.pattern = /^@| @/
        }
    }

    useEffect(()=>{
        isValidForm()
    },[action])

    useEffect(()=>{
        setTimeout(() => {
            isValidForm()
        }, "500");
    },[])

    const addVariation = (e) => {
        e.preventDefault()
        if(replyVariants.length < 3){
            setReplyVariants([...replyVariants, ""])

        }
    }

    const removeVariation = (e, index) => {
        e.preventDefault()
        if(replyVariants.length > 0){
            setReplyVariants(replyVariants => replyVariants.filter((s,i)=>(i != index)))
        }
    }

    const clickEmoji = (variationIndex) => {
        setCurVar(variationIndex)
        dispatch(setShowEmojiModal(true,"rule_reply_text"))
    }

    const clickSavedReply = (variationIndex) => {
        setCurVar(variationIndex)
        setShowSavedReplies(true)
    }

    return (
        <>
            {!ai ?
                <div className="form-group mb-2">
                    <label>Action</label>
                    <select className="form-select" name="rule[action]" defaultValue={action}
                            onChange={changeAction} required="required">
                        {/*{type!=="instagram_page" && <option value="like">Like</option>}*/}
                        <option value="reply">Reply</option>
                        <option value="review">Archive</option>
                    </select>
                </div>
                : <input type="hidden" name="rule[action]" value="reply"/>}


                {(action == "reply" || type=="instagram_page" || ai) && <div className="form-group mt-2">
                    {!ai && <>
                        {replyVariants.map((x, i) => {
                            return <div key={i}>
                                <div className="reply-block reply-rules my-2" >
                                    <div className={`d-flex ${i>0 && 'justify-content-between flex-row-reverse'} w-100`}>
                                        {i > 0 && <Button className="varCloseBtn btn btn-tr p-0 m-0" clickHandler={(e) => removeVariation(e,i)}>
                                            <FontAwesomeIcon icon={faXmark}
                                                             className="fs-08"
                                                             size="lg"/>
                                        </Button>}
                                        <TagInput placeholder="Type your comment here…" name={i==0 ? 'rule[reply_text]' : 'rule[reply_variants][]'}
                                                  tags={x || ""}
                                                  settings={tagSettings}
                                                  tagifyRef={msgRef[i]}
                                                  inputId={`rule_reply_text${i}`}
                                                  onChange={isValidForm}
                                        />
                                    </div>

                                    <div className="d-flex justify-content-between mx-0">
                                        <div className="d-flex p-0">
                                            <div className="private-checkbox">
                                                {i == 0 ? <>
                                                    <input className="form-check-input"
                                                           type="checkbox"
                                                           name="rule[private_reply]"
                                                           value="true"
                                                           ref={privateRef}
                                                           onChange={handlePrivate}
                                                           defaultChecked={rule && rule.attributes.private_reply}/>
                                                    <label className="form-check-label ms-2 rules-private-reply">
                                                        Send as private message (applies to all variations)
                                                    </label>
                                                </> :
                                                <AiRulesAction msgRef={msgRef[i]} mainInp={msgRef[0]}/>}
                                            </div>
                                        </div>

                                        <div className="d-flex p-0 icon-buttons">
                                            <FontAwesomeIcon icon={faPaste}
                                                             className="saved-replies-icon mx-2"
                                                             size="lg"
                                                             onClick={()=>{clickSavedReply(i)}}/>
                                            <FontAwesomeIcon icon={faFaceSmile}
                                                             className="emoji-icon mx-2"
                                                             size="lg"
                                                             onClick={() => clickEmoji(i)}/>
                                        </div>
                                    </div>
                                </div>
                         </div>
                        })}

                        {replyVariants.length<3 && <Button className="btn btn-tr py-2 px-0" clickHandler={addVariation}>
                            <FontAwesomeIcon icon={faPlus}
                                             className="me-2"
                                             size="lg"/>
                             <span>Add variation</span>
                        </Button>}
                    </>}

                {ai && <><SwitchItem title="Add mention"
                            value="true"
                            defaultChecked={rule?.attributes?.mention}
                            name="rule[mention]">
                    <div className="delay-desc">Mention the user who commented so he/she gets notified</div>
                </SwitchItem>

                <SwitchItem title="Tone of voice"
                    value={enableTone}
                    checked={enableTone}
                    name="rule[tone]"
                    changeHandler={changeTone}>
                    <div className="delay-desc">Choose a tone of voice that matches your brand</div>
                {enableTone && (<>
                        <div className="mlm-2_5 mt-2 form-group mb-2">
                            <select className="form-select" defaultValue={rule?.attributes?.tone_style} name="rule[tone_style]">
                                <option value="professional">Professional</option>
                                <option value="funny">Funny</option>
                                <option value="conversational">Conversational</option>
                                <option value="casual">Casual</option>
                                <option value="helpful">Helpful</option>
                            </select>
                        </div>
                    </>)}
                </SwitchItem></>
                }

                <SwitchItem title="Send with a random delay"
                            value="true"
                            defaultChecked={rule?.attributes?.delay}
                            name="rule[delay]">
                    <div className="delay-desc">Send auto-replies with a random delay up to 10 minutes</div>
                </SwitchItem>
            </div>


            }
            <AdditionalModals isValidForm={isValidForm}
                              curVar={curVar}
                              msgRef={msgRef}
                              showSavedReplies={showSavedReplies}
                              setShowSavedReplies={setShowSavedReplies}/>
        </>
    )
}

export default React.memo(ActionBlock)