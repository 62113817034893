import React, {useEffect, useState} from 'react'

import { addDays } from 'date-fns';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DateRangePicker from "react-date-range/dist/components/DateRangePicker";
import {changeFilter} from "../../../actions/pages";
import {requestPageComments} from "../../../actions/requests";
import {useDispatch, useSelector} from "react-redux";

const DateRangeWrapper = ({startDate, endDate}) => {
    const dispatch = useDispatch()
    const {data_retention} = useSelector(state => state.user.account_level.attributes)
    const [newStartDate, setNewStartDate] = useState("")
    const [newEndDate, setNewEndDate] = useState("")
    const [staticRange, setStaticRange] = useState(false)

    const handleStaticRange = () => {
        setStaticRange(true)
    }

    const handleSelect = (date) => {
        setState([date.selection])
        if(date.selection.startDate.getTime()!==date.selection.endDate.setHours(0, 0, 0, 0)){
            setNewEndDate(date.selection.endDate)
        }else{
            if(newStartDate !== "" || staticRange){
                setNewEndDate(date.selection.endDate)
            }else{
                setNewEndDate("")
            }
        }
        setNewStartDate(date.selection.startDate)
        staticRange && setStaticRange(false)
    }

    useEffect(()=>{
        if(newEndDate!=="")
        {
            dispatch(changeFilter("startDate", newStartDate))
            if(newStartDate.getTime() !== newEndDate.getTime()){
                dispatch(changeFilter("endDate", newEndDate))
            }else{
                dispatch(changeFilter("endDate", ""))
            }
            dispatch(requestPageComments())
            setNewStartDate("")
            setNewEndDate("")
        }

    },[newEndDate])


    useEffect(()=>{
        const divs = document.querySelectorAll('.rdrStaticRange');
        divs.forEach(el => el.addEventListener('click', handleStaticRange));
        return () => {
            divs.forEach(el => el.removeEventListener('click', handleStaticRange));
        }
    },[])

    const [state, setState] = useState([
        {
            startDate: startDate || new Date(),
            endDate: endDate || startDate || new Date(),
            key: 'selection'
        }
    ]);

    return (
        <div>
            <DateRangePicker
                onChange={handleSelect}
                showDateDisplay={false}
                editableDateInputs={false}
                rangeColors={['#2bae76']}
                showMonthAndYearPickers={false}
                maxDate={new Date()}
                minDate={addDays(new Date(), -data_retention)}
                inputRanges={[]}
                ranges={state}
            />
        </div>
    )
}

export default React.memo(DateRangeWrapper)