import React, {useEffect, useRef, useState} from 'react'
import './Messages.scss'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@awesome.me/kit-a13c95776b/icons/modules/sharp/thin";
import {useDispatch, useSelector} from "react-redux";
import {
    addLastActive,
    changeMessageFilter,
    requestMessages,
    requestConversations,
    requestMessagesSuccess, updateConversation
} from "../../../actions/direct_messages";
import {formatDistanceToNow} from "date-fns";
import ReplyForm from "../ReplyForm";
import MessagesReply from "./MessagesReply";
import {requestPageComments} from "../../../actions/requests";
import {dmFiltersIsChanged} from "../../../utils/app";
import {faBoxArchive, faCircleCheck, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import {isMobile} from "@headlessui/react/dist/utils/platform";
import ReactTooltip from "react-tooltip";
import Item from "./Item";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {changeFilter} from "../../../actions/pages";
import FacebookLoginButton from "../FacebookLoginButton";

const Messages = (props) => {
    const dispatch = useDispatch()
    const {details} = useSelector(state => state.pages.facebook.activePage)
    const {conversations, messagesList, lastActive, filters, unarchivedConvosCount} = useSelector(state => state.direct_messages)
    const {top, bottom} = filters
    const {read} = top
    const {resolved, archived} = bottom
    const [showChat, setShowChat] = useState(false)
    // let inbox_sum = 0;
    // conversations.map(c => inbox_sum+=c.attributes.unread_count)


    useEffect(()=>{
        dispatch(requestConversations())
    },[filters, details])

    useEffect(() => {

        const list = document.getElementById("list");
        if(list){
            list.scrollTop = 9999999999;
        }
    }, [messagesList]);

    const reload = () => {
        conversations.length > 0 && !lastActive && loadMessages(conversations[0].attributes.last_message)
    }

    useEffect(()=>{
        reload()
    },[conversations])

    const loadMessages = (m, show=false) => {
        dispatch(addLastActive(m))
        dispatch(requestMessages(m.id))
        setShowChat(show)
    }

    const handleFilter = (filterName, value, top=false) => {
        dispatch(addLastActive())
        dispatch(changeMessageFilter(top, filterName, value))
    }

    const isMobile = /Mobi|Android/i.test(navigator.userAgent)

    const convoUpdate = (name) => {
        dispatch(addLastActive())
        dispatch(updateConversation(lastActive.id, name))
    }

    const truncateString = (string = '', maxLength = 50) =>
        string.length > maxLength
            ? `${string.substring(0, maxLength)}…`
            : string

    const setUnreview = (index) => {
        if(index == 0){
            handleFilter("archived", false)
        }else{
            handleFilter("archived", true)
        }
    }

    return (
    <>
        <div className="mt-4 mb-3">
            {details.attributes.has_messages_scope ?
                <>Here you'll find an overview of the messages you've received.</>
            : <>Login to Facebook to grant the required permission to manage messages.
                    <div className="my-4">
                        <FacebookLoginButton href="/auth/facebook?redirect=/" text="Login with Facebook"/>
                    </div>
                </>}
        </div>

        {details.attributes.has_messages_scope &&
            <div className={`msg-wrapper ${showChat && 'showChat'}`}>

                <div className="msg-inbox">
                    <div className="msg-header msg-tabs">
                        <Tabs onSelect={setUnreview}>
                            <TabList className="react-tabs__tab-list filters">
                                <Tab>
                                    Inbox
                                    {unarchivedConvosCount > 0 && <span className="comments-counter px-1">{unarchivedConvosCount}</span>}
                                </Tab>
                                <Tab >Archive</Tab>
                            </TabList>

                            <TabPanel>
                            </TabPanel>
                            <TabPanel >
                            </TabPanel>
                        </Tabs>
                    </div>

                    <div className="msg-inbox-w">

                        <div className="sender-list">
                            {conversations?.length > 0 ? conversations.map((c) => {
                                return <div
                                    className={`msg-inbox-item ${lastActive?.attributes?.sender_id === c.attributes.last_message.attributes.sender_id && 'selected'}`}
                                    key={c.id} onClick={() => loadMessages(c.attributes.last_message, true)}>
                                    <div className="name-time">
                                        <div className="name">
                                            {c.attributes.last_message.attributes.sender_name}
                                        </div>
                                        <div className="time">
                                            {c.attributes.last_message && formatDistanceToNow(new Date(c.attributes.last_message.attributes?.date), {addSuffix: true})}
                                        </div>
                                    </div>
                                    <div className="last-msg">
                                        {c.attributes.last_message.attributes.attachment_type === "like" ? <FontAwesomeIcon icon={faThumbsUp} className="fa-fw"/> :
                                            truncateString(c.attributes.last_message.attributes?.message) || `[${c.attributes.last_message.attributes?.attachment_type}]`}
                                    </div>
                                </div>
                            }) : <div className="no-conv"> No conversations</div>}
                        </div>
                    </div>
                </div>

                <div className="msg-list">
                    <div className="msg-header">
                        <div className="arrow" onClick={() => setShowChat(false)}>
                            <FontAwesomeIcon icon={faArrowLeft} className="fa-fw"/>
                        </div>
                        <div className="text">{lastActive && lastActive.attributes.sender_name}</div>

                        <div className="btns">
                            <Button className={`square-btn ${archived ? 'active' : ''}`}
                                    clickHandler={()=>convoUpdate("archived")}
                                    dataTip={isMobile ? '' : (archived ? 'Unarchive' : 'Archive')}>
                                <FontAwesomeIcon icon={faBoxArchive} className="fa-fw"/>
                            </Button>
                        </div>
                    </div>

                    <div className="msg-list-w">
                        <div className="list" id="list">

                            {messagesList && messagesList.map((m, index) => {
                                return <Item dm={m} key={index} pageId={details.id}/>
                            })}

                        </div>
                        <div className="control-area">
                            {conversations?.length > 0 && lastActive && <MessagesReply selectedMessage={lastActive} pageId={details.id}/>}
                        </div>
                    </div>
                </div>
            </div>
        }
        <ReactTooltip effect="solid" html={false} className="switch-tooltip"/>
    </>
  )
}

export default React.memo(Messages)