import React, {useEffect, useRef} from 'react'
import './Preview.scss'
import {SanitizeHTML} from "../../../constants";
import {formatDistanceToNow} from "date-fns";

const PreviewItem = ({comment, commentId, is_hidden=false, showLine=false}) => {

    return (
        <>
            <div className="test">
            <div className={`preview-comment-message ${(comment.is_hidden || is_hidden) && "c-hidden"}`} key={comment.uuid}>
                <div className={`text ${(commentId == comment.uuid) && showLine ? "selected" : ""}`} >
                    <div className="author">{comment.author_name || "Author name"}</div>
                    <SanitizeHTML html={comment.message}/>
                    { comment.attachment_tmp_url && (<div className="comment-attachment">
                        <img src={comment.attachment_tmp_url} alt="attachment"/>
                    </div>)}
                </div>

                <div className="time">
                    {formatDistanceToNow(new Date(comment.fb_created_at), {addSuffix: true})}
                </div>
            </div>
                <div className="test">
                {comment.children && comment.children.map((c, index)=>{
                        return <div key={c.uuid} className="child-c">
                            <PreviewItem comment={c} commentId={commentId} is_hidden={comment.is_hidden} showLine={showLine}/>
                        </div>})

                }
                </div>
            </div>
        </>
    )
}

export default React.memo(PreviewItem)