import { combineReducers } from "redux";

import { pagesReducer } from "./pages";
import { uiReducer} from "./ui";
import { userReducer} from "./user";
import { commentsReducer} from "./comments";
import {filtersReducer} from "./filters";
import {linksReducer} from "./links";
import {tmpCommentsReducer} from "./tmpComments";
import {brandsReducer} from "./brands";
import {directMessagesReducer} from "./direct_messages";

export default combineReducers({
 pages: pagesReducer,
 comments: commentsReducer,
 tmpComments: tmpCommentsReducer,
 ui: uiReducer,
 user: userReducer,
 filters: filtersReducer,
 links: linksReducer,
 brands: brandsReducer,
 direct_messages: directMessagesReducer
});
