import React, {useCallback, useRef, useState} from 'react'
import ModalWindow from "../../../UI/ModalWindow";
import {useDispatch, useSelector} from "react-redux";
import {addPageManually} from "../../../../actions/requests";

const ManualPageModal = ({userPages, handleClose}) => {
    const dispatch = useDispatch()
    const manualForm = useRef()
    const btnRef = useRef()

    const handleManualForm = (e) => {
        e.preventDefault()
        const formData = new FormData(manualForm.current)
        dispatch(addPageManually(formData, userPages))
        handleClose()
    }

    const isValidForm = useCallback(e => {
        let valid = false
        const formData = new FormData(manualForm.current)

        for(let pair of formData.entries()) {
            if(!pair[1]) {
                valid = true
            }
        }

        btnRef.current.disabled = valid
    }, [])

    return (
        <ModalWindow hideClose={true}
                     header="Add a page using the page ID" className="giveFeedbackModal" modalSize="modal-sm"
                     closeHandler={() => handleClose()}
        >
            <form onSubmit={handleManualForm} ref={manualForm}>

                <div className="row">
                    <div className="form-group mb-2">
                        <label>Page ID</label>
                        <input type="text" name="page_uuid"
                               className="form-control"
                               required="required"
                               onChange={isValidForm}/>
                    </div>

                    <a href="https://commentguard.io/how-to-find-your-page-id/"
                       className="g-link"
                       target="_blank">
                        Where can I find the page ID?
                    </a>
                </div>

                <div className="row mt-4">
                    <div>
                        <button type="submit" className="btn btn-primary float-end" ref={btnRef } disabled={true}>Add</button>
                        <button type="reset" className="btn btn-secondary float-end mx-2"
                                onClick={() => handleClose()}>Cancel</button>
                    </div>
                </div>
            </form>

        </ModalWindow>
    )
}

export default React.memo(ManualPageModal)