import React, {useState, lazy, Suspense} from 'react';
import configureStore from "../../../store/configureStore";
const store = configureStore();
import {ToastContainer} from "react-toastify";
import {AbilityContext, defineAbilities} from "../../../utils/app";

import {Provider} from "react-redux";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import RebillFailed from "../../pages/RebillFailed";
import PurchaseConfirm from "../../pages/PurchaseConfirm";
import SignupConfirm from "../../pages/SignupConfirm";
const AccountSettings = lazy(() => import("../../pages/AccountSettings"));
const Dashboard = lazy(() => import("../../UI/Dashboard"));
const NotSigned = lazy(() => import("../../pages/NotSigned"));


const App  = (props) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <AbilityContext.Provider value={defineAbilities(props.rules)}>
                <Provider store={store}>
                    <Router>

                        <Routes>
                            {/*<Route path="brands" element={<Brands/>} />*/}
                            <Route path="account-settings" element={<AccountSettings admin={props.admin} token_present={props.token_present}/>} />
                            <Route path="purchase-confirmation" element={<PurchaseConfirm/>} />
                            <Route path="signup-confirmation" element={<SignupConfirm/>} />
                            <Route exact path="/" element={(
                                props.rebill_failed ? <RebillFailed/>
                                : props.token_present ? <Dashboard admin={props.admin} roles={props.rules} inbox_zero={props.inbox_zero}/> : <NotSigned/>
                            )} />
                        </Routes>

                        <div id="modal-root" />
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                    </Router>
                </Provider>
            </AbilityContext.Provider>
        </Suspense>
    )
}

export default App;