import React from 'react';

const SignupConfirm  = () => {

    return (
        <div className="col-lg-7">

            <h4 className="mb-4">
                Thanks for signing up 🎉
            </h4>

            <div className="connect-page-msg message my-4">
                Your account is now setup and ready to use.
            </div>

            <a href="/" className="btn btn-primary">Get started</a>
        </div>
    )
}

export default SignupConfirm;