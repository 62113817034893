import React, {useEffect, useRef} from 'react'
import './Preview.scss'
import {useDispatch, useSelector} from "react-redux";
import {SanitizeHTML} from "../../../constants";
import CommentItem from "../CommentItem";
import {formatDistanceToNow} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {capitalize} from "../../../utils/app";
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import {setShowPreviewPanel} from "../../../actions/ui";
import PreviewItem from "./PreviewItem";

const Preview = (props) => {
    const {post, comments, commentId, commentUrl, commentType} = useSelector(state => state.ui.previewData)
    const {message, full_picture, type, video_url} = post || {}
    const {showPreviewPanel} = useSelector(state => state.ui)
    const previewRef = useRef()
    const dispatch = useDispatch()

    const clickOutsidePreviewPanel = (e) => {
        const parent = previewRef.current
        if (previewRef.current && !parent.contains(e.target) && !e.target.closest(".preview-btn")) {
            dispatch(setShowPreviewPanel(false))
        }
    }

    useEffect(()=>{
        window.addEventListener("click", clickOutsidePreviewPanel);
        return () => {
            window.removeEventListener('click', clickOutsidePreviewPanel)
        }
    },[])

    const commentPreview = (e) => {
        const isMobile = /Mobi|Android/i.test(navigator.userAgent)
        let newUrl = commentUrl

        if(isMobile){
            newUrl = commentUrl.replace(`/c/${commentId}`, `?comment_id=${commentId}`)
        }

        window.open(newUrl, '_blank')
    }

  return (
    <>
        {showPreviewPanel &&
            <div className="preview-wrapper" ref={previewRef}>
                {post && (message || full_picture) && <>
                    <div className="preview-image">
                        {type == "video" ?
                            <video controls={true} height="154" key={video_url}>
                                <source src={video_url}/>
                            </video>
                            : full_picture && <img src={full_picture} alt="logo" className="logo" width="273"/>}
                    </div>
                    <div className="preview-message">
                        {message}
                    </div>
                    <div className="preview-comments">
                        <div>
                            {comments &&
                                <PreviewItem comment={comments} commentId={commentId} showLine={comments.children && comments.children.length > 0}/>
                            }
                        </div>
                    </div>
                </>}

                <Button clickHandler={commentPreview} className="square-btn preview-more mt-2 my-0">
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="fa-fw"/>
                    <span className="ms-2">View on {capitalize(commentType)}</span>
                </Button>
            </div>
        }
    </>
  )
}

export default React.memo(Preview)